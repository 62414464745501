import {ReactNode, useContext, useEffect} from "react";
import {AppDataContext} from "../AppData";
import {RestApiClientContext} from "./RestApiProvider";
import {kTopicTimotySettings} from "./constants";
import {TimotySettingsItemResponsePage} from "../generated/graphql/graphql";
import {processQueryError} from "../service/ErrorService";

export interface ITimotySettingsProcessorProps {
    children: ReactNode;
}

/**
 * Processor component to load current Timoty settings.
 */
export default function TimotySettingsProcessor(props: ITimotySettingsProcessorProps) {
    const {children} = props;

    const appDataContext = useContext(AppDataContext);
    const {auth, setTimotySettings} = appDataContext;

    const restApiClientContext = useContext(RestApiClientContext);
    const {subscribe} = restApiClientContext;

    useEffect(() => {
        if (auth.authUser?.idToken) {
            const subscription = subscribe(
                kTopicTimotySettings,
                {
                    uri: '/admin/timoty-settings/search',
                    onData: (data: TimotySettingsItemResponsePage | null) => {
                        if (data) {
                            setTimotySettings(data.content);
                        } else {
                            setTimotySettings([]);
                        }
                    },
                    onError: (error: any) => processQueryError(appDataContext, error),
                },
                () => true,
            );

            return () => subscription.cancel();
        }
    }, [auth.authUser?.idToken]);

    return (
        <>
            {children}
        </>
    );
}
