import { Text } from "@mantine/core";
import { booleanToYesNo } from "../../../utils/Utils";

export interface IYesNoBooleanLabelProps {
    value: boolean;
}

/**
 * Component for displaying boolean value as Yes or No.
 */
export default function YesNoBooleanLabel(props: IYesNoBooleanLabelProps) {
    const { value } = props;

    return (
        <Text color={value ? 'green' : 'red'}>
            {booleanToYesNo(value)}
        </Text>
    );
}
