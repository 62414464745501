import { useContext, useEffect, useMemo, useState } from "react";
import { RestApiClientContext } from "../../../core/RestApiProvider";
import { AdminGetSubscriptionsInput, AdminSubscriptionResponsePage, SubscriptionResponse } from "../../../generated/graphql/graphql";
import { processQueryError } from "../../../service/ErrorService";
import { AppDataContext } from "../../../AppData";
import { Box, Flex, Table } from "@mantine/core";
import { tt } from "../../../core/Localization";
import { useGeneralStyles } from "../../../styles/GeneralStyles";
import PaginationSection from "../tables/PaginationSection";
import AppIconButton from "../buttons/AppIconButton";
import { Edit, Trash } from "tabler-icons-react";
import {kTopicSubscriptions} from "../../../core/constants";
import TableHeadCell from "../tables/TableHeadCell";

const kSubscriptionsPageSize = 10;

export interface ISubscriptionsTableProps {
    search?: string;
    onEdit?: (subscription: SubscriptionResponse) => void;
    onDelete?: (subscription: SubscriptionResponse) => void;
}

/**
 * Table component for displaying Subscription list.
 */
export default function SubscriptionsTable(props: ISubscriptionsTableProps) {
    const { search, onEdit, onDelete } = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const { subscribe } = restApiClientContext;

    const appDataContext = useContext(AppDataContext);

    const { classes: generalClasses } = useGeneralStyles();

    const [sortDesc, setSortDesc] = useState<boolean>(false);
    const [sortBy, setSortBy] = useState<string>('id');

    const [page, setPage] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<AdminSubscriptionResponsePage | NullOrUndefined>();
    useEffect(() => {
        const subscription = subscribe(
            kTopicSubscriptions,
            {
                uri: '/admin/subscription/search-template',
                params: {
                    search,
                    page,
                    pageSize: kSubscriptionsPageSize,
                    sortDesc,
                    sortBy,
                } as AdminGetSubscriptionsInput,
                setLoading,
                onData: (data: AdminSubscriptionResponsePage) => setData(data),
                onError: (error: any) => processQueryError(appDataContext, error),
            },
            () => true,
        );

        return () => {
            subscription.cancel();
        };
    }, [page, search, sortDesc, sortBy]);

    useEffect(() => {
        if (data) {
            setTotal(data.total);
        } else {
            setTotal(0);
        }
    }, [data]);

    useEffect(() => {
        setPage(0);
    }, [search]);

    const rowsJSX = useMemo(() => {
        return data ? data.content.map((subscription) => {
            let validFor;

            if (subscription.validForDays) {
                validFor = tt('subscriptionsTable.table.validForDays').replace('$days', subscription.validForDays.toString());
            } else if (subscription.validForMonths) {
                validFor = tt('subscriptionsTable.table.validForMonths').replace('$months', subscription.validForMonths.toString());
            }

            return (
                <tr key={subscription.id}>
                    <td>{subscription.id}</td>
                    <td>{subscription.name}</td>
                    <td>{subscription.translationKey}</td>
                    <td>{subscription.maxUsers}</td>
                    <td>{validFor}</td>
                    <td align="right">
                        <Flex
                            justify="flex-end"
                        >
                            <AppIconButton
                                onClick={() => onEdit && onEdit(subscription)}
                                tooltip={tt('common.table.button.edit')}
                            >
                                <Edit />
                            </AppIconButton>

                            {subscription.canBeDeleted ? (
                                <AppIconButton
                                    onClick={() => onDelete && onDelete(subscription)}
                                    tooltip={tt('common.table.button.delete')}
                                >
                                    <Trash color="red" />
                                </AppIconButton>
                            ) : null}
                        </Flex>
                    </td>
                </tr>
            );
        }) : [];
    }, [data]);

    return (
        <>
            <Table
                highlightOnHover={true}
                mb="md"
            >
                <thead>
                    <tr>
                        <TableHeadCell
                            sortKey="id"
                            sortDesc={sortDesc}
                            setSortDesc={setSortDesc}
                            sortBy={sortBy}
                            setSortBy={setSortBy}
                        >
                            {tt('common.table.header.id')}
                        </TableHeadCell>
                        <TableHeadCell
                            sortKey="name"
                            sortDesc={sortDesc}
                            setSortDesc={setSortDesc}
                            sortBy={sortBy}
                            setSortBy={setSortBy}
                        >
                            {tt('subscriptionsTable.header.name')}
                        </TableHeadCell>
                        <th>{tt('subscriptionsTable.header.translationKey')}</th>
                        <TableHeadCell
                            sortKey="maxUsers"
                            sortDesc={sortDesc}
                            setSortDesc={setSortDesc}
                            sortBy={sortBy}
                            setSortBy={setSortBy}
                        >
                            {tt('subscriptionsTable.header.maxUsers')}
                        </TableHeadCell>
                        <th>{tt('subscriptionsTable.header.validFor')}</th>
                        <Box component="th" className={generalClasses.textAlignRightImportant}>
                            {tt('common.table.items.total').replace('$total', data?.total || 0)}
                        </Box>
                    </tr>
                </thead>

                <tbody>
                    {rowsJSX}
                </tbody>

                <tfoot>
                    <tr>
                        <TableHeadCell
                            sortKey="id"
                            sortDesc={sortDesc}
                            setSortDesc={setSortDesc}
                            sortBy={sortBy}
                            setSortBy={setSortBy}
                        >
                            {tt('common.table.header.id')}
                        </TableHeadCell>
                        <TableHeadCell
                            sortKey="name"
                            sortDesc={sortDesc}
                            setSortDesc={setSortDesc}
                            sortBy={sortBy}
                            setSortBy={setSortBy}
                        >
                            {tt('subscriptionsTable.header.name')}
                        </TableHeadCell>
                        <th>{tt('subscriptionsTable.header.translationKey')}</th>
                        <TableHeadCell
                            sortKey="maxUsers"
                            sortDesc={sortDesc}
                            setSortDesc={setSortDesc}
                            sortBy={sortBy}
                            setSortBy={setSortBy}
                        >
                            {tt('subscriptionsTable.header.maxUsers')}
                        </TableHeadCell>
                        <th>{tt('subscriptionsTable.header.validFor')}</th>
                        <Box component="th" className={generalClasses.textAlignRightImportant}>
                            {tt('common.table.items.total').replace('$total', data?.total || 0)}
                        </Box>
                    </tr>
                </tfoot>
            </Table>

            <PaginationSection
                total={total}
                page={page}
                setPage={setPage}
                pageSize={kSubscriptionsPageSize}
            />
        </>
    );
}
