import {ReactNode, useEffect, useState} from "react";
import AppButton from "../buttons/AppButton";

export interface IOptionalElementProps {
    children: ReactNode;
    text: string;
    elementValue: any;
    hideTrigger?: boolean;
    checkElementValue?: number;
    controlledVisible?: boolean;
    onControlledVisibleChange?: (visible: boolean) => void;
}

/**
 * Wrapper around any element to make it optional.
 */
export default function OptionalElement(props: IOptionalElementProps) {
    const {children, text, elementValue, hideTrigger, checkElementValue, controlledVisible, onControlledVisibleChange} = props;

    const [visible, setVisible] = useState<boolean>(!!elementValue);

    useEffect(() => {
        setVisible(false);
    }, [hideTrigger]);

    useEffect(() => {
        if (checkElementValue) {
            setVisible(!!elementValue);
        }
    }, [checkElementValue]);

    const theVisible = typeof controlledVisible === 'boolean' ? controlledVisible : visible;

    if (!theVisible) {
        return (
            <AppButton
                variant="outline"
                type="button"
                onClick={() => {
                    setVisible(true);

                    if (onControlledVisibleChange) {
                        onControlledVisibleChange(true);
                    }
                }}
                mb="sm"
                mr="sm"
            >
                {text}
            </AppButton>
        );
    }

    return (
        <>
            {children}
        </>
    );
}
