import React, {useContext, useEffect} from "react";
import TimotyLogo from "../../icons/TimotyLogo";
import {tt} from "../../core/Localization";
import ScreenContent from "../components/screens/ScreenContent";
import {useNavigate} from "react-router-dom";
import {AppDataContext} from "../../AppData";
import {Space, Text} from "@mantine/core";
import AppPaper from "../components/screens/AppPaper";
import AppButton from "../components/buttons/AppButton";

export const kNoMatchRoute = '*';

/**
 * Screen component for when URL does not match any Route.
 */
export default function NoMatchScreen() {
    const appDataContext = useContext(AppDataContext);
    const {setTitle, auth, theme} = appDataContext;

    const navigate = useNavigate();

    useEffect(() => {
        setTitle(tt('noMatch.screen.title'));
    }, []);

    return (
        <ScreenContent
            appBar={!!auth.authUser}
            navigationDrawer={!!auth.authUser}
        >
            <AppPaper>
                <TimotyLogo size={80}/>

                <Space h="sm"/>

                <Text size="xl" mb="sm">
                    {tt('404.screen.title')}
                </Text>

                <AppButton
                    variant="filled"
                    fullWidth={true}
                    onClick={() => navigate(-1)}
                >
                    {tt('404.screen.button.title')}
                </AppButton>
            </AppPaper>
        </ScreenContent>
    );
}
