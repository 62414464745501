import {IAppDataTheme} from "../AppData";

export const kStorageTheme = 'theme';

export const kDefaultTheme = 'light';
export const kSupportedThemes = ['dark', 'light'];

/**
 * Initialize theme.
 */
export function InitTheme(setTheme: (statePartial: (Partial<IAppDataTheme> | ((currentState: IAppDataTheme) => Partial<IAppDataTheme>))) => void) {
    const storedTheme = localStorage.getItem(kStorageTheme);

    if (storedTheme && kSupportedThemes.includes(storedTheme)) {
        setTheme({
            theme: storedTheme,
            darkMode: storedTheme === 'dark',
        });
    } else {
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

        if (prefersDark) {
            setTheme({
                theme: 'dark',
                darkMode: true,
            });
        } else {
            setTheme({
                theme: 'light',
                darkMode: false,
            });
        }

        ListenTheme(setTheme);
    }
}

let themeChangeListener: ((this: MediaQueryList, ev: MediaQueryListEvent) => any) | null = null;

/**
 * Listen to theme changes and update theme.
 * Does not save to local storage.
 */
export function ListenTheme(setTheme: (statePartial: (Partial<IAppDataTheme> | ((currentState: IAppDataTheme) => Partial<IAppDataTheme>))) => void) {
    if (themeChangeListener) {
        window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', themeChangeListener);
    }

    themeChangeListener = event => {
        const newColorScheme = event.matches ? "dark" : "light";

        setTheme({
            theme: newColorScheme,
            darkMode: newColorScheme === 'dark',
        });
    };

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', themeChangeListener);
}

/**
 * Change theme.
 */
export function ChangeTheme(theme: string, setTheme: (statePartial: (Partial<IAppDataTheme> | ((currentState: IAppDataTheme) => Partial<IAppDataTheme>))) => void) {
    if (kSupportedThemes.includes(theme)) {
        if (themeChangeListener) {
            window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', themeChangeListener);
            themeChangeListener = null;
        }

        localStorage.setItem(kStorageTheme, theme);

        setTheme({
            theme: theme,
            darkMode: theme === 'dark',
        });
    }
}
