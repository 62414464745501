import {useContext, useEffect, useState} from "react";
import ScreenContent from "../../components/screens/ScreenContent";
import {AppDataContext} from "../../../AppData";
import {tt} from "../../../core/Localization";
import AppPaper from "../../components/screens/AppPaper";
import {ArrowBack, Plus} from "tabler-icons-react";
import AppIconButton from "../../components/buttons/AppIconButton";
import {useNavigate, useParams} from "react-router-dom";
import {getBackRoute} from "../../../utils/Utils";
import {kCompanyListRoute} from "./CompanyListScreen";
import CompanySubscriptionsTable from "../../components/companies/CompanySubscriptionsTable";
import {createStyles, Group} from "@mantine/core";
import {kLargeDesktop} from "../../../styles/AppThemeProcessor";
import AppButton from "../../components/buttons/AppButton";
import {useDisclosure} from "@mantine/hooks";
import {AdminEndCompanySubscriptionInput, SubscriptionResponse} from "../../../generated/graphql/graphql";
import {
    CompanySubscriptionEditModal,
    ICompanySubscriptionEditModalOnSubmit
} from "../../components/modals/companies/CompanySubscriptionEditModal";
import {FetchPolicy, RestApiClientContext} from "../../../core/RestApiProvider";
import {SuccessToast} from "../../../service/ToastService";
import {processMutationError} from "../../../service/ErrorService";

export const kCompanySubscriptionsRoute = '/companies/:companyId/subscriptions';

/**
 * Create uri for Company Subscriptions.
 */
export function companySubscriptionsUri(companyId: string | number): string {
    return kCompanySubscriptionsRoute.replace(':companyId', companyId.toString());
}

const useStyles = createStyles((theme) => ({
    paper: {
        width: kLargeDesktop,
        maxWidth: "100%",
        '@media (max-width: 1500px)': {
            width: "100%",
        },
    },
}));

/**
 * Screen component for list of Subscription for Company.
 */
export default function CompanySubscriptionsScreen() {
    const appDataContext = useContext(AppDataContext);
    const {setTitle} = appDataContext;

    const restApiClientContext = useContext(RestApiClientContext);
    const { restApiPost } = restApiClientContext;

    const {companyId} = useParams();
    const navigate = useNavigate();

    const {classes} = useStyles();

    const [opened, {open, close}] = useDisclosure(false);
    const [editSubscription, setEditSubscription] = useState<SubscriptionResponse | null>(null);

    useEffect(() => {
        setTitle(tt('companySubscriptions.screen.title'));
    }, []);

    /**
     * OnAdd opens modal for adding new Subscription to Company.
     */
    const onAddSubscription = () => {
        setEditSubscription(null);
        open();
    };

    /**
     * OnEdit opens modal for editing Subscription.
     */
    const onEdit = (subscription: SubscriptionResponse) => {
        setEditSubscription(subscription);
        open();
    };

    const [saveLoading, setSaveLoading] = useState(false);
    /**
     * Save newly added or existing edited Company Subscription.
     */
    const onSubmit = (input: ICompanySubscriptionEditModalOnSubmit) => {
        console.log('TCH_d input', input); //TODO remove

        setSaveLoading(true);

        if (input.edit) {
            restApiPost({
                uri: '/admin/subscription/edit-company-subscription',
                params: input.edit,
                fetchPolicy: FetchPolicy.NetworkOnly,
                setLoading: setSaveLoading,
                onData: (data: SubscriptionResponse) => {
                    if (data) {
                        close();

                        SuccessToast(
                            tt('companySubscriptions.screen.editCompanySubscription.success'),
                        );
                    }
                },
                onError: (error) => processMutationError(error),
            });
        }

        if (input.add) {
            restApiPost({
                uri: '/admin/subscription/add-to-company',
                params: input.add,
                fetchPolicy: FetchPolicy.NetworkOnly,
                setLoading: setSaveLoading,
                onData: (data: SubscriptionResponse) => {
                    if (data) {
                        close();

                        SuccessToast(
                            tt('companySubscriptions.screen.addSubscriptionToCompany.success'),
                        );
                    }
                },
                onError: (error) => processMutationError(error),
            });
        }
    };

    /**
     * End the Subscription now.
     */
    const onEnd = (subscription: SubscriptionResponse) => {
        restApiPost({
            uri: '/admin/subscription/end-company-subscription',
            params: {
                subscriptionId: subscription.id,
            } as AdminEndCompanySubscriptionInput,
            fetchPolicy: FetchPolicy.NetworkOnly,
            onData: (data: SubscriptionResponse) => {
                if (data) {
                    SuccessToast(
                        tt('companySubscriptions.screen.endCompanySubscription.success'),
                    );
                }
            },
            onError: (error) => processMutationError(error),
        });
    };

    return (
        <>
            <ScreenContent>
                <AppPaper
                    className={classes.paper}
                    mb="md"
                    centerContent={false}
                >
                    <AppIconButton
                        onClick={() => navigate(getBackRoute(kCompanyListRoute))}
                        tooltip={tt('common.navigate.back')}
                        variant="outline"
                    >
                        <ArrowBack/>
                    </AppIconButton>
                </AppPaper>

                <AppPaper
                    className={classes.paper}
                >
                    <Group w="100%" position="right">
                        <AppButton
                            mb="sm"
                            onClick={onAddSubscription}
                            leftIcon={<Plus/>}
                        >
                            {tt('companySubscriptions.screen.addSubscriptionToCompany')}
                        </AppButton>
                    </Group>

                    <CompanySubscriptionsTable
                        companyId={parseInt(companyId!)}
                        onEdit={onEdit}
                        onEnd={onEnd}
                    />
                </AppPaper>
            </ScreenContent>

            <CompanySubscriptionEditModal
                companyId={parseInt(companyId!)}
                opened={opened}
                close={close}
                existing={editSubscription}
                onSubmit={onSubmit}
                loading={saveLoading}
            />
        </>
    );
}
