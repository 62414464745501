import React, {useContext} from "react";
import {Navigate} from "react-router-dom";
import {AppDataContext} from "../AppData";

export interface IProtectedRouteProps {
    children: React.ReactNode;
    redirectTo: string;
}

/**
 * Route element wrapper to make sure only auth users can access.
 */
export default function ProtectedRoute(props: IProtectedRouteProps) {
    const {children, redirectTo} = props;

    const appDataContext = useContext(AppDataContext);
    const {auth} = appDataContext;

    if (auth.authUser) {
        return (
            <>
                {children}
            </>
        );
    } else {
        return (
            <Navigate to={redirectTo}/>
        );
    }
}
