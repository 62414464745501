import {useContext, useEffect, useMemo, useState} from "react";
import {RestApiClientContext} from "../../../core/RestApiProvider";
import {Box, createStyles, Flex, Table} from "@mantine/core";
import {tt} from "../../../core/Localization";
import {useGeneralStyles} from "../../../styles/GeneralStyles";
import {
    AdminGetSubscriptionsForCompanyInput,
    SubscriptionResponse,
    SubscriptionResponsePage
} from "../../../generated/graphql/graphql";
import {processQueryError} from "../../../service/ErrorService";
import {AppDataContext} from "../../../AppData";
import {DateTime} from "luxon";
import {kTopicCompanies} from "../../../core/constants";
import {Edit, HandStop} from "tabler-icons-react";
import AppIconButton from "../buttons/AppIconButton";

const useStyles = createStyles((theme) => ({
    body: {
        wordBreak: 'break-word',
    },
}));

export interface ICompanySubscriptionsTableProps {
    companyId: number;
    onEdit?: (subscription: SubscriptionResponse) => void;
    onEnd?: (subscription: SubscriptionResponse) => void;
}

/**
 * Table component for displaying Subscription list for Company.
 */
export default function CompanySubscriptionsTable(props: ICompanySubscriptionsTableProps) {
    const { companyId, onEdit, onEnd } = props;

    const restApiClientContext = useContext(RestApiClientContext);
    const { subscribe } = restApiClientContext;

    const appDataContext = useContext(AppDataContext);
    const { language } = appDataContext;

    const { classes } = useStyles();
    const { classes: generalClasses } = useGeneralStyles();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<SubscriptionResponsePage | NullOrUndefined>();
    useEffect(() => {
        const subscription = subscribe(
            kTopicCompanies,
            {
                uri: '/admin/subscription/company',
                params: {
                    company: companyId,
                } as AdminGetSubscriptionsForCompanyInput,
                setLoading,
                onData: (data: SubscriptionResponsePage) => setData(data),
                onError: (error: any) => processQueryError(appDataContext, error),
            },
            () => true,
        );

        return () => {
            subscription.cancel();
        };
    }, [companyId]);

    const now = DateTime.now();

    const rowsJSX = useMemo(() => {
        return data ? data.content.map((subscription) => {
            const canEnd = subscription.validFrom && subscription.validTo && subscription.validTo > now.toMillis() && subscription.validFrom !== subscription.validTo;

            return (
                <tr key={subscription.id}>
                    <td>{tt(subscription.translationKey)}</td>
                    <td>{subscription.maxUsers}</td>
                    <td>{subscription.validFrom ? DateTime.fromMillis(subscription.validFrom).toLocaleString(DateTime.DATETIME_MED, { locale: language }) : null}</td>
                    <td>{subscription.validTo ? DateTime.fromMillis(subscription.validTo).toLocaleString(DateTime.DATETIME_MED, { locale: language }) : null}</td>
                    <td className={generalClasses.textAlignRightImportant}>
                        <Flex
                            justify="flex-end"
                        >
                            <AppIconButton
                                onClick={() => onEdit && onEdit(subscription)}
                                tooltip={tt('common.table.button.edit')}
                            >
                                <Edit />
                            </AppIconButton>

                            {canEnd ? (
                                <AppIconButton
                                    onClick={() => onEnd && onEnd(subscription)}
                                    tooltip={tt('companySubscriptionsTable.button.end')}
                                >
                                    <HandStop color="red" />
                                </AppIconButton>
                            ) : null}
                        </Flex>
                    </td>
                </tr>
            );
        }) : [];
    }, [data]);

    return (
        <>
            <Table
                highlightOnHover={true}
                mb="md"
            >
                <thead>
                    <tr>
                        <th>{tt('companySubscriptionsTable.header.name')}</th>
                        <th>{tt('companySubscriptionsTable.header.maxUsers')}</th>
                        <th>{tt('companySubscriptionsTable.header.validFrom')}</th>
                        <th>{tt('companySubscriptionsTable.header.validTo')}</th>
                        <Box component="th" className={generalClasses.textAlignRightImportant}>
                            {tt('common.table.items.total').replace('$total', data?.content.length || 0)}
                        </Box>
                    </tr>
                </thead>

                <tbody
                    className={classes.body}
                >
                    {rowsJSX}
                </tbody>

                <tfoot>
                    <tr>
                        <th>{tt('companySubscriptionsTable.header.name')}</th>
                        <th>{tt('companySubscriptionsTable.header.maxUsers')}</th>
                        <th>{tt('companySubscriptionsTable.header.validFrom')}</th>
                        <th>{tt('companySubscriptionsTable.header.validTo')}</th>
                        <Box component="th" className={generalClasses.textAlignRightImportant}>
                            {tt('common.table.items.total').replace('$total', data?.content.length || 0)}
                        </Box>
                    </tr>
                </tfoot>
            </Table>
        </>
    );
}
