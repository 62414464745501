import React, {ReactNode} from "react";
import {Box, createStyles} from "@mantine/core";

export const useStyles = createStyles((theme) => ({
    container: {
        display: 'flex',
        flex: '1 1 auto',
        justifyContent: 'center',
        alignItems: 'center',
    },
    topAlignedContent: {
        alignItems: "start",
    },
}));

export interface IFullSizedContainerProps {
    children: ReactNode;
    topAlignedContent?: boolean;
}

/**
 * Component container that stretches to full space available.
 */
export default function FullSizedContainer(props: IFullSizedContainerProps) {
    const {children, topAlignedContent} = props;

    const {classes, cx} = useStyles();

    const theClassName = cx(
        classes.container,
        topAlignedContent ? classes.topAlignedContent : undefined,
    );

    return (
        <Box className={theClassName}>
            {children}
        </Box>
    );
}
