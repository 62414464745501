import {AppDataContext} from "../../../AppData";
import {ReactNode, useContext} from "react";
import {createStyles, Modal, useComponentDefaultProps} from "@mantine/core";
import {MantineNumberSize} from "@mantine/styles";
import {Theme} from "@emotion/react";

export const useStyles = createStyles((theme: Theme) => ({
    modal: {
        '.mantine-Modal-content': {
            maxHeight: '100%',
        }
    }
}));

export interface IAppModalProps {
    children: ReactNode;
    opened: boolean;
    close: () => void;
    title: string;
    closeVisible?: boolean;
    size?: MantineNumberSize;
}

const defaultProps: Partial<IAppModalProps> = {
    closeVisible: true,
    size: 'md',
};

/**
 * Default modal component for the app.
 */
export default function AppModal(props: IAppModalProps) {
    const {
        children,
        opened,
        close,
        title,
        closeVisible,
        size,
    } = useComponentDefaultProps('AppModal', defaultProps, props);

    const appDataContext = useContext(AppDataContext);
    const {theme} = appDataContext;
    const {classes} = useStyles();

    return (
        <Modal.Root
            className={classes.modal}
            size={size}
            centered={true}
            opened={opened}
            onClose={close}
            title={title}
            fullScreen={theme.isMobile}
        >
            <Modal.Overlay blur={3}/>

            <Modal.Content>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>

                    {closeVisible ? <Modal.CloseButton/> : null}
                </Modal.Header>

                <Modal.Body>{children}</Modal.Body>
            </Modal.Content>
        </Modal.Root>
    );
}
