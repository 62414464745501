import AppVersionModal, {IAppVersionModalProps} from "./appVersions/AppVersionModal";
import {IAppDataContext} from "../../../AppData";
import ConfirmActionModal, {IConfirmActionModalProps} from "./ConfirmActionModal";

export interface IAppModalsProps {
    appVersion?: IAppVersionModalProps;
    confirmActionModal?: IConfirmActionModalProps;
}

/**
 * Component that serves as container for global modals.
 */
export default function AppModals(props: IAppModalsProps) {
    const {appVersion, confirmActionModal} = props;

    const appVersionModalJSX = appVersion ? (
        <AppVersionModal {...appVersion}/>
    ) : null;

    const confirmActionModalJSX = confirmActionModal ? (
        <ConfirmActionModal {...confirmActionModal}/>
    ) : null;

    return (
        <>
            {appVersionModalJSX}

            {confirmActionModalJSX}
        </>
    );
}

/**
 * Shorthand to display app version update modal.
 */
export function SetAppVersionModal(appDataContext: IAppDataContext, appVersion: Partial<IAppVersionModalProps>) {
    const {setAppModals} = appDataContext;

    setAppModals({
        appVersion: {
            opened: true,
            version: '',
            ...appVersion,
        },
    });
}

/**
 * Shorthand to hide app version update modal.
 */
export function HideAppVersionModal(appDataContext: IAppDataContext) {
    const {setAppModals} = appDataContext;

    setAppModals(prev => ({
        ...prev,
        appVersion: {
            ...prev.appVersion!,
            opened: false,
        },
    }));
}

/**
 * Shorthand to display confirm action modal.
 */
export function SetConfirmActionModal(appDataContext: IAppDataContext, confirmActionModal: Partial<IConfirmActionModalProps>) {
    const {setAppModals} = appDataContext;

    setAppModals({
        confirmActionModal: {
            opened: true,
            onConfirm: () => {},
            ...confirmActionModal,
        },
    });
}

/**
 * Shorthand to hide confirm action modal.
 */
export function HideConfirmActionModal(appDataContext: IAppDataContext) {
    const {setAppModals} = appDataContext;

    setAppModals(prev => ({
        ...prev,
        confirmActionModal: {
            ...prev.confirmActionModal!,
            opened: false,
        },
    }));
}
