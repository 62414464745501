import IInfo, {InfoType, MessageSeverity} from "../model/firestore/Info";
import {
    collection,
    deleteDoc,
    doc,
    getCountFromServer,
    getFirestore,
    limit,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    Unsubscribe
} from "firebase/firestore";
import {QuerySnapshot} from "@firebase/firestore";
import {tt} from "../core/Localization";
import {SelectItem} from "@mantine/core";
import {DateTime} from "luxon";

export const kInfosCollection = 'infos';

/**
 * Create new or update existing Info on Firestore.
 */
export async function saveInfo(info: IInfo): Promise<void> {
    const firestore = getFirestore();
    const theCollection = collection(firestore, kInfosCollection);

    info.updatedAt = DateTime.local().toMillis();

    if (info.id) {
        await setDoc(doc(theCollection, info.id), info);
    } else {
        await setDoc(doc(theCollection), info);
    }
}

export const kInfosPageSize = 10;

/**
 * Create subscription to infos collection.
 */
export function subscribeInfos(onNext: (snapshot: QuerySnapshot) => void, loadPages: number): Unsubscribe {
    const firestore = getFirestore();
    const theCollection = collection(firestore, kInfosCollection);

    const theQuery = query(
        theCollection,
        orderBy('createdAt', 'desc'),
        limit(kInfosPageSize * loadPages),
    );

    return onSnapshot(theQuery, onNext);
}

/**
 * Count number of infos in Firestore.
 */
export async function countInfos(): Promise<number> {
    const firestore = getFirestore();
    const theCollection = collection(firestore, kInfosCollection);

    const snapshot = await getCountFromServer(
        theCollection,
    );

    return snapshot.data().count;
}

/**
 * Delete info from Firestore.
 */
export async function deleteInfo(info: IInfo): Promise<void> {
    const firestore = getFirestore();
    const theCollection = collection(firestore, kInfosCollection);

    await deleteDoc(doc(theCollection, info.id));
}

/**
 * Display Info type as string.
 */
export function infoTypeToString(type: InfoType): string {
    switch (type) {
        case InfoType.TextOnly:
            return tt('info.type.textOnly');
        default:
            return tt('common.none');
    }
}

/**
 * Get Info types for select as options.
 */
export function getInfoTypesForSelect(): SelectItem[] {
    return [
        {value: InfoType.TextOnly.toString(), label: tt('info.type.textOnly')},
    ];
}

/**
 * Get info MessageSeverity as options for select.
 */
export function getMessageSeveritiesForSelect(): SelectItem[] {
    return [
        {value: '', label: tt('info.messageSeverity.none')},
        {value: MessageSeverity.Success.toString(), label: tt('info.messageSeverity.success')},
        {value: MessageSeverity.Error.toString(), label: tt('info.messageSeverity.error')},
        {value: MessageSeverity.Info.toString(), label: tt('info.messageSeverity.info')},
        {value: MessageSeverity.Warning.toString(), label: tt('info.messageSeverity.warning')},
    ];
}
