import './App.css';

import React, {useContext, useEffect, useState} from 'react';
import AppThemeProcessor, {kPhoneStopBreakpoint} from "./styles/AppThemeProcessor";
import {Toaster} from "react-hot-toast";
import {AppDataContext} from "./AppData";
import Router from "./core/Router";
import FullSizedContainer from "./ui/components/screens/FullSizedContainer";
import {BrowserRouter} from "react-router-dom";
import {Loader} from "@mantine/core";
import AutoSignInProcessor from "./core/AutoSignInProcessor";
import AppModals from "./ui/components/modals/AppModals";

/**
 * Main React App component.
 */
export default function App() {
    const {auth, theme, setTheme, appModals} = useContext(AppDataContext);

    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        const callback = () => {
            setWindowSize(getWindowSize());
        };

        window.addEventListener('resize', callback);

        return () => {
            window.removeEventListener('resize', callback);
        };
    }, []);

    useEffect(() => {
        if (windowSize.innerWidth > kPhoneStopBreakpoint && theme.isMobile) {
            setTheme({isMobile: false});
        } else if (windowSize.innerWidth <= kPhoneStopBreakpoint && !theme.isMobile) {
            setTheme({isMobile: true});
        }
    }, [windowSize]);

    const contextJSX = auth.authUserVerified ? (
        <Router/>
    ) : (
        <FullSizedContainer>
            <Loader size="xl"/>
        </FullSizedContainer>
    );

    return (
        <>
            <AutoSignInProcessor>
                <AppThemeProcessor>
                    <BrowserRouter>
                        {contextJSX}

                        <AppModals {...appModals}/>
                    </BrowserRouter>
                </AppThemeProcessor>
            </AutoSignInProcessor>

            <Toaster/>
        </>
    );
}

/**
 * Get current window size.
 */
function getWindowSize() {
    const {innerWidth, innerHeight} = window;

    return {innerWidth, innerHeight};
}
