import {IndustryResponse, SubscriptionResponse} from "../generated/graphql/graphql";

/**
 * Get last Subscription for Company.
 * Filter out the deleted.
 */
export function lastSubscriptionForCompany(companyId: number, subscriptions: Array<SubscriptionResponse> | undefined): SubscriptionResponse | NullOrUndefined {
    if (!subscriptions) {
        return null;
    }

    const filtered = subscriptions
        .filter(subscription => subscription.companyId === companyId && !subscription.deleted)
        .sort((a, b) => {
            if (a.validTo > b.validTo) {
                return -1;
            }
            if (a.validTo < b.validTo) {
                return 1;
            }
            return 0;
        });

    if (filtered.length === 0) {
        return null;
    }

    return filtered[0];
}

/**
 * Industry for id.
 */
export function industryForId(id: number, industries: Array<IndustryResponse> | undefined): IndustryResponse | NullOrUndefined {
    if (!industries) {
        return null;
    }

    const filtered = industries
        .filter(industry => industry.id === id);

    if (filtered.length === 0) {
        return null;
    }

    return filtered[0];
}
