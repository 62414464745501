import { createStyles } from "@mantine/core";
import { Dispatch, ReactNode, SetStateAction } from "react";
import { kThemePrimaryShade } from "../../../styles/AppThemeProcessor";
import { Triangle, TriangleInverted } from "tabler-icons-react";

const useStyles = createStyles((theme) => ({
    sort: {
        cursor: 'pointer',
    },
    children: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        'svg': {
            width: 16,
            marginLeft: 4,
        },
    },
    active: {
        color: `${theme.colors['primary-orange'][kThemePrimaryShade]} !important`,
    },
}));

export interface ITableHeadCellProps {
    children: ReactNode;
    className?: string;
    sortKey: string;
    sortDesc?: boolean;
    setSortDesc?: Dispatch<SetStateAction<boolean>>;
    sortBy?: string;
    setSortBy?: Dispatch<SetStateAction<string>>;
}

/**
 * Component for displaying table head cell with sorting functionality.
 */
export default function TableHeadCell(props: ITableHeadCellProps) {
    const { children, className, sortKey, sortDesc, setSortDesc, sortBy, setSortBy } = props;

    const { classes, cx } = useStyles();

    const isActive = sortBy === sortKey;

    if (setSortDesc && setSortBy) {
        const iconJSX = !sortDesc ? <Triangle /> : <TriangleInverted />;

        return (
            <th
                className={cx(classes.sort, isActive && classes.active, className)}
                onClick={() => {
                    setSortDesc(isActive ? !sortDesc : false);
                    setSortBy(sortKey);
                }}
            >
                <span className={classes.children}>
                    {children}

                    {isActive && iconJSX}
                </span>
            </th>
        );
    }

    return (
        <th className={cx(isActive && classes.active, className)}>
            {children}
        </th>
    );
}
