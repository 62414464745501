import React, {useContext, useEffect, useState} from "react";
import {getAuth} from "firebase/auth";
import IAuthUser from "../model/AuthUser";
import {AppDataContext, IAppDataAuth} from "../AppData";
import {convertAvatarUrlToLarge} from "../service/UserService";
import {
    AuthUserCanRefreshToken,
    AuthUserRefreshedToken,
    AuthUserSignOut,
    FirebaseProviderToEnum
} from "../service/AuthUserService";
import {ErrorToast} from "../service/ToastService";
import {tt} from "./Localization";
import {checkAppVersion} from "../service/AppVersionService";
import {FetchPolicy, RestApiClientContext} from "./RestApiProvider";
import {AdminSignInInput, AdminUserSignInResponse} from "../generated/graphql/graphql";

export interface IAutoSignInProcessorProps {
    children: React.ReactNode;
}

/**
 * Component for processing Firebase Auth events and auto signIn.
 */
export default function AutoSignInProcessor(props: IAutoSignInProcessorProps) {
    const {children} = props;

    const appDataContext = useContext(AppDataContext);
    const {auth, setAuth, language} = appDataContext;
    const theAuthUser = auth?.authUser;
    const firebaseUid = theAuthUser?.uid;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiPost} = restApiClientContext;

    const [executeCheckAppVersion, setExecuteCheckAppVersion] = useState<boolean>(false);

    useEffect(() => {
        if (theAuthUser) {
            checkAppVersion(appDataContext);
        }
    }, [executeCheckAppVersion, theAuthUser]);

    useEffect(() => {
        getAuth().onAuthStateChanged(async (user) => {
            if (user) {
                const idToken = await user.getIdToken(true);

                const authUser: IAuthUser = {
                    email: user.email || '',
                    emailVerified: user.emailVerified,
                    photoUrl: convertAvatarUrlToLarge(user.photoURL),
                    uid: user.uid,
                    loggedInWith: FirebaseProviderToEnum(user.providerData[0].providerId),
                    idToken,
                };

                setAuth((currentState: IAppDataAuth) => {
                    setTimeout(() => {
                        setExecuteCheckAppVersion(!executeCheckAppVersion);
                    }, 1);

                    return {
                        ...currentState,
                        authUser,
                        authUserVerified: true,
                    };
                });
            } else {
                setAuth((currentState: IAppDataAuth) => ({
                    ...currentState,
                    authUser: undefined,
                    authUserVerified: true,
                }));
            }
        });
    }, []);

    useEffect(() => {
        if (theAuthUser) {
            const refreshToken = async () => {
                try {
                    const user = getAuth().currentUser;

                    if (user && AuthUserCanRefreshToken()) {
                        const idToken = await user.getIdToken(true);

                        setAuth((currentState: IAppDataAuth) => {
                            if (currentState.authUser) {
                                setTimeout(() => {
                                    setExecuteCheckAppVersion(!executeCheckAppVersion);
                                }, 1);

                                return {
                                    ...currentState,
                                    authUser: {
                                        ...currentState.authUser!,
                                        email: user.email!,
                                        emailVerified: user.emailVerified,
                                        uid: user.uid,
                                        idToken,
                                    },
                                };
                            } else {
                                return {
                                    ...currentState,
                                    authUser: undefined,
                                };
                            }
                        });

                        AuthUserRefreshedToken();
                    }
                } catch (e) {
                    console.error(e);
                }
            };

            window.addEventListener('focus', refreshToken);

            return () => {
                window.removeEventListener('focus', refreshToken);
            };
        }
    }, [theAuthUser]);

    useEffect(() => {
        if (firebaseUid) {
            SignIn();
        }
    }, [firebaseUid]);

    /**
     * When User signIn with Firebase, load our data from BE.
     */
    const SignIn = async () => {
        restApiPost({
            uri: '/admin/user/signIn',
            params: {
                email: theAuthUser!.email,
                loginProviders: [theAuthUser!.loggedInWith],
                language,
            } as AdminSignInInput,
            fetchPolicy: FetchPolicy.NetworkOnly,
            onData: (data: AdminUserSignInResponse) => {
                if (data) {
                    setAuth((currentState: IAppDataAuth) => {
                        if (currentState.authUser) {
                            return {
                                ...currentState,
                                authUser: {
                                    ...currentState.authUser!,
                                    signInResponse: {
                                        error: undefined,
                                        data: data,
                                    },
                                },
                            };
                        } else {
                            return {
                                ...currentState,
                                authUser: undefined,
                            };
                        }
                    });
                }
            },
            onError: (error) => {
                console.error(error);

                setAuth((currentState: IAppDataAuth) => {
                    if (currentState.authUser) {
                        return {
                            ...currentState,
                            authUser: {
                                ...currentState.authUser!,
                                signInResponse: {
                                    error: error,
                                    data: undefined,
                                },
                            },
                        };
                    } else {
                        return {
                            ...currentState,
                            authUser: undefined,
                        };
                    }
                });

                AuthUserSignOut(appDataContext);

                ErrorToast(tt('error.signInFailed'));
            },
        });
    };

    return (
        <>
            {children}
        </>
    );
}
