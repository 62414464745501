import LocalizedStrings from 'react-localization';
import { Dispatch, SetStateAction } from "react";
import { SelectItem } from "@mantine/core";

export const kStorageLanguage = 'localization_language';

export const kDefaultLanguage = 'en';
export const kSupportedLanguages = ['en', 'sk', 'cs'];
export const kFESupportedLanguages = ['en', 'sk', 'cs', 'de', 'uk', 'ru'];
export const localizedStrings = new LocalizedStrings({
    en: require('../assets/translations/en.json'),
    sk: require('../assets/translations/sk.json'),
    cs: require('../assets/translations/cs.json'),
});

// Include localizations for components dependant on dayjs
require('dayjs/locale/en');
require('dayjs/locale/sk');
require('dayjs/locale/cs');

/**
 * Initialize language.
 * Override if set in local storage.
 */
export function InitLanguage(setLanguage: Dispatch<SetStateAction<string>>) {
    const storedLanguage = localStorage.getItem(kStorageLanguage);

    if (storedLanguage && kSupportedLanguages.includes(storedLanguage)) {
        localizedStrings.setLanguage(storedLanguage);

        setLanguage(storedLanguage);
    } else {
        const browserLanguage = localizedStrings.getLanguage();

        setLanguage(browserLanguage);
    }
}

/**
 * Change language to different supported and save to local storage.
 */
export function ChangeLanguage(language: string, setLanguage: Dispatch<SetStateAction<string>>) {
    if (kSupportedLanguages.includes(language)) {
        localizedStrings.setLanguage(language);

        localStorage.setItem(kStorageLanguage, language);

        setLanguage(language);
    }
}

/**
 * Shorthand for translations and to make JsTrions analysis easier to set up.
 */
export function tt(key: string) {
    return localizedStrings[key] || key;
}

/**
 * Get FE supported languages as options.
 */
export function getFESupportedLanguagesOptions(): SelectItem[] {
    return [
        { value: 'en', label: tt('language.en') },
        { value: 'sk', label: tt('language.sk') },
        { value: 'cs', label: tt('language.cs') },
        { value: 'de', label: tt('language.de') },
        { value: 'uk', label: tt('language.uk') },
        { value: 'ru', label: tt('language.ru') },
    ];
}

/**
 * Translate language to translated string.
 */
export function languageToString(language: string): string {
    switch (language) {
        case 'en':
        case 'en_GB':
        case 'en_US':
            return tt('language.en');
        case 'sk':
            return tt('language.sk');
        case 'cs':
            return tt('language.cs');
        case 'de':
            return tt('language.de');
        case 'uk':
            return tt('language.uk');
        case 'ru':
            return tt('language.ru');
        default:
            return language;
    }
}

/**
 * Translate language to translated string.
 * Use on string[] to translate all languages.
 */
export function languagesToString(languages: string[]): string[] {
    return languages.map(language => languageToString(language));
}
