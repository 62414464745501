import {collection, getFirestore, onSnapshot, query, QuerySnapshot, Unsubscribe, where} from "firebase/firestore";
import IEventSystemListener from "../model/EventSystemListener";
import IEventSystemNotification from "../model/firestore/EventSystemNotification";

const kEventSystemNotificationsFirestoreCollection = 'event_system_notifications';

export interface ISubscribeEventSystemNotificationsParams {
    createdAt: number;
    onNext: (snapshot: QuerySnapshot) => void;
}

/**
 * Create subscription to EventSystemNotifications collection.
 */
export function subscribeEventSystemNotifications(params: ISubscribeEventSystemNotificationsParams): Unsubscribe {
    const {createdAt, onNext} = params;

    const firestore = getFirestore();
    const theCollection = collection(firestore, kEventSystemNotificationsFirestoreCollection);

    const theQuery = query(
        theCollection,
        where('createdAt', '>', createdAt),
    );

    return onSnapshot(theQuery, onNext);
}

const listeners: IEventSystemListener[] = [];

/**
 * Subscribe listener to EventSystemNotifications.
 */
export function listenToEventSystem(listener: IEventSystemListener): void {
    listeners.push(listener);
}

/**
 * Unsubscribe listener from EventSystemNotifications.
 */
export function unListenToEventSystem(listener: IEventSystemListener): void {
    const index = listeners.indexOf(listener);

    if (index >= 0) {
        listeners.splice(index, 1);
    }
}

/**
 * Notify listeners about EventSystemNotifications.
 */
export function notifyEventSystem(notifications: IEventSystemNotification[]): void {
    for (const listener of listeners) {
        const notificationsForListener = notifications.filter((notification) => {
            return notification.topic === listener.topic && (notification.action === listener.action || !listener.action);
        });

        if (notificationsForListener.length) {
            listener.callback(notificationsForListener);
        }
    }
}
