import React, {ReactNode} from "react";
import {createStyles, Paper, SpacingValue, SystemProp, useComponentDefaultProps} from "@mantine/core";
import {kPhoneStopBreakpoint} from "../../../styles/AppThemeProcessor";

const useStyles = createStyles((theme) => ({
    mobileFullWidth: {
        width: kPhoneStopBreakpoint,
        maxWidth: "100%",
    },
    centerContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
}));

export interface IAppPaperProps {
    children: ReactNode;
    className?: string;
    mobileFullWidth?: boolean;
    centerContent?: boolean;
    mb?: SystemProp<SpacingValue>;
}

const defaultProps: Partial<IAppPaperProps> = {
    mobileFullWidth: true,
    centerContent: true,
};

/**
 * Paper component with App theme.
 */
export default function AppPaper(props: IAppPaperProps) {
    const {
        children,
        className,
        mobileFullWidth,
        centerContent,
        mb,
    } = useComponentDefaultProps('AppPaper', defaultProps, props);

    const {classes, cx} = useStyles();

    const theClassName = cx(
        mobileFullWidth ? classes.mobileFullWidth : undefined,
        centerContent ? classes.centerContent : undefined,
        className,
    );

    return (
        <Paper
            className={theClassName}
            shadow="sm"
            radius="lg"
            p="lg"
            withBorder={true}
            mb={mb}
        >
            {children}
        </Paper>
    );
}
