import toast, {Renderable, Toast, ToastOptions, ValueOrFunction} from "react-hot-toast";

export function SuccessToast(text: ValueOrFunction<Renderable, Toast>, options?: ToastOptions) {
    const theOptions = {
        duration: 4000,
        ...options,
    };

    toast.success(text, theOptions);
}

export function ErrorToast(text: ValueOrFunction<Renderable, Toast>, options?: ToastOptions) {
    const theOptions = {
        duration: 4000,
        ...options,
    };

    toast.error(text, theOptions);
}
