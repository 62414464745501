import {DateTime} from "luxon";

/**
 * Convert date millis to formatted date.
 */
export function MillisToDate(millis: number | NullOrUndefined, weekday?: boolean, language?: string): string {
    if (millis) {
        if (weekday) {
            return DateTime.fromMillis(millis).toFormat('EEEE d.L.y', {locale: language});
        }

        return DateTime.fromMillis(millis).toFormat('d.L.y');
    }

    return '';
}

export const kDefaultWaitForMillis = 200;

/**
 * Wait for set amount of millis.
 */
export function WaitFor(millis: number): Promise<void> {
    return new Promise((resolve, reject) => {
        setTimeout(resolve, millis);
    });
}
