import {useContext, useEffect, useState} from "react";
import {AppDataContext} from "../../../AppData";
import {tt} from "../../../core/Localization";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/screens/AppPaper";
import AppVersionsTable from "../../components/appVersions/AppVersionsTable";
import {useDisclosure} from "@mantine/hooks";
import AppVersionEditModal from "../../components/modals/appVersions/AppVersionEditModal";
import IAppVersion from "../../../model/firestore/AppVersion";
import AppButton from "../../components/buttons/AppButton";
import {Group} from "@mantine/core";
import {Plus} from "tabler-icons-react";
import {kDefaultWaitForMillis, WaitFor} from "../../../utils/DateUtils";
import {deleteAppVersion, saveAppVersion} from "../../../service/AppVersionService";
import {ErrorToast, SuccessToast} from "../../../service/ToastService";
import {HideConfirmActionModal, SetConfirmActionModal} from "../../components/modals/AppModals";

export const kAppVersionsRoute = '/app-versions';

/**
 * Screen component for list of AppVersions.
 */
export default function AppVersionListScreen() {
    const appDataContext = useContext(AppDataContext);
    const {setTitle, theme} = appDataContext;

    const [opened, { open, close }] = useDisclosure(false);
    const [editAppVersion, setEditAppVersion] = useState<IAppVersion | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setTitle(tt('appVersionsList.screen.title'));
    }, []);

    /**
     * OnCreate opens modal for creating new app version.
     */
    const onCreate = () => {
        setEditAppVersion(null);
        open();
    };

    /**
     * OnEdit opens modal for editing app version.
     */
    const onEdit = (appVersion: IAppVersion) => {
        setEditAppVersion(appVersion);
        open();
    };

    /**
     * Save AppVersion data from form.
     */
    const onSubmit = async (appVersion: IAppVersion) => {
        try {
            setLoading(true);

            await WaitFor(kDefaultWaitForMillis);

            await saveAppVersion(appVersion);

            close();

            SuccessToast(tt('saveAppVersion.success'));
        } catch (e) {
            console.error(e);

            ErrorToast(tt('error.saveAppVersion.failed'));
        }

        setLoading(false);
    };

    /**
     * Delete AppVersion.
     */
    const onDelete = async (appVersion: IAppVersion) => {
        SetConfirmActionModal(appDataContext, {
            onConfirm: async () => {
                try {
                    await deleteAppVersion(appVersion);

                    HideConfirmActionModal(appDataContext);

                    SuccessToast(tt('deleteAppVersion.success'));
                } catch (e) {
                    console.error(e);

                    ErrorToast(tt('error.deleteAppVersion.failed'));
                }
            },
        });
    };

    return (
        <>
            <ScreenContent>
                <AppPaper>
                    <Group w="100%" position="right">
                        <AppButton
                            mb="sm"
                            onClick={onCreate}
                            leftIcon={<Plus/>}
                        >
                            {tt('appVersionEditModal.create')}
                        </AppButton>
                    </Group>

                    <AppVersionsTable
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                </AppPaper>
            </ScreenContent>

            <AppVersionEditModal
                opened={opened}
                close={close}
                existing={editAppVersion}
                onSubmit={onSubmit}
                loading={loading}
            />
        </>
    );
}
