import {useContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {kSignInRoute} from "./SignInScreen";
import {tt} from "../../../core/Localization";
import {AuthUserSignOut} from "../../../service/AuthUserService";
import {SuccessToast} from "../../../service/ToastService";
import {AppDataContext} from "../../../AppData";

export const kSignOutRoute = '/sign-out';

/**
 * Screen component which signsOut current authUser.
 */
export default function SignOutScreen() {
    const appContext = useContext(AppDataContext);
    const {setTitle, auth} = appContext;
    const theAuthUser = auth?.authUser;

    const navigate = useNavigate();

    useEffect(() => {
        setTitle(tt('signOut.screen.title'));

        AuthUserSignOut(appContext)
            .then(() => SuccessToast(tt('common.userWasSignedOut')))
            .catch(e => console.error(e));
    }, []);

    useEffect(() => {
        if (!theAuthUser) {
            navigate(kSignInRoute);
        }
    }, [theAuthUser]);

    return null;
}
