import {ReactNode, useContext, useEffect} from "react";
import {AppDataContext} from "../AppData";
import {Unsubscribe} from "firebase/firestore";
import {notifyEventSystem, subscribeEventSystemNotifications} from "../service/EventSystemService";
import IEventSystemNotification from "../model/firestore/EventSystemNotification";
import {DateTime} from "luxon";

export interface IEventSystemProcessorProps {
    children?: ReactNode;
}

let theUnsubscribe: Unsubscribe | undefined;

/**
 * Processor component for the event system.
 */
export default function EventSystemProcessor(props: IEventSystemProcessorProps) {
    const {children} = props;

    const appDataContext = useContext(AppDataContext);
    const {auth} = appDataContext;
    const {authUser} = auth;

    useEffect(() => {
        if (authUser) {
            subscribeProcessor();
        }

        return () => {
            if (theUnsubscribe) {
                theUnsubscribe();
                theUnsubscribe = undefined;
            }
        };
    }, [authUser]);

    return (
        <>
            {children}
        </>
    )
}

/**
 * Subscribe to and process event system notifications.
 */
function subscribeProcessor(now?: number) {
    const theNow = now || DateTime.now().toMillis();

    if (theUnsubscribe) {
        theUnsubscribe();
        theUnsubscribe = undefined;
    }

    theUnsubscribe = subscribeEventSystemNotifications({
        createdAt: theNow,
        onNext: (snapshot) => {
            const newNotifications: IEventSystemNotification[] = [];

            snapshot.forEach((doc) => {
                const data = doc.data();
                const notification = data as any;

                notification.id = doc.id;

                newNotifications.push(notification);
            });

            if (newNotifications.length > 0) {
                const lastNotification = newNotifications
                    .sort((a, b) => b.createdAt - a.createdAt)[0];

                subscribeProcessor(lastNotification.createdAt);

                notifyEventSystem(newNotifications);
            }
        },
    });
}
