import TimotyLogo from "../../icons/TimotyLogo";
import React from "react";
import {Text} from "@mantine/core";
import {tt} from "../../core/Localization";

/**
 * Component for Timoty logo with text.
 */
export default function LogoWithText() {
    return (
        <>
            <TimotyLogo size={80}/>

            <Text>{tt('app.name')}</Text>
        </>
    );
}
