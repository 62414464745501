import {createStyles} from "@mantine/core";

export const useIconStyles = createStyles((theme) => ({
    icon: {
        width: 28,
        height: 28,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    iconBigger: {
        width: 48,
        height: 48,
    },
}));
