import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
import { tt } from "../core/Localization";
import { FileResponse } from "../generated/graphql/graphql";
import { ErrorToast } from "./ToastService";

/**
 * Get public urls in object for fileStates.
 * Mapped for uuid.
 */
export async function getPublicUrls(data: FileResponse[]): Promise<Record<string, string>> {
    const storage = getStorage();

    const urls: Record<string, string> = {};

    try {
        for (const file of data) {
            const fileRef = ref(storage, file.path);

            urls[file.uuid] = await getDownloadURL(fileRef);
        }
    } catch (e) {
        console.error(e);
        ErrorToast(tt('common.storage.getDownloadURL.error'));
    }

    return urls;
}

/**
 * Get correct publicUrl for file id using FileResponse.
 */
export function publicUrlForFile(fileId: number | NullOrUndefined, files: FileResponse[], publicUrls: Record<string, string>): string | null {
    const file = files.find(f => f.id === fileId);

    if (!file) {
        return null;
    }

    return publicUrls[file.uuid] ?? null;
}
