import { tt } from "../core/Localization";

/**
 * Return new T[] with only unique values.
 */
export function uniqueArray<T>(array: T[]): T[] {
    const newArray: T[] = [];

    array.forEach((value) => {
        if (!newArray.includes(value)) {
            newArray.push(value);
        }
    });

    return newArray;
}

/**
 * Return new number[] with only unique values by some property.
 */
export function uniqueArrayByPredicate<T>(array: T[], predicate: (item: T, otherItem: T) => boolean): T[] {
    const newArray: T[] = [];

    array.forEach((value) => {
        if (!newArray.some((item) => predicate(item, value))) {
            newArray.push(value);
        }
    });

    return newArray;
}

/**
 * Return new number[] with only unique values.
 */
export function arrayUnique<T>(array: T[]): T[] {
    return uniqueArray<T>(array);
}

/**
 * Check if one array contains all values from another array.
 */
export function arrayContainsAll(array: number[], values: number[]): boolean {
    return values.every((value) => array.includes(value));
}

/**
 * Get file extension from name.
 */
export function getFileExtension(fileName: string): string {
    return fileName.split('.').pop() || '';
}

/**
 * Convert boolean to yes/no translation.
 */
export function booleanToYesNo(value: boolean): string {
    return value ? tt('common.yes') : tt('common.no');
};

/**
 * Receive relative url and add current route to it as parameter.
 */
export function routeWithCurrentAsParam(route: string): string {
    const currentRoute = `${window.location.pathname}${window.location.search}`;

    let newRoute = route;

    if (newRoute.includes('?')) {
        newRoute = `${newRoute}&currentRoute=${encodeURIComponent(currentRoute)}`;
    } else {
        newRoute = `${newRoute}?currentRoute=${encodeURIComponent(currentRoute)}`;
    }

    return newRoute;
}

/**
 * Return backRoute or if current location has currentRoute param, return it.
 */
export function getBackRoute(backRoute: string): string {
    const urlParams = new URLSearchParams(window.location.search);
    const currentRoute = urlParams.get('currentRoute');

    if (currentRoute) {
        return decodeURIComponent(currentRoute);
    }

    return backRoute;
}

/**
 * Truncate string to given length.
 */
export function truncateString(text: string, length: number, wholeWords?: string, doNotAddDots?: boolean): string {
    if (text.length <= length) {
        return text;
    }

    let truncatedText = text.slice(0, length);

    if (wholeWords) {
        const indexOfLastSpace = truncatedText.lastIndexOf(" ");

        if (indexOfLastSpace !== -1) {
            truncatedText = truncatedText.slice(0, indexOfLastSpace);
        }
    }

    if (!doNotAddDots) {
        truncatedText += "...";
    }

    return truncatedText;
}
