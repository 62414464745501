import {ReactNode, useContext, useState} from "react";
import {
    AppShell,
    Box,
    Burger,
    createStyles,
    Header,
    MediaQuery,
    ScrollArea,
    useComponentDefaultProps
} from "@mantine/core";
import AppNavBar, { kAppNavBarWidth } from "./AppNavBar";
import { AppDataContext } from "../../../AppData";

const useStyles = createStyles((theme) => ({
    root: {
        width: '100%',
    },
    scrollContainer: {
        minWidth: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (max-width: 1700px)': {
            paddingLeft: kAppNavBarWidth,
        },
        '@media (max-width: 48em)': {
            paddingLeft: 0,
        },
    },
    scrollContainerNotAuthenticated: {
        paddingLeft: 0,
        '@media (max-width: 1700px)': {
            paddingLeft: 0,
        },
    },
    screenContent: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing.xs,
        paddingBottom: theme.spacing.xs,
        overflow: 'auto',
        width: '100%',
        '@media (max-width: 48em)': {
            paddingRight: '1rem',
        },
    },
    centerHorizontally: {
        alignItems: "center",
    },
    centerContent: {
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

export interface IScreenContentProps {
    children?: ReactNode;
    appBar?: boolean;
    navigationDrawer?: boolean;
    centerHorizontally?: boolean;
    centerContent?: boolean;
}

const defaultProps: Partial<IScreenContentProps> = {
    appBar: true,
    navigationDrawer: true,
    centerHorizontally: true,
    centerContent: true,
};

/**
 * Screen main contents component.
 */
export default function ScreenContent(props: IScreenContentProps) {
    const { children, appBar, navigationDrawer, centerHorizontally, centerContent } = useComponentDefaultProps('ScreenContent', defaultProps, props);

    const appDataContext = useContext(AppDataContext);
    const {auth} = appDataContext;

    const [navBar, setNavBar] = useState(false);

    const {classes, cx} = useStyles();

    const scrollContainerClassName = cx(
        classes.scrollContainer,
        !auth.authUser ? classes.scrollContainerNotAuthenticated : undefined,
    );

    const containerClassName = cx(
        classes.screenContent,
        centerContent ? classes.centerContent : undefined,
        centerHorizontally ? classes.centerHorizontally : undefined,
    );

    const navbarJSX = navigationDrawer ? (
        <AppNavBar hidden={!navBar} />
    ) : undefined;

    //TODO styles, width, height, etc. for the header
    //TODO header content, as component?
    const headerJSX = appBar ? (
        <Header height={{ base: 50, md: 70 }} p="md">
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                {navbarJSX ? (
                    <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                        <Burger
                            opened={navBar}
                            onClick={() => setNavBar((o) => !o)}
                            size="sm"
                            mr="xl"
                        />
                    </MediaQuery>
                ) : undefined}

                {"Application header"}
            </div>
        </Header>
    ) : undefined;

    return (
        <AppShell
            className={classes.root}
            navbarOffsetBreakpoint="sm"
            navbar={navbarJSX}
            header={headerJSX}
        >
            <Box className={scrollContainerClassName}>
                <ScrollArea.Autosize w="100%" mah="100%" placeholder="">
                    <Box className={containerClassName}>
                        {children}
                    </Box>
                </ScrollArea.Autosize>
            </Box>
        </AppShell>
    );
}
