import AppButton from "../buttons/AppButton";
import {ChevronLeft, ChevronRight} from "tabler-icons-react";
import {tt} from "../../../core/Localization";
import {Group, Text} from "@mantine/core";
import {Dispatch, SetStateAction} from "react";

export interface IPaginationSectionProps {
    total: number;
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
    pageSize: number;
}

/**
 * Component for displaying pagination section.
 */
export default function PaginationSection(props: IPaginationSectionProps) {
    const {total, page, setPage, pageSize} = props;

    const canLoadPrev = page > 0;
    const canLoadNext = total > (page + 1) * pageSize;
    const pages = Math.ceil(total / pageSize);

    /**
     * OnLoadPrev loads previous page of data.
     */
    const onLoadPrev = () => {
        setPage(page - 1);
    };

    /**
     * OnLoadNext loads next page of data.
     */
    const onLoadNext = () => {
        setPage(page + 1);
    };

    return (
        <Group w="100%" position="right">
            <AppButton
                mb="sm"
                mr="sm"
                onClick={onLoadPrev}
                leftIcon={<ChevronLeft/>}
                disabled={!canLoadPrev}
            >
                {tt('common.table.button.loadPrev')}
            </AppButton>

            <Text mb="sm" mr="sm">{tt('common.table.items.page').replace('$page', (page + 1)).replace('$pages', pages)}</Text>

            <AppButton
                mb="sm"
                onClick={onLoadNext}
                leftIcon={<ChevronRight/>}
                disabled={!canLoadNext}
            >
                {tt('common.table.button.loadNext')}
            </AppButton>
        </Group>
    );
}
