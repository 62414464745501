import {ReactNode} from "react";
import {createStyles} from "@mantine/core";

export const useStyles = createStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-end',
        '> button': {
            marginLeft: theme.spacing.md,
        },
    },
}));

export interface IAppModalActionsProps {
    children: ReactNode;
}

/**
 * Component that serves as container for modal actions.
 */
export default function AppModalActions(props: IAppModalActionsProps) {
    const {children} = props;

    const {classes} = useStyles();

    return (
        <div className={classes.root}>
            {children}
        </div>
    );
}
