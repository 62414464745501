import React, {ReactNode, useContext} from "react";
import {MantineProvider, MantineTheme} from "@mantine/core";
import {AppDataContext} from "../AppData";

export const kPhoneStopBreakpoint = 576;
export const kExtraLargeDesktop = 1500;
export const kLargeDesktop = 1200;
export const kSmallDesktop = 992;
export const kTablet = 768;
export const kLargePhone = 576;

export const kThemePrimaryShade = 4;

export interface IAppThemeProcessorProps {
    children: ReactNode;
}

/**
 * Component for generation of correct App Theme.
 */
export default function AppThemeProcessor(props: IAppThemeProcessorProps) {
    const {children} = props;

    const appDataContext = useContext(AppDataContext);

    //TODO: Add global styles here.
    const globalStyles = (theme: MantineTheme) => ({
        body: {
            ...theme.fn.fontStyles(),
        },
    });

    return (
        <MantineProvider
            withGlobalStyles={true}
            withNormalizeCSS={true}
            theme={{
                colorScheme: appDataContext.theme.darkMode ? 'dark' : 'light',
                colors: {
                    'primary-orange': [
                        "#E5CAC2",
                        "#E0B0A0",
                        "#E2947C",
                        "#EC7752",
                        "#FF5722",
                        "#E64E1E",
                        "#C04E2A",
                        "#9F4D33",
                        "#864B38",
                        "#714739",
                    ],
                },
                primaryColor: 'primary-orange',
                primaryShade: kThemePrimaryShade,
                loader: 'bars',
                globalStyles,
            }}
        >
            {children}
        </MantineProvider>
    );
}
