import { createStyles, TextInput } from "@mantine/core";
import { kLargeDesktop } from "../../../styles/AppThemeProcessor";
import { useContext, useEffect, useState } from "react";
import { RestApiClientContext } from "../../../core/RestApiProvider";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/screens/AppPaper";
import { tt } from "../../../core/Localization";
import CompaniesTable from "../../components/companies/CompaniesTable";
import { AppDataContext } from "../../../AppData";
import { useSearchParams } from "react-router-dom";

export const kCompanyListRoute = '/companies';

const useStyles = createStyles((theme) => ({
    paper: {
        width: kLargeDesktop,
        maxWidth: "100%",
        '@media (max-width: 1500px)': {
            width: "100%",
        },
    },
}));

/**
 * Screen component for list of Company.
 */
export default function CompanyListScreen() {
    const appDataContext = useContext(AppDataContext);
    const { setTitle } = appDataContext;

    const restApiClientContext = useContext(RestApiClientContext);
    const { restApiPost } = restApiClientContext;

    const { classes } = useStyles();

    const [searchParams, setSearchParams] = useSearchParams();

    const [search, setSearch] = useState<string>(searchParams.get('companies-search') || '');

    useEffect(() => {
        setTitle(tt('companyList.screen.title'));
    }, []);

    return (
        <>
            <ScreenContent>
                <AppPaper
                    className={classes.paper}
                    mb="md"
                    centerContent={false}
                >
                    <TextInput
                        label={tt('companyList.search')}
                        placeholder={tt('companyList.search.placeholder')}
                        mb="sm"
                        value={search}
                        onChange={(event) => setSearch(event.currentTarget.value)}
                    />
                </AppPaper>

                <AppPaper
                    className={classes.paper}
                >
                    <CompaniesTable
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                        search={search}
                    />
                </AppPaper>
            </ScreenContent>
        </>
    );
}
