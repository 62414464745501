/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Long: any;
};

export type AddJobEmployeeTimesheetItemToVisitInput = {
  approved: Scalars['Boolean'];
  createdByEmployeeId: Scalars['Int'];
  distance?: InputMaybe<Scalars['Float']>;
  distanceRate?: InputMaybe<Scalars['Float']>;
  employeeId: Scalars['Int'];
  endTime?: InputMaybe<Scalars['Long']>;
  fixedPrice?: InputMaybe<Scalars['Float']>;
  hourRate?: InputMaybe<Scalars['Float']>;
  hours?: InputMaybe<Scalars['Float']>;
  minutes?: InputMaybe<Scalars['Float']>;
  note?: InputMaybe<Scalars['String']>;
  paymentType: ItemPaymentType;
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  startTime?: InputMaybe<Scalars['Long']>;
  uuid: Scalars['String'];
  visitId: Scalars['Long'];
};

export type AddJobOfferSeatToVisitInput = {
  companyId: Scalars['Int'];
  employeeIds: Array<Scalars['Int']>;
  fixedPrice?: InputMaybe<Scalars['Float']>;
  hourRate?: InputMaybe<Scalars['Float']>;
  hours?: InputMaybe<Scalars['Float']>;
  minutes?: InputMaybe<Scalars['Float']>;
  paymentType: PaymentType;
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  sendNotifications: Scalars['Boolean'];
  substituteCount: Scalars['Int'];
  totalPrice?: InputMaybe<Scalars['Float']>;
  visitId: Scalars['Long'];
};

export type AddJobProtocolToVisitInput = {
  description: Scalars['String'];
  fileIds: Array<Scalars['Long']>;
  isDefect?: InputMaybe<Scalars['Boolean']>;
  repeatingDay?: InputMaybe<Scalars['Long']>;
  signatureFileIds?: InputMaybe<Array<Scalars['Long']>>;
  visitId: Scalars['Long'];
};

export type AddNoteItemInput = {
  clientId?: InputMaybe<Scalars['Int']>;
  companyId: Scalars['Int'];
  employeeId?: InputMaybe<Scalars['Int']>;
  note: Scalars['String'];
};

export type AddUserDeviceInput = {
  deviceToken: Scalars['String'];
};

export type AddVisitJobFormInput = {
  jobForms: Array<CreateVisitJobFormInput>;
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  visitId: Scalars['Long'];
};

export type AddVisitMaterialInput = {
  employeeId: Scalars['Int'];
  name: Scalars['String'];
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  saveAsTemplate: Scalars['Boolean'];
  templateId?: InputMaybe<Scalars['Long']>;
  unitCount: Scalars['Float'];
  unitName?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
  visitId: Scalars['Long'];
};

export type AddVisitProductInput = {
  description?: InputMaybe<Scalars['String']>;
  employeeId: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Float'];
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  saveAsTemplate: Scalars['Boolean'];
  templateId?: InputMaybe<Scalars['Long']>;
  type: ProductType;
  unitCount?: InputMaybe<Scalars['Float']>;
  unitName: Scalars['String'];
  uuid: Scalars['String'];
  vatRate?: InputMaybe<Scalars['Float']>;
  visitId: Scalars['Long'];
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  country: Scalars['String'];
  createdAt: Scalars['Long'];
  googlePlacesId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Long']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  provider: AddressProvider;
  state: Scalars['String'];
  street: Scalars['String'];
  zipcode: Scalars['String'];
};

export type AddressFragment = {
  __typename?: 'AddressFragment';
  city: Scalars['String'];
  country: Scalars['String'];
  createdAt?: Maybe<Scalars['Long']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Long']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  provider: AddressProvider;
  state: Scalars['String'];
  street: Scalars['String'];
  zipcode: Scalars['String'];
};

export type AddressFragmentInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  createdAt?: InputMaybe<Scalars['Long']>;
  googlePlacesId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Long']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  provider: AddressProvider;
  state: Scalars['String'];
  street: Scalars['String'];
  zipcode: Scalars['String'];
};

export enum AddressProvider {
  Google = 'Google',
  MapyCz = 'MapyCZ',
  None = 'None'
}

export type AdminAddSubscriptionToCompanyInput = {
  companyId: Scalars['Int'];
  input?: InputMaybe<AdminSaveSubscriptionTemplateInput>;
  maxUsers?: InputMaybe<Scalars['Int']>;
  templateId?: InputMaybe<Scalars['Long']>;
  validFrom?: InputMaybe<Scalars['Long']>;
  validTo?: InputMaybe<Scalars['Long']>;
};

export type AdminCompanyResponsePage = {
  __typename?: 'AdminCompanyResponsePage';
  content: Array<CompanyResponse>;
  files: Array<FileResponse>;
  industries: Array<IndustryResponse>;
  subscriptions: Array<SubscriptionResponse>;
  total: Scalars['Long'];
};

export type AdminDeleteSubscriptionTemplateInput = {
  id: Scalars['Long'];
};

export type AdminEditCompanySubscriptionInput = {
  maxUsers: Scalars['Int'];
  subscriptionId: Scalars['Long'];
  validFrom: Scalars['Long'];
  validTo: Scalars['Long'];
};

export type AdminEndCompanySubscriptionInput = {
  subscriptionId: Scalars['Long'];
};

export type AdminGetCompaniesInput = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDesc?: InputMaybe<Scalars['Boolean']>;
};

export type AdminGetSubscriptionsForCompanyInput = {
  company: Scalars['Int'];
};

export type AdminGetSubscriptionsInput = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDesc?: InputMaybe<Scalars['Boolean']>;
};

export type AdminGetUsersInput = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Scalars['String']>;
  sortDesc?: InputMaybe<Scalars['Boolean']>;
  userIds?: InputMaybe<Array<Scalars['Int']>>;
};

export type AdminSaveSubscriptionTemplateInput = {
  id?: InputMaybe<Scalars['Long']>;
  maxUsers: Scalars['Int'];
  name: Scalars['String'];
  translationKey: Scalars['String'];
  validForDays?: InputMaybe<Scalars['Int']>;
  validForMonths?: InputMaybe<Scalars['Int']>;
};

export type AdminSignInInput = {
  email: Scalars['String'];
  fcmDeviceToken?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  loginProviders: Array<LoginProvider>;
};

export type AdminSubscriptionResponsePage = {
  __typename?: 'AdminSubscriptionResponsePage';
  content: Array<SubscriptionResponse>;
  total: Scalars['Long'];
};

export type AdminUserResponsePage = {
  __typename?: 'AdminUserResponsePage';
  content: Array<UserResponse>;
  total: Scalars['Long'];
};

export type AdminUserSetAdminInput = {
  superAdmin: Scalars['Boolean'];
  userId: Scalars['Int'];
};

export type AdminUserSignInResponse = {
  __typename?: 'AdminUserSignInResponse';
  userId: Scalars['Int'];
};

export type AssignClientContactsToJobInput = {
  clientContacts: Array<Scalars['Int']>;
  jobId: Scalars['Long'];
};

export type AssignClientContactsToLocationInput = {
  clientContacts: Array<Scalars['Int']>;
  locationId: Scalars['Int'];
};

export type AssignClientContactsToLocationPlaceInput = {
  clientContacts: Array<Scalars['Int']>;
  locationPlaceId: Scalars['Long'];
};

export type AssignClientContactsToVisitInput = {
  clientContacts: Array<Scalars['Int']>;
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  visitId: Scalars['Long'];
};

export type AssignIndustriesInput = {
  companyId: Scalars['Int'];
  industryIds: Array<Scalars['Int']>;
};

export type CancelVisitByManagerInput = {
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  sendNotifications: Scalars['Boolean'];
  visitId: Scalars['Long'];
};

export type ClaimFilesInput = {
  fileIds: Array<Scalars['Long']>;
};

export type ClientContactJoinedLocationsResponse = {
  __typename?: 'ClientContactJoinedLocationsResponse';
  assignedToClient: Scalars['Boolean'];
  clientId: Scalars['Int'];
  createdAt: Scalars['Long'];
  id: Scalars['Int'];
  locationPlaces: Array<LocationPlaceResponse>;
  locations: Array<LocationResponse>;
  mainContact: Scalars['Boolean'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  surname: Scalars['String'];
};

export type ClientContactProfileResponse = {
  __typename?: 'ClientContactProfileResponse';
  client: ClientPureResponse;
  clientContact: ClientContactResponse;
  location?: Maybe<LocationResponse>;
  locationPlace?: Maybe<LocationPlaceResponse>;
};

export type ClientContactResponse = {
  __typename?: 'ClientContactResponse';
  assignedToClient: Scalars['Boolean'];
  clientId: Scalars['Int'];
  createdAt: Scalars['Long'];
  id: Scalars['Int'];
  mainContact: Scalars['Boolean'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  surname: Scalars['String'];
};

export type ClientContactResponsePage = {
  __typename?: 'ClientContactResponsePage';
  content: Array<ClientContactResponse>;
};

export type ClientJoinedLocationsResponse = {
  __typename?: 'ClientJoinedLocationsResponse';
  companyId: Scalars['Int'];
  contacts: Array<ClientContactJoinedLocationsResponse>;
  createdAt: Scalars['Long'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['Long']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isCompany: Scalars['Boolean'];
  locationPlaces: Array<LocationPlaceResponse>;
  locations: Array<LocationResponse>;
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type ClientJoinedLocationsResponsePage = {
  __typename?: 'ClientJoinedLocationsResponsePage';
  content: Array<ClientJoinedLocationsResponse>;
};

export type ClientProfileResponse = {
  __typename?: 'ClientProfileResponse';
  allClientLocationsCount: Scalars['Long'];
  client: ClientResponse;
  clientNotes?: Maybe<NoteItemResponsePage>;
  locations: Array<LocationResponse>;
};

export type ClientPureResponse = {
  __typename?: 'ClientPureResponse';
  company: Scalars['Boolean'];
  companyId: Scalars['Int'];
  createdAt: Scalars['Long'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['Long']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type ClientPureResponsePage = {
  __typename?: 'ClientPureResponsePage';
  content: Array<ClientPureResponse>;
  locations?: Maybe<Array<LocationPureResponse>>;
};

export type ClientResponse = {
  __typename?: 'ClientResponse';
  company: Scalars['Boolean'];
  companyId: Scalars['Int'];
  contacts: Array<ClientContactResponse>;
  createdAt: Scalars['Long'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['Long']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastLocationId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type CloseJobOfferSeatOfVisitInput = {
  jobOfferSeatId: Scalars['Long'];
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
};

export type CloseVisitInput = {
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  visitId: Scalars['Long'];
};

export type CompanyEmployeeProcessingDataResponse = {
  __typename?: 'CompanyEmployeeProcessingDataResponse';
  company: CompanyResponse;
  employee: EmployeeJoinedUserResponse;
  files: Array<FileResponse>;
  permissionsMapJSON: Scalars['String'];
  subscription: CompanySubscriptionStatusResponse;
  userPreferences: Array<UserPreferencesItemResponse>;
};

export type CompanyProfileResponse = {
  __typename?: 'CompanyProfileResponse';
  allIndustries?: Maybe<Array<IndustryResponse>>;
  company: CompanyResponse;
  companyIndustries?: Maybe<Array<IndustryResponse>>;
  logoFile?: Maybe<FileResponse>;
  selfEmployeeData?: Maybe<EmployeeResponse>;
};

export type CompanyResponse = {
  __typename?: 'CompanyResponse';
  address?: Maybe<AddressFragment>;
  companySize?: Maybe<Scalars['String']>;
  createdAt: Scalars['Long'];
  currency: Scalars['String'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['Long']>;
  gpsLocationTracking: Scalars['Boolean'];
  hasVat: Scalars['Boolean'];
  id: Scalars['Int'];
  industryIds: Array<Scalars['Int']>;
  logoFileId?: Maybe<Scalars['Long']>;
  name: Scalars['String'];
  timeZone: Scalars['String'];
};

export type CompanyResponsePage = {
  __typename?: 'CompanyResponsePage';
  content: Array<CompanyResponse>;
};

export type CompanySubscriptionStatusResponse = {
  __typename?: 'CompanySubscriptionStatusResponse';
  currentUsers: Scalars['Long'];
  maxUsers: Scalars['Int'];
  subscription: SubscriptionResponse;
};

export enum ContractType {
  Contract = 'Contract',
  FullTime = 'FullTime',
  None = 'None',
  PartTime = 'PartTime',
  SelfEmployed = 'SelfEmployed',
  Temporary = 'Temporary'
}

export type CreateClientContactInput = {
  clientId: Scalars['Int'];
  name: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  surname: Scalars['String'];
};

export type CreateClientInput = {
  companyId: Scalars['Int'];
  companyName: Scalars['String'];
  companyPhone?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  isCompany: Scalars['Boolean'];
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
};

export type CreateCompanyEmployeeInput = {
  address?: InputMaybe<AddressFragmentInput>;
  companyId: Scalars['Int'];
  contactEmail?: InputMaybe<Scalars['String']>;
  contractType?: InputMaybe<ContractType>;
  distanceRate?: InputMaybe<Scalars['Float']>;
  hourRate?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  role: EmployeeRole;
  surname: Scalars['String'];
};

export type CreateCompanyEmployeePasscodeInput = {
  employeeId: Scalars['Int'];
};

export type CreateCompanyInput = {
  address?: InputMaybe<AddressFragmentInput>;
  companySize?: InputMaybe<Scalars['String']>;
  currency: Scalars['String'];
  hasVat: Scalars['Boolean'];
  industryIds: Array<Scalars['Int']>;
  name: Scalars['String'];
  personalDetailsInput?: InputMaybe<UserPersonalDetailsInput>;
  timeZone: Scalars['String'];
};

export type CreateFileInput = {
  category?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  path: Scalars['String'];
  uuid: Scalars['String'];
};

export type CreateGpsLocationForJobInput = {
  device: Scalars['String'];
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  os: OsType;
  status: GpsLocationStatus;
};

export type CreateJobEmployeeTimesheetItemInput = {
  approved: Scalars['Boolean'];
  createdByEmployeeId: Scalars['Int'];
  distance?: InputMaybe<Scalars['Float']>;
  distanceRate?: InputMaybe<Scalars['Float']>;
  employeeId: Scalars['Int'];
  endTime?: InputMaybe<Scalars['Long']>;
  fixedPrice?: InputMaybe<Scalars['Float']>;
  hourRate?: InputMaybe<Scalars['Float']>;
  hours?: InputMaybe<Scalars['Float']>;
  minutes?: InputMaybe<Scalars['Float']>;
  note?: InputMaybe<Scalars['String']>;
  paymentType: ItemPaymentType;
  startTime?: InputMaybe<Scalars['Long']>;
  uuid: Scalars['String'];
};

export type CreateJobFormTemplateInput = {
  companyId: Scalars['Int'];
  elements: Array<JobFormElementFragmentInput>;
  name: Scalars['String'];
  sections: Array<Scalars['String']>;
};

export type CreateJobInput = {
  clientContactIds: Array<Scalars['Int']>;
  clientId?: InputMaybe<Scalars['Int']>;
  companyId: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  responsiblePersonId: Scalars['Int'];
  sendNotifications: Scalars['Boolean'];
  templateId?: InputMaybe<Scalars['Long']>;
  timeZone: Scalars['String'];
  visits: Array<CreateVisitInput>;
};

export type CreateJobOfferSeatInput = {
  employeeIds: Array<Scalars['Int']>;
  fixedPrice?: InputMaybe<Scalars['Float']>;
  hourRate?: InputMaybe<Scalars['Float']>;
  hours?: InputMaybe<Scalars['Float']>;
  minutes?: InputMaybe<Scalars['Float']>;
  paymentType: PaymentType;
  substituteCount: Scalars['Int'];
};

export type CreateLocationInput = {
  address: AddressFragmentInput;
  clientId: Scalars['Int'];
  companyId: Scalars['Int'];
  icon: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  places: Array<CreateLocationInputPlaceInput>;
  specification?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type CreateLocationInputPlaceInput = {
  icon: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type CreateLocationPlaceInput = {
  icon: Scalars['String'];
  locationId: Scalars['Int'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type CreateLocationPlacesInput = {
  locationId: Scalars['Int'];
  places: Array<CreateLocationInputPlaceInput>;
};

export type CreateLocationPlacesResponse = {
  __typename?: 'CreateLocationPlacesResponse';
  location: LocationResponse;
  newLocationPlaces: Array<LocationPlaceResponse>;
};

export type CreateMaterialInput = {
  employeeId: Scalars['Int'];
  name: Scalars['String'];
  sortOrder: Scalars['Int'];
  templateId?: InputMaybe<Scalars['Long']>;
  unitCount: Scalars['Float'];
  unitName?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type CreateMaterialTemplateInput = {
  companyId: Scalars['Int'];
  name: Scalars['String'];
  unitName?: InputMaybe<Scalars['String']>;
};

export type CreateProductInput = {
  description?: InputMaybe<Scalars['String']>;
  employeeId: Scalars['Int'];
  name: Scalars['String'];
  price?: InputMaybe<Scalars['Float']>;
  sortOrder: Scalars['Int'];
  templateId?: InputMaybe<Scalars['Long']>;
  type: ProductType;
  unitCount?: InputMaybe<Scalars['Float']>;
  unitName?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
  vatRate?: InputMaybe<Scalars['Float']>;
};

export type CreateProductTemplateInput = {
  companyId: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  sortOrder: Scalars['Int'];
  type: ProductType;
  unitCount?: InputMaybe<Scalars['Float']>;
  unitName: Scalars['String'];
  vatRate?: InputMaybe<Scalars['Float']>;
};

export type CreateVisitForJobInput = {
  jobId: Scalars['Long'];
  sendNotifications: Scalars['Boolean'];
  visit: CreateVisitInput;
};

export type CreateVisitForJobResponse = {
  __typename?: 'CreateVisitForJobResponse';
  job: JobResponse;
  visit: VisitResponse;
};

export type CreateVisitInput = {
  attachmentFileIds?: InputMaybe<Array<Scalars['Long']>>;
  clientContactIds: Array<Scalars['Int']>;
  clientId: Scalars['Int'];
  companyId: Scalars['Int'];
  createVisitParams?: InputMaybe<Scalars['String']>;
  customDates: Array<CustomDateInput>;
  description?: InputMaybe<Scalars['String']>;
  employeeTimesheets: Array<CreateJobEmployeeTimesheetItemInput>;
  endDate?: InputMaybe<Scalars['Long']>;
  endTime: Scalars['Long'];
  every?: InputMaybe<Scalars['Int']>;
  jobForms: Array<CreateVisitJobFormInput>;
  jobId: Scalars['Long'];
  jobOfferSeats: Array<CreateJobOfferSeatInput>;
  locationId?: InputMaybe<Scalars['Int']>;
  locationPlaceId?: InputMaybe<Scalars['Long']>;
  materials: Array<CreateMaterialInput>;
  monthDays: Array<Scalars['Int']>;
  monthWeekDays: Array<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  products: Array<CreateProductInput>;
  repeatEndDate?: InputMaybe<Scalars['Long']>;
  repeatModifier?: InputMaybe<VisitRepeatModifier>;
  repeatWeekDays: Array<Scalars['Int']>;
  repeating: VisitRepeating;
  requiredEmployeeIds: Array<Scalars['Int']>;
  scheduleLater: Scalars['Boolean'];
  skipDays: Array<Scalars['Long']>;
  startDate: Scalars['Long'];
  startTime: Scalars['Long'];
  templateId?: InputMaybe<Scalars['Int']>;
  uuid: Scalars['String'];
  wholeDay: Scalars['Boolean'];
};

export type CreateVisitJobFormInput = {
  input?: InputMaybe<CreateJobFormTemplateInput>;
  jobFormId?: InputMaybe<Scalars['Long']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CustomDateInput = {
  end: Scalars['Long'];
  start: Scalars['Long'];
  timestamp: Scalars['Long'];
};

export type DeleteAccountRequestResponse = {
  __typename?: 'DeleteAccountRequestResponse';
  createdAt: Scalars['Long'];
  fulfilled: Scalars['Boolean'];
  fulfilledAt: Scalars['Long'];
  id: Scalars['Int'];
  userId: Scalars['Int'];
};

export type DeleteClientContactInput = {
  clientContactId: Scalars['Int'];
};

export type DeleteClientInput = {
  clientId: Scalars['Int'];
};

export type DeleteCompanyEmployeeInput = {
  employeeId: Scalars['Int'];
};

export type DeleteCompanyInput = {
  companyId: Scalars['Int'];
};

export type DeleteEmployeePermissionsInput = {
  employeeId: Scalars['Int'];
};

export type DeleteJobEmployeeTimesheetItemFromVisitInput = {
  employeeId: Scalars['Int'];
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  timesheetId: Scalars['Long'];
  timesheetUuid: Scalars['String'];
  visitId: Scalars['Long'];
};

export type DeleteJobFormTemplateInput = {
  jobFormId: Scalars['Long'];
};

export type DeleteJobInput = {
  jobId: Scalars['Long'];
};

export type DeleteJobOfferSeatFromVisitInput = {
  jobOfferSeatId: Scalars['Long'];
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
};

export type DeleteJobProtocolFromVisitInput = {
  jobProtocolId: Scalars['Long'];
};

export type DeleteLocationInput = {
  locationId: Scalars['Int'];
};

export type DeleteLocationPlaceInput = {
  locationPlaceId: Scalars['Long'];
};

export type DeleteMaterialTemplateInput = {
  materialId: Scalars['Long'];
};

export type DeleteNoteItemInput = {
  noteId: Scalars['Long'];
};

export type DeleteProductTemplateInput = {
  productId: Scalars['Long'];
};

export type DeleteVisitInput = {
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  visitId: Scalars['Long'];
};

export type DeleteVisitJobFormInput = {
  jobFormUUID: Scalars['String'];
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  visitId: Scalars['Long'];
};

export type DeleteVisitMaterialInput = {
  materialId: Scalars['Long'];
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  uuid: Scalars['String'];
  visitId: Scalars['Long'];
};

export type DeleteVisitProductInput = {
  productId: Scalars['Long'];
  productUuid: Scalars['String'];
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  visitId: Scalars['Long'];
};

export type EmployeeDetailResponse = {
  __typename?: 'EmployeeDetailResponse';
  customPhotoFile?: Maybe<FileResponse>;
  employee: EmployeeJoinedUserResponse;
  permissionsByRole: Scalars['Boolean'];
  permissionsMapJSON: Scalars['String'];
};

export type EmployeeInvitationResponse = {
  __typename?: 'EmployeeInvitationResponse';
  companyId: Scalars['Int'];
  consumed: Scalars['Boolean'];
  consumedAt?: Maybe<Scalars['Long']>;
  createdAt: Scalars['Long'];
  id: Scalars['Long'];
  passcode?: Maybe<Scalars['String']>;
};

export type EmployeeJoinedUserOthersResponse = {
  __typename?: 'EmployeeJoinedUserOthersResponse';
  employee: EmployeeJoinedUserResponse;
  files: Array<FileResponse>;
};

export type EmployeeJoinedUserResponse = {
  __typename?: 'EmployeeJoinedUserResponse';
  active: Scalars['Boolean'];
  address?: Maybe<AddressFragment>;
  companyId: Scalars['Int'];
  contactEmail?: Maybe<Scalars['String']>;
  contractType?: Maybe<ContractType>;
  createdAt: Scalars['Long'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['Long']>;
  distanceRate?: Maybe<Scalars['Float']>;
  employeeInvitation?: Maybe<EmployeeInvitationResponse>;
  hourRate?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  role: EmployeeRole;
  surname?: Maybe<Scalars['String']>;
  user?: Maybe<UserResponse>;
};

export type EmployeeJoinedUserResponsePage = {
  __typename?: 'EmployeeJoinedUserResponsePage';
  content: Array<EmployeeJoinedUserResponse>;
  employeePermissions?: Maybe<Array<EmployeePermissionsMapResponse>>;
  files: Array<FileResponse>;
  permissionsByRoleEmployeeIds?: Maybe<Array<Scalars['Int']>>;
};

export type EmployeePermissionsMapResponse = {
  __typename?: 'EmployeePermissionsMapResponse';
  employeeId: Scalars['Int'];
  permissionsMapJSON: Scalars['String'];
};

export type EmployeePermissionsResponse = {
  __typename?: 'EmployeePermissionsResponse';
  companyId: Scalars['Int'];
  createdAt: Scalars['Long'];
  deleted: Scalars['Boolean'];
  id: Scalars['Int'];
  isTemplate: Scalars['Boolean'];
  permissionsMapJSON: Scalars['String'];
  role: EmployeeRole;
  templateId?: Maybe<Scalars['Int']>;
};

export type EmployeePermissionsResponsePage = {
  __typename?: 'EmployeePermissionsResponsePage';
  content: Array<EmployeePermissionsResponse>;
};

export type EmployeeResponse = {
  __typename?: 'EmployeeResponse';
  active: Scalars['Boolean'];
  address?: Maybe<AddressFragment>;
  companyId: Scalars['Int'];
  contactEmail?: Maybe<Scalars['String']>;
  contractType?: Maybe<ContractType>;
  createdAt: Scalars['Long'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['Long']>;
  distanceRate?: Maybe<Scalars['Float']>;
  employeeInvitationId?: Maybe<Scalars['Int']>;
  hourRate?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  role: EmployeeRole;
  surname?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
};

export type EmployeeResponsePage = {
  __typename?: 'EmployeeResponsePage';
  content: Array<EmployeeResponse>;
};

export type EmployeeResponseToJobOfferSeatOfVisitInput = {
  accept: Scalars['Boolean'];
  jobOfferSeatId: Scalars['Long'];
  reject: Scalars['Boolean'];
  repeatingDay?: InputMaybe<Scalars['Long']>;
};

export enum EmployeeRole {
  Admin = 'Admin',
  HeadOfWorkers = 'HeadOfWorkers',
  Manager = 'Manager',
  None = 'None',
  Worker = 'Worker'
}

export type FileResponse = {
  __typename?: 'FileResponse';
  category?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Long'];
  expirationAt?: Maybe<Scalars['Long']>;
  id: Scalars['Long'];
  label?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  path: Scalars['String'];
  uuid: Scalars['String'];
};

export type FileResponsePage = {
  __typename?: 'FileResponsePage';
  content: Array<FileResponse>;
};

export type GetClientContactInput = {
  clientContactId: Scalars['Int'];
  includeLocationId?: InputMaybe<Scalars['Int']>;
  includeLocationPlaceId?: InputMaybe<Scalars['Long']>;
};

export type GetClientContactsForIdsInput = {
  clientContactIds: Array<Scalars['Int']>;
};

export type GetClientInput = {
  clientId: Scalars['Int'];
  requestDeletedRead?: InputMaybe<Scalars['Boolean']>;
  requestLastLocationId?: InputMaybe<Scalars['Boolean']>;
};

export type GetClientProfileInput = {
  clientId: Scalars['Int'];
  includeClientNotes?: InputMaybe<Scalars['Boolean']>;
  includeClientNotesEmployeeJoinedUsers?: InputMaybe<Scalars['Boolean']>;
  includeClientNotesFiles?: InputMaybe<Scalars['Boolean']>;
  locationsPageableOffset?: InputMaybe<Scalars['Int']>;
  locationsPageableSize?: InputMaybe<Scalars['Int']>;
  requestDeletedRead?: InputMaybe<Scalars['Boolean']>;
  requestLastLocationId?: InputMaybe<Scalars['Boolean']>;
};

export type GetClientsInput = {
  companyId: Scalars['Int'];
  includeLocations?: InputMaybe<Scalars['Boolean']>;
  search: Scalars['String'];
};

export type GetCompaniesInput = {
  companyIds: Array<Scalars['Int']>;
};

export type GetCompanyInput = {
  companyId: Scalars['Int'];
};

export type GetCompanyProfileInput = {
  companyId: Scalars['Int'];
  includeIndustries?: InputMaybe<Scalars['Boolean']>;
  includeLogoFile?: InputMaybe<Scalars['Boolean']>;
  includeSelfEmployeeData?: InputMaybe<Scalars['Boolean']>;
};

export type GetEmployeeDetailInput = {
  employeeId: Scalars['Int'];
};

export type GetEmployeeInput = {
  companyId: Scalars['Int'];
};

export type GetEmployeeJoinedUserInput = {
  employeeId: Scalars['Int'];
};

export type GetEmployeeOfCompaniesInput = {
  companyIds: Array<Scalars['Int']>;
};

export type GetEmployeesJoinedUsersIncludingInactiveInput = {
  companyId: Scalars['Int'];
  employeeIds?: InputMaybe<Array<Scalars['Int']>>;
  includeCheckPermissionsByRole?: InputMaybe<Scalars['Boolean']>;
  includePermissionsMapJSON?: InputMaybe<Scalars['Boolean']>;
};

export type GetEmployeesJoinedUsersInput = {
  active: Scalars['Boolean'];
  companyId: Scalars['Int'];
  employeeIds?: InputMaybe<Array<Scalars['Int']>>;
  includeCheckPermissionsByRole?: InputMaybe<Scalars['Boolean']>;
  includePermissionsMapJSON?: InputMaybe<Scalars['Boolean']>;
};

export type GetFilesByIdsInput = {
  fileIds: Array<Scalars['Long']>;
};

export type GetIndustriesInput = {
  companyId?: InputMaybe<Scalars['Int']>;
};

export type GetJobFormInput = {
  jobFormId: Scalars['Long'];
};

export type GetJobFormsByIdsInput = {
  jobFormIds: Array<Scalars['Long']>;
};

export type GetJobFormsInput = {
  companyId: Scalars['Int'];
  isTemplate: Scalars['Boolean'];
};

export type GetJobInput = {
  includeJobEmployeeStatsForEmployee?: InputMaybe<Scalars['Int']>;
  jobId: Scalars['Long'];
};

export type GetJobProtocolInput = {
  jobProtocolId: Scalars['Long'];
};

export type GetJobsInput = {
  clientId?: InputMaybe<Scalars['Int']>;
  companyId: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};

export type GetLocationInput = {
  locationId: Scalars['Int'];
  requestDeletedRead?: InputMaybe<Scalars['Boolean']>;
};

export type GetLocationPlaceDataInput = {
  locationPlaceId: Scalars['Long'];
  requestDeletedRead?: InputMaybe<Scalars['Boolean']>;
};

export type GetLocationPlaceProfileInput = {
  includeClientContacts?: InputMaybe<Scalars['Boolean']>;
  includeJobForm?: InputMaybe<Scalars['Boolean']>;
  locationPlaceId: Scalars['Long'];
};

export type GetLocationPlacesByIdsInput = {
  locationPlaceIds: Array<Scalars['Long']>;
};

export type GetLocationProfileInput = {
  includeClientContacts?: InputMaybe<Scalars['Boolean']>;
  includeFirstJobForm?: InputMaybe<Scalars['Boolean']>;
  locationId: Scalars['Int'];
};

export type GetLocationsByIdsInput = {
  locationIds: Array<Scalars['Int']>;
};

export type GetLocationsForClientInput = {
  clientId: Scalars['Int'];
  includeClientPureId?: InputMaybe<Scalars['Int']>;
  pageable?: InputMaybe<PageableFragmentInput>;
  search?: InputMaybe<Scalars['String']>;
};

export type GetLocationsForCompanyInput = {
  companyId: Scalars['Int'];
};

export type GetMaterialInput = {
  materialId: Scalars['Long'];
};

export type GetMaterialsInput = {
  companyId: Scalars['Int'];
  isTemplate: Scalars['Boolean'];
};

export type GetNoteItemInput = {
  noteId: Scalars['Long'];
};

export type GetNoteItemProfileInput = {
  includeEmployeeJoinedUser?: InputMaybe<Scalars['Boolean']>;
  includeFile?: InputMaybe<Scalars['Boolean']>;
  noteId: Scalars['Long'];
};

export type GetNoteItemsInput = {
  clientId?: InputMaybe<Scalars['Int']>;
  companyId: Scalars['Int'];
  employeeId?: InputMaybe<Scalars['Int']>;
  includeEmployeeJoinedUsers?: InputMaybe<Scalars['Boolean']>;
  includeFiles?: InputMaybe<Scalars['Boolean']>;
};

export type GetPermissionsForAllRolesInput = {
  companyId: Scalars['Int'];
};

export type GetPermissionsForRoleInput = {
  companyId: Scalars['Int'];
  role: EmployeeRole;
};

export type GetProductInput = {
  productId: Scalars['Long'];
};

export type GetProductsInput = {
  companyId: Scalars['Int'];
  isTemplate: Scalars['Boolean'];
};

export type GetUserCompaniesProfileInput = {
  includeCompanySubscriptions?: InputMaybe<Scalars['Boolean']>;
};

export type GetUserInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type GetUserJoinedCompaniesInput = {
  id?: InputMaybe<Scalars['Int']>;
  includeCompanySubscriptions?: InputMaybe<Scalars['Boolean']>;
};

export type GetUserPreferencesInput = {
  companyId: Scalars['Int'];
};

export type GetVisitInput = {
  includeHistory?: InputMaybe<Scalars['Boolean']>;
  includeJobFormTemplates?: InputMaybe<Scalars['Boolean']>;
  repeatingDay?: InputMaybe<Scalars['Long']>;
  visitId: Scalars['Long'];
};

export type GetVisitJobEmployeeDataInput = {
  visitIds: Array<Scalars['Long']>;
};

export type GetVisitJobEmployeeTimesheetItemsInput = {
  visitIds: Array<Scalars['Long']>;
};

export type GetVisitJobLogHistoryInput = {
  repeatingDay?: InputMaybe<Scalars['Long']>;
  visitId: Scalars['Long'];
};

export type GetVisitJobOfferSeatsInput = {
  visitIds: Array<Scalars['Long']>;
};

export type GetVisitJobProtocolsInput = {
  repeatingDay?: InputMaybe<Scalars['Long']>;
  visitId: Scalars['Long'];
};

export type GetVisitJoinedOthersConflictsInput = {
  companyId: Scalars['Int'];
  fromDate: Scalars['Long'];
  toDate: Scalars['Long'];
};

export type GetVisitJoinedOthersTimesheetsInput = {
  companyId: Scalars['Int'];
  fromDate: Scalars['Long'];
  includeMaterials?: InputMaybe<Scalars['Boolean']>;
  includeProducts?: InputMaybe<Scalars['Boolean']>;
  toDate: Scalars['Long'];
};

export type GetVisitsInput = {
  companyId: Scalars['Int'];
  fromDate: Scalars['Long'];
  includeJobOfferSeats?: InputMaybe<Scalars['Boolean']>;
  includeScheduleLater?: InputMaybe<Scalars['Boolean']>;
  jobId?: InputMaybe<Scalars['Long']>;
  toDate: Scalars['Long'];
  visitIds?: InputMaybe<Array<Scalars['Long']>>;
};

export type GetVisitsJoinedOthersInput = {
  companyId: Scalars['Int'];
  fromDate: Scalars['Long'];
  includeJobEmployeeData?: InputMaybe<Scalars['Boolean']>;
  includeScheduleLater?: InputMaybe<Scalars['Boolean']>;
  jobId?: InputMaybe<Scalars['Long']>;
  toDate: Scalars['Long'];
  visitIds?: InputMaybe<Array<Scalars['Long']>>;
};

export type GpsLocationResponse = {
  __typename?: 'GpsLocationResponse';
  companyId: Scalars['Int'];
  createdAt: Scalars['Long'];
  device: Scalars['String'];
  employeeId: Scalars['Int'];
  id: Scalars['Long'];
  jobId?: Maybe<Scalars['Long']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  os: OsType;
  status: GpsLocationStatus;
  visitId?: Maybe<Scalars['Long']>;
};

export enum GpsLocationStatus {
  DiagnosesOk = 'DiagnosesOk',
  FusedUnsupported = 'FusedUnsupported',
  None = 'None',
  PermissionDisabled = 'PermissionDisabled',
  ProvidersRestricted = 'ProvidersRestricted',
  ServiceAndPermissionDisabled = 'ServiceAndPermissionDisabled',
  ServiceDisabled = 'ServiceDisabled'
}

export type IndustryResponse = {
  __typename?: 'IndustryResponse';
  id: Scalars['Int'];
  name: Scalars['String'];
  translationKey: Scalars['String'];
};

export type IndustryResponsePage = {
  __typename?: 'IndustryResponsePage';
  content: Array<IndustryResponse>;
};

export enum ItemPaymentType {
  Extra = 'Extra',
  Fixed = 'Fixed',
  Hourly = 'Hourly',
  None = 'None',
  StartEnd = 'StartEnd',
  TravelDistance = 'TravelDistance',
  TravelFixed = 'TravelFixed',
  TravelHourly = 'TravelHourly',
  TravelStartEnd = 'TravelStartEnd'
}

export type JobDetailResponse = {
  __typename?: 'JobDetailResponse';
  client?: Maybe<ClientPureResponse>;
  clientContacts: Array<ClientContactResponse>;
  createdByEmployee: EmployeeJoinedUserResponse;
  createdByUser: UserResponse;
  files: Array<FileResponse>;
  hasAnyVisit: Scalars['Boolean'];
  job: JobResponse;
  jobEmployeeStats?: Maybe<Array<JobEmployeeStatsResponse>>;
  responsiblePerson: EmployeeJoinedUserResponse;
  stats?: Maybe<JobStatsResponse>;
};

export type JobEmployeeDataResponse = {
  __typename?: 'JobEmployeeDataResponse';
  companyId: Scalars['Int'];
  createdAt: Scalars['Long'];
  employeeId: Scalars['Int'];
  id: Scalars['Long'];
  ignoreForVisitStatus: Scalars['Boolean'];
  jobId: Scalars['Long'];
  jobOfferSeatUuid?: Maybe<Scalars['String']>;
  previousStatus: JobEmployeeStatus;
  repeatingDay?: Maybe<Scalars['Long']>;
  status: JobEmployeeStatus;
  visitId: Scalars['Long'];
};

export type JobEmployeeDataResponsePage = {
  __typename?: 'JobEmployeeDataResponsePage';
  content: Array<JobEmployeeDataResponse>;
};

export type JobEmployeeStatsResponse = {
  __typename?: 'JobEmployeeStatsResponse';
  canceled: Scalars['Int'];
  done: Scalars['Int'];
  employeeId: Scalars['Int'];
  hasNeverEndingVisits: Scalars['Boolean'];
  hasRepeatingVisits: Scalars['Boolean'];
  id: Scalars['Long'];
  inProgress: Scalars['Int'];
  jobId: Scalars['Long'];
  jobOffer: Scalars['Int'];
  notArrived: Scalars['Int'];
  scheduleLater: Scalars['Int'];
  scheduled: Scalars['Int'];
  travelling: Scalars['Int'];
  visitsCount: Scalars['Int'];
};

export enum JobEmployeeStatus {
  CanceledByManager = 'CanceledByManager',
  CanceledByWorker = 'CanceledByWorker',
  Done = 'Done',
  InProgress = 'InProgress',
  JobOffer = 'JobOffer',
  None = 'None',
  NotArrived = 'NotArrived',
  ScheduleLater = 'ScheduleLater',
  Scheduled = 'Scheduled',
  Travelling = 'Travelling'
}

export type JobEmployeeTimesheetItemResponse = {
  __typename?: 'JobEmployeeTimesheetItemResponse';
  approved: Scalars['Boolean'];
  companyId: Scalars['Int'];
  createdAt: Scalars['Long'];
  createdByEmployeeId: Scalars['Int'];
  deleted: Scalars['Boolean'];
  distance?: Maybe<Scalars['Float']>;
  distanceRate?: Maybe<Scalars['Float']>;
  employeeId: Scalars['Int'];
  endTime?: Maybe<Scalars['Long']>;
  fixedPrice?: Maybe<Scalars['Float']>;
  hourRate?: Maybe<Scalars['Float']>;
  hours?: Maybe<Scalars['Float']>;
  id: Scalars['Long'];
  jobId: Scalars['Long'];
  minutes?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  originalJobEmployeeTimesheetId?: Maybe<Scalars['Long']>;
  paymentType: ItemPaymentType;
  repeatingDay?: Maybe<Scalars['Long']>;
  startTime?: Maybe<Scalars['Long']>;
  totalPrice?: Maybe<Scalars['Float']>;
  uuid: Scalars['String'];
  visitId: Scalars['Long'];
};

export type JobEmployeeTimesheetItemResponsePage = {
  __typename?: 'JobEmployeeTimesheetItemResponsePage';
  content: Array<JobEmployeeTimesheetItemResponse>;
};

export type JobFormElementFragment = {
  __typename?: 'JobFormElementFragment';
  createdAt?: Maybe<Scalars['Long']>;
  done: Scalars['Boolean'];
  doneAt?: Maybe<Scalars['Long']>;
  doneByEmployeeId?: Maybe<Scalars['Int']>;
  doneValue?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Long']>;
  jobFormId?: Maybe<Scalars['Long']>;
  label: Scalars['String'];
  options: Array<Scalars['String']>;
  position: Scalars['Int'];
  required: Scalars['Boolean'];
  sectionIndex: Scalars['Int'];
  type: JobFormElementType;
  uuid?: Maybe<Scalars['String']>;
};

export type JobFormElementFragmentInput = {
  createdAt?: InputMaybe<Scalars['Long']>;
  done: Scalars['Boolean'];
  doneAt?: InputMaybe<Scalars['Long']>;
  doneByEmployeeId?: InputMaybe<Scalars['Int']>;
  doneValue?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Long']>;
  jobFormId?: InputMaybe<Scalars['Long']>;
  label: Scalars['String'];
  options: Array<Scalars['String']>;
  position: Scalars['Int'];
  required: Scalars['Boolean'];
  sectionIndex: Scalars['Int'];
  type: JobFormElementType;
  uuid?: InputMaybe<Scalars['String']>;
};

export enum JobFormElementType {
  Checkbox = 'Checkbox',
  Datepicker = 'Datepicker',
  Dropdown = 'Dropdown',
  MultilineText = 'MultilineText',
  None = 'None',
  Number = 'Number',
  Radio = 'Radio',
  Text = 'Text',
  Timepicker = 'Timepicker',
  ValueRange = 'ValueRange'
}

export type JobFormPureResponse = {
  __typename?: 'JobFormPureResponse';
  companyId: Scalars['Int'];
  createdAt?: Maybe<Scalars['Long']>;
  deleted: Scalars['Boolean'];
  id?: Maybe<Scalars['Long']>;
  isTemplate: Scalars['Boolean'];
  jobId?: Maybe<Scalars['Long']>;
  name: Scalars['String'];
  position: Scalars['Int'];
  repeatingDay?: Maybe<Scalars['Long']>;
  sections: Array<Scalars['String']>;
  templateId?: Maybe<Scalars['Long']>;
  uuid?: Maybe<Scalars['String']>;
  visitId?: Maybe<Scalars['Long']>;
};

export type JobFormPureResponsePage = {
  __typename?: 'JobFormPureResponsePage';
  content: Array<JobFormPureResponse>;
};

export type JobFormResponse = {
  __typename?: 'JobFormResponse';
  companyId: Scalars['Int'];
  createdAt?: Maybe<Scalars['Long']>;
  deleted: Scalars['Boolean'];
  elements: Array<JobFormElementFragment>;
  id?: Maybe<Scalars['Long']>;
  isTemplate: Scalars['Boolean'];
  jobId?: Maybe<Scalars['Long']>;
  name: Scalars['String'];
  position: Scalars['Int'];
  repeatingDay?: Maybe<Scalars['Long']>;
  sections: Array<Scalars['String']>;
  templateId?: Maybe<Scalars['Long']>;
  uuid?: Maybe<Scalars['String']>;
  visitId?: Maybe<Scalars['Long']>;
};

export type JobFormResponsePage = {
  __typename?: 'JobFormResponsePage';
  content: Array<JobFormResponse>;
};

export type JobJoinedOthersResponseResponsePage = {
  __typename?: 'JobJoinedOthersResponseResponsePage';
  clients: Array<ClientPureResponse>;
  employees: Array<EmployeeJoinedUserResponse>;
  files: Array<FileResponse>;
  jobs: Array<JobResponse>;
  notScheduledCount: Scalars['Long'];
  stats: Array<JobStatsResponse>;
};

export enum JobLogEvent {
  AddJobOfferSeatToVisit = 'AddJobOfferSeatToVisit',
  AddProtocolToVisit = 'AddProtocolToVisit',
  CancelVisitByManager = 'CancelVisitByManager',
  CloseJobOfferSeatOfVisit = 'CloseJobOfferSeatOfVisit',
  CloseVisit = 'CloseVisit',
  CreateJob = 'CreateJob',
  CreateVisit = 'CreateVisit',
  CreateVisitForJob = 'CreateVisitForJob',
  DeleteJob = 'DeleteJob',
  DeleteJobOfferSeatFromVisit = 'DeleteJobOfferSeatFromVisit',
  DeleteVisit = 'DeleteVisit',
  EmployeeResponseToJobOfferSeat = 'EmployeeResponseToJobOfferSeat',
  EmployeeResponseToJobOfferSeatAccepted = 'EmployeeResponseToJobOfferSeatAccepted',
  EmployeeResponseToJobOfferSeatRejected = 'EmployeeResponseToJobOfferSeatRejected',
  EmployeeUpdateEmployeeStatus = 'EmployeeUpdateEmployeeStatus',
  JobFormAdded = 'JobFormAdded',
  JobFormChanged = 'JobFormChanged',
  JobFormDeleted = 'JobFormDeleted',
  JobFormElementDoneStateChanged = 'JobFormElementDoneStateChanged',
  JobFormUpdated = 'JobFormUpdated',
  ManagerUpdateEmployeeStatus = 'ManagerUpdateEmployeeStatus',
  None = 'None',
  OpenVisit = 'OpenVisit',
  ReactivateVisit = 'ReactivateVisit',
  RemoveSubstituteFromJobOfferSeat = 'RemoveSubstituteFromJobOfferSeat',
  ScheduleVisitForJob = 'ScheduleVisitForJob',
  SetJobEmployeeStatusAsIgnored = 'SetJobEmployeeStatusAsIgnored',
  UpdateJobOfferSeatOfVisit = 'UpdateJobOfferSeatOfVisit',
  UpdateStatusModifier = 'UpdateStatusModifier',
  UpdateVisitAssignedEmployees = 'UpdateVisitAssignedEmployees'
}

export type JobLogResponse = {
  __typename?: 'JobLogResponse';
  companyId: Scalars['Int'];
  createdAt: Scalars['Long'];
  currentEmployeeId: Scalars['Int'];
  currentUserId: Scalars['Int'];
  employeeId?: Maybe<Scalars['Int']>;
  employeeStatus?: Maybe<JobEmployeeStatus>;
  event: JobLogEvent;
  gpsLocationId?: Maybe<Scalars['Long']>;
  id: Scalars['Long'];
  ignoreForVisitStatus: Scalars['Boolean'];
  jobFormElementId?: Maybe<Scalars['Long']>;
  jobFormId?: Maybe<Scalars['Long']>;
  jobFormName?: Maybe<Scalars['String']>;
  jobId: Scalars['Long'];
  message?: Maybe<Scalars['String']>;
  previousEmployeeStatus?: Maybe<JobEmployeeStatus>;
  previousJobFormId?: Maybe<Scalars['Long']>;
  previousJobFormName?: Maybe<Scalars['String']>;
  previousStatusModifier?: Maybe<VisitStatusModifier>;
  previousVisitStatus?: Maybe<VisitStatus>;
  repeatingDay?: Maybe<Scalars['Long']>;
  statusModifier?: Maybe<VisitStatusModifier>;
  visitId?: Maybe<Scalars['Long']>;
  visitStatus?: Maybe<VisitStatus>;
};

export type JobLogResponsePage = {
  __typename?: 'JobLogResponsePage';
  content: Array<JobLogResponse>;
};

export type JobOfferSeatResponse = {
  __typename?: 'JobOfferSeatResponse';
  acceptedIds: Array<Scalars['Int']>;
  cancelled: Scalars['Boolean'];
  closeDeadline: Scalars['Long'];
  companyId: Scalars['Int'];
  completed: Scalars['Boolean'];
  completedAt?: Maybe<Scalars['Long']>;
  createdAt: Scalars['Long'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['Long']>;
  employeeIds: Array<Scalars['Int']>;
  fixedPrice?: Maybe<Scalars['Float']>;
  hourRate?: Maybe<Scalars['Float']>;
  hours?: Maybe<Scalars['Float']>;
  id: Scalars['Long'];
  jobId: Scalars['Long'];
  lastNotification?: Maybe<Scalars['Long']>;
  minutes?: Maybe<Scalars['Float']>;
  paymentType: PaymentType;
  rejectedIds: Array<Scalars['Int']>;
  repeatingDay?: Maybe<Scalars['Long']>;
  substituteCount: Scalars['Int'];
  totalPrice?: Maybe<Scalars['Float']>;
  uuid: Scalars['String'];
  visitId: Scalars['Long'];
};

export type JobOfferSeatResponsePage = {
  __typename?: 'JobOfferSeatResponsePage';
  content: Array<JobOfferSeatResponse>;
};

export type JobProtocolJoinedOthersResponsePage = {
  __typename?: 'JobProtocolJoinedOthersResponsePage';
  content: Array<JobProtocolResponse>;
  employees: Array<EmployeeJoinedUserResponse>;
  files: Array<FileResponse>;
};

export type JobProtocolResponse = {
  __typename?: 'JobProtocolResponse';
  companyId: Scalars['Int'];
  createdAt: Scalars['Long'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['Long']>;
  description?: Maybe<Scalars['String']>;
  employeeId: Scalars['Int'];
  fileIds?: Maybe<Array<Scalars['Long']>>;
  id: Scalars['Long'];
  isDefect: Scalars['Boolean'];
  jobId: Scalars['Long'];
  repeatingDay?: Maybe<Scalars['Long']>;
  signatureFileIds?: Maybe<Array<Scalars['Long']>>;
  userId: Scalars['Int'];
  visitId: Scalars['Long'];
};

export type JobProtocolResponsePage = {
  __typename?: 'JobProtocolResponsePage';
  content: Array<JobProtocolResponse>;
};

export type JobPureResponse = {
  __typename?: 'JobPureResponse';
  clientContactIds: Array<Scalars['Int']>;
  clientId?: Maybe<Scalars['Int']>;
  companyId: Scalars['Int'];
  createdAt: Scalars['Long'];
  createdEmployeeId: Scalars['Int'];
  createdUserId: Scalars['Int'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['Long']>;
  id: Scalars['Long'];
  name?: Maybe<Scalars['String']>;
  responsiblePersonId: Scalars['Int'];
  sequenceId: Scalars['Long'];
  templateId?: Maybe<Scalars['Long']>;
  timeZone: Scalars['String'];
};

export type JobResponse = {
  __typename?: 'JobResponse';
  clientContactIds: Array<Scalars['Int']>;
  clientId?: Maybe<Scalars['Int']>;
  companyId: Scalars['Int'];
  createdAt: Scalars['Long'];
  createdEmployeeId: Scalars['Int'];
  createdUserId: Scalars['Int'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['Long']>;
  id: Scalars['Long'];
  name?: Maybe<Scalars['String']>;
  responsiblePersonId: Scalars['Int'];
  sequenceId: Scalars['Long'];
  stats?: Maybe<JobStatsResponse>;
  templateId?: Maybe<Scalars['Long']>;
  timeZone: Scalars['String'];
};

export type JobResponsePage = {
  __typename?: 'JobResponsePage';
  content: Array<JobResponse>;
};

export type JobStatsResponse = {
  __typename?: 'JobStatsResponse';
  canceled: Scalars['Int'];
  closed: Scalars['Int'];
  done: Scalars['Int'];
  hasNeverEndingVisits: Scalars['Boolean'];
  hasRepeatingVisits: Scalars['Boolean'];
  id: Scalars['Long'];
  inProgress: Scalars['Int'];
  invoiced: Scalars['Int'];
  jobId: Scalars['Long'];
  jobOffer: Scalars['Int'];
  notPaid: Scalars['Int'];
  paid: Scalars['Int'];
  scheduleLater: Scalars['Int'];
  scheduled: Scalars['Int'];
  travelling: Scalars['Int'];
  unfinished: Scalars['Int'];
  visitsCount: Scalars['Int'];
};

export enum JobUpdateRepeats {
  None = 'None',
  Single = 'Single',
  ThisAndAllFuture = 'ThisAndAllFuture'
}

export type JoinCompanyWithPasscodeInput = {
  passcode: Scalars['String'];
};

export type LayoffCompanyEmployeeInput = {
  employeeId: Scalars['Int'];
};

export type LocationJoinedOthersResponsePage = {
  __typename?: 'LocationJoinedOthersResponsePage';
  clientPure?: Maybe<ClientPureResponse>;
  locations: Array<LocationResponse>;
};

export type LocationPlaceProfileResponse = {
  __typename?: 'LocationPlaceProfileResponse';
  client: ClientPureResponse;
  clientContacts?: Maybe<Array<ClientContactResponse>>;
  jobForm?: Maybe<JobFormResponse>;
  location: LocationPureResponse;
  place: LocationPlaceResponse;
};

export type LocationPlaceResponse = {
  __typename?: 'LocationPlaceResponse';
  clientContactIds: Array<Scalars['Int']>;
  createdAt: Scalars['Long'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['Long']>;
  icon: Scalars['String'];
  id: Scalars['Long'];
  jobFormIds: Array<Scalars['Long']>;
  locationId: Scalars['Int'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type LocationPlaceResponsePage = {
  __typename?: 'LocationPlaceResponsePage';
  content: Array<LocationPlaceResponse>;
};

export type LocationProfileResponse = {
  __typename?: 'LocationProfileResponse';
  client: ClientPureResponse;
  clientContacts?: Maybe<Array<ClientContactResponse>>;
  jobForm?: Maybe<JobFormResponse>;
  location: LocationResponse;
};

export type LocationPureResponse = {
  __typename?: 'LocationPureResponse';
  address: Address;
  addressSingleLine: Scalars['String'];
  clientContactIds: Array<Scalars['Int']>;
  clientId: Scalars['Int'];
  companyId: Scalars['Int'];
  createdAt: Scalars['Long'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['Long']>;
  icon: Scalars['String'];
  id: Scalars['Int'];
  jobFormIds: Array<Scalars['Long']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  specification?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type LocationPureResponsePage = {
  __typename?: 'LocationPureResponsePage';
  content: Array<LocationPureResponse>;
};

export type LocationResponse = {
  __typename?: 'LocationResponse';
  address: Address;
  addressSingleLine: Scalars['String'];
  clientContactIds: Array<Scalars['Int']>;
  clientId: Scalars['Int'];
  companyId: Scalars['Int'];
  createdAt: Scalars['Long'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['Long']>;
  icon: Scalars['String'];
  id: Scalars['Int'];
  jobFormIds: Array<Scalars['Long']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  places: Array<LocationPlaceResponse>;
  specification?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type LocationResponsePage = {
  __typename?: 'LocationResponsePage';
  content: Array<LocationResponse>;
};

export enum LoginProvider {
  Apple = 'apple',
  Email = 'email',
  Facebook = 'facebook',
  Google = 'google',
  None = 'none'
}

export type ManageAssignedClientContactsFromLocationPlaceInput = {
  clientContacts: Array<Scalars['Int']>;
  locationPlaceId: Scalars['Long'];
};

export type ManageAssignedClientContactsOfLocationInput = {
  clientContacts: Array<Scalars['Int']>;
  locationId: Scalars['Int'];
};

export type ManageAssignedJobFormsOfLocationInput = {
  jobForms: Array<Scalars['Long']>;
  locationId: Scalars['Int'];
};

export type ManageAssignedJobFormsOfLocationPlaceInput = {
  jobForms: Array<Scalars['Long']>;
  locationPlaceId: Scalars['Long'];
};

export type MaterialResponse = {
  __typename?: 'MaterialResponse';
  companyId: Scalars['Int'];
  createdAt: Scalars['Long'];
  deleted: Scalars['Boolean'];
  employeeId?: Maybe<Scalars['Int']>;
  id: Scalars['Long'];
  isTemplate: Scalars['Boolean'];
  jobId?: Maybe<Scalars['Long']>;
  name: Scalars['String'];
  repeatingDay?: Maybe<Scalars['Long']>;
  sortOrder: Scalars['Int'];
  templateId?: Maybe<Scalars['Long']>;
  unitCount?: Maybe<Scalars['Float']>;
  unitName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Long'];
  uuid?: Maybe<Scalars['String']>;
  visitId?: Maybe<Scalars['Long']>;
};

export type MaterialResponsePage = {
  __typename?: 'MaterialResponsePage';
  content: Array<MaterialResponse>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addJobEmployeeTimesheetItemToVisit: VisitResponse;
  addJobOfferSeatToVisit: VisitResponse;
  addJobProtocolToVisit: VisitResponse;
  addNoteItem: NoteItemResponse;
  addUserDevice: Scalars['Boolean'];
  addVisitJobForm: VisitResponse;
  addVisitMaterial: VisitResponse;
  addVisitProduct: VisitResponse;
  adminAddSubscriptionToCompany: SubscriptionResponse;
  adminDeleteSubscriptionTemplate: SubscriptionResponse;
  adminEditCompanySubscription: SubscriptionResponse;
  adminEndCompanySubscription: SubscriptionResponse;
  adminSaveSubscriptionTemplate: SubscriptionResponse;
  adminSaveTimotySettingsItemsForKeys: TimotySettingsItemResponsePage;
  adminUserSetAdmin: UserResponse;
  adminUserSignIn: AdminUserSignInResponse;
  assignClientContactsToJob: JobResponse;
  assignClientContactsToLocation: LocationResponse;
  assignClientContactsToLocationPlace: LocationPlaceResponse;
  assignClientContactsToVisit: VisitResponse;
  assignIndustriesToCompany: CompanyResponse;
  cancelVisitByManager: VisitResponse;
  claimFiles: FileResponsePage;
  closeJobOfferSeatOfVisit: VisitResponse;
  closeVisit: VisitResponse;
  createClient: ClientResponse;
  createClientContact: ClientContactResponse;
  createCompany: CompanyResponse;
  createCompanyEmployee: EmployeeResponse;
  createFile: FileResponse;
  createJob: JobResponse;
  createJobFormTemplate: JobFormResponse;
  createLocation: LocationResponse;
  createLocationPlace: LocationPlaceResponse;
  createLocationPlaces: CreateLocationPlacesResponse;
  createMaterialTemplate: MaterialResponse;
  createOrExistingCompanyEmployeePasscode: Scalars['String'];
  createProductTemplate: ProductResponse;
  createVisitForJob: CreateVisitForJobResponse;
  deleteClient: Scalars['Boolean'];
  deleteClientContact: ClientResponse;
  deleteCompany: UserResponse;
  deleteCompanyEmployee: EmployeeResponse;
  deleteEmployeePermissions: EmployeeResponse;
  deleteJob: JobResponse;
  deleteJobEmployeeTimesheetItemFromVisit: VisitResponse;
  deleteJobFormTemplate: JobFormResponse;
  deleteJobOfferSeatFromVisit: VisitResponse;
  deleteJobProtocolFromVisit: VisitResponse;
  deleteLocation: Scalars['Boolean'];
  deleteLocationPlace: LocationResponse;
  deleteMaterialTemplate: MaterialResponse;
  deleteNoteItem: NoteItemResponse;
  deleteProductTemplate: ProductResponse;
  deleteVisit: VisitResponse;
  deleteVisitJobForm: VisitResponse;
  deleteVisitMaterial: VisitResponse;
  deleteVisitProduct: VisitResponse;
  employeeResponseToJobOfferSeatOfVisit: VisitResponse;
  joinCompanyWithPasscode: EmployeeResponse;
  layoffCompanyEmployee: EmployeeResponse;
  manageAssignedClientContactsOfLocation: LocationResponse;
  manageAssignedClientContactsOfLocationPlace: LocationPlaceResponse;
  manageAssignedJobFormsOfLocation: LocationResponse;
  manageAssignedJobFormsOfLocationPlace: LocationPlaceResponse;
  notifyEmployeesOfVisit: Scalars['Boolean'];
  openVisit: VisitResponse;
  reActivateCompanyEmployee: EmployeeResponse;
  reactivateVisit: VisitResponse;
  removeAssignedClientContactsFromLocation: LocationResponse;
  removeAssignedClientContactsFromLocationPlace: LocationPlaceResponse;
  removeSubstituteFromJobOfferSeatOfVisit: VisitResponse;
  removeUserDevice: Scalars['Boolean'];
  renameVisitJobForm: VisitResponse;
  saveEmployeePermissions: EmployeeResponse;
  savePermissionsForRole: EmployeePermissionsResponse;
  saveUserPreferencesItemByKey: UserPreferencesItemResponse;
  saveUserPreferencesItemsForKeys: UserPreferencesItemResponsePage;
  scheduleNonRepeatingVisitForJob: ScheduleVisitForJobResponse;
  scheduleVisitForJob: ScheduleVisitForJobResponse;
  sendNotificationToUsers: Scalars['Boolean'];
  setJobEmployeeStatusAsIgnoredOfVisit: VisitResponse;
  updateClient: ClientResponse;
  updateClientContact: ClientContactResponse;
  updateCompany: CompanyResponse;
  updateCompanyEmployee: EmployeeJoinedUserResponse;
  updateCompanyGeneralSettings: CompanyResponse;
  updateCompanyLogo: CompanyResponse;
  updateFileLabel: FileResponse;
  updateJobClient: JobResponse;
  updateJobDetails: JobResponse;
  updateJobEmployeeStatusOfVisit: VisitResponse;
  updateJobEmployeeStatusOfVisitByEmployee: VisitResponse;
  updateJobEmployeeTimesheetItemOfVisit: VisitResponse;
  updateJobEmployeeTimesheetItemsApprovalOfVisit: VisitResponse;
  updateJobFormPosition: JobFormResponsePage;
  updateJobFormTemplate: JobFormResponse;
  updateJobOfferSeatOfVisit: VisitResponse;
  updateJobResponsiblePerson: JobResponse;
  updateLocation: LocationResponse;
  updateLocationPlace: LocationPlaceResponse;
  updateMaterialTemplate: MaterialResponse;
  updateNoteItem: NoteItemResponse;
  updateProductTemplate: ProductResponse;
  updateUserPhoto: UserResponse;
  updateVisitAssignedEmployees: VisitResponse;
  updateVisitAttachments: VisitResponse;
  updateVisitDateTime: VisitResponse;
  updateVisitDateTimeOnly: VisitResponse;
  updateVisitDetails: VisitResponse;
  updateVisitInvoiceDeliveryNumbers: VisitResponse;
  updateVisitJobFormElementDoneState: VisitResponse;
  updateVisitLocation: VisitResponse;
  updateVisitLocationPlace: VisitResponse;
  updateVisitMaterial: VisitResponse;
  updateVisitProduct: VisitResponse;
  updateVisitStatusModifier: VisitResponse;
  userChangeEmail: UserResponse;
  userDeleteRequest: UserDeleteResponse;
  userPersonalDetails: UserResponse;
  userSignIn: UserSignInResponse;
  userUpdateLanguage: UserResponse;
};


export type MutationAddJobEmployeeTimesheetItemToVisitArgs = {
  input: AddJobEmployeeTimesheetItemToVisitInput;
};


export type MutationAddJobOfferSeatToVisitArgs = {
  input: AddJobOfferSeatToVisitInput;
};


export type MutationAddJobProtocolToVisitArgs = {
  input: AddJobProtocolToVisitInput;
};


export type MutationAddNoteItemArgs = {
  input: AddNoteItemInput;
};


export type MutationAddUserDeviceArgs = {
  input: AddUserDeviceInput;
};


export type MutationAddVisitJobFormArgs = {
  input: AddVisitJobFormInput;
};


export type MutationAddVisitMaterialArgs = {
  input: AddVisitMaterialInput;
};


export type MutationAddVisitProductArgs = {
  input: AddVisitProductInput;
};


export type MutationAdminAddSubscriptionToCompanyArgs = {
  input: AdminAddSubscriptionToCompanyInput;
};


export type MutationAdminDeleteSubscriptionTemplateArgs = {
  input: AdminDeleteSubscriptionTemplateInput;
};


export type MutationAdminEditCompanySubscriptionArgs = {
  input: AdminEditCompanySubscriptionInput;
};


export type MutationAdminEndCompanySubscriptionArgs = {
  input: AdminEndCompanySubscriptionInput;
};


export type MutationAdminSaveSubscriptionTemplateArgs = {
  input: AdminSaveSubscriptionTemplateInput;
};


export type MutationAdminSaveTimotySettingsItemsForKeysArgs = {
  input: SaveTimotySettingsItemsForKeysInput;
};


export type MutationAdminUserSetAdminArgs = {
  input: AdminUserSetAdminInput;
};


export type MutationAdminUserSignInArgs = {
  input: AdminSignInInput;
};


export type MutationAssignClientContactsToJobArgs = {
  input: AssignClientContactsToJobInput;
};


export type MutationAssignClientContactsToLocationArgs = {
  input: AssignClientContactsToLocationInput;
};


export type MutationAssignClientContactsToLocationPlaceArgs = {
  input: AssignClientContactsToLocationPlaceInput;
};


export type MutationAssignClientContactsToVisitArgs = {
  input: AssignClientContactsToVisitInput;
};


export type MutationAssignIndustriesToCompanyArgs = {
  input: AssignIndustriesInput;
};


export type MutationCancelVisitByManagerArgs = {
  input: CancelVisitByManagerInput;
};


export type MutationClaimFilesArgs = {
  input: ClaimFilesInput;
};


export type MutationCloseJobOfferSeatOfVisitArgs = {
  input: CloseJobOfferSeatOfVisitInput;
};


export type MutationCloseVisitArgs = {
  input: CloseVisitInput;
};


export type MutationCreateClientArgs = {
  input: CreateClientInput;
};


export type MutationCreateClientContactArgs = {
  input: CreateClientContactInput;
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type MutationCreateCompanyEmployeeArgs = {
  input: CreateCompanyEmployeeInput;
};


export type MutationCreateFileArgs = {
  input: CreateFileInput;
};


export type MutationCreateJobArgs = {
  input: CreateJobInput;
};


export type MutationCreateJobFormTemplateArgs = {
  input: CreateJobFormTemplateInput;
};


export type MutationCreateLocationArgs = {
  input: CreateLocationInput;
};


export type MutationCreateLocationPlaceArgs = {
  input: CreateLocationPlaceInput;
};


export type MutationCreateLocationPlacesArgs = {
  input: CreateLocationPlacesInput;
};


export type MutationCreateMaterialTemplateArgs = {
  input: CreateMaterialTemplateInput;
};


export type MutationCreateOrExistingCompanyEmployeePasscodeArgs = {
  input: CreateCompanyEmployeePasscodeInput;
};


export type MutationCreateProductTemplateArgs = {
  input: CreateProductTemplateInput;
};


export type MutationCreateVisitForJobArgs = {
  input: CreateVisitForJobInput;
};


export type MutationDeleteClientArgs = {
  input: DeleteClientInput;
};


export type MutationDeleteClientContactArgs = {
  input: DeleteClientContactInput;
};


export type MutationDeleteCompanyArgs = {
  input: DeleteCompanyInput;
};


export type MutationDeleteCompanyEmployeeArgs = {
  input: DeleteCompanyEmployeeInput;
};


export type MutationDeleteEmployeePermissionsArgs = {
  input: DeleteEmployeePermissionsInput;
};


export type MutationDeleteJobArgs = {
  input: DeleteJobInput;
};


export type MutationDeleteJobEmployeeTimesheetItemFromVisitArgs = {
  input: DeleteJobEmployeeTimesheetItemFromVisitInput;
};


export type MutationDeleteJobFormTemplateArgs = {
  input: DeleteJobFormTemplateInput;
};


export type MutationDeleteJobOfferSeatFromVisitArgs = {
  input: DeleteJobOfferSeatFromVisitInput;
};


export type MutationDeleteJobProtocolFromVisitArgs = {
  input: DeleteJobProtocolFromVisitInput;
};


export type MutationDeleteLocationArgs = {
  input: DeleteLocationInput;
};


export type MutationDeleteLocationPlaceArgs = {
  input: DeleteLocationPlaceInput;
};


export type MutationDeleteMaterialTemplateArgs = {
  input: DeleteMaterialTemplateInput;
};


export type MutationDeleteNoteItemArgs = {
  input: DeleteNoteItemInput;
};


export type MutationDeleteProductTemplateArgs = {
  input: DeleteProductTemplateInput;
};


export type MutationDeleteVisitArgs = {
  input: DeleteVisitInput;
};


export type MutationDeleteVisitJobFormArgs = {
  input: DeleteVisitJobFormInput;
};


export type MutationDeleteVisitMaterialArgs = {
  input: DeleteVisitMaterialInput;
};


export type MutationDeleteVisitProductArgs = {
  input: DeleteVisitProductInput;
};


export type MutationEmployeeResponseToJobOfferSeatOfVisitArgs = {
  input: EmployeeResponseToJobOfferSeatOfVisitInput;
};


export type MutationJoinCompanyWithPasscodeArgs = {
  input: JoinCompanyWithPasscodeInput;
};


export type MutationLayoffCompanyEmployeeArgs = {
  input: LayoffCompanyEmployeeInput;
};


export type MutationManageAssignedClientContactsOfLocationArgs = {
  input: ManageAssignedClientContactsOfLocationInput;
};


export type MutationManageAssignedClientContactsOfLocationPlaceArgs = {
  input: ManageAssignedClientContactsFromLocationPlaceInput;
};


export type MutationManageAssignedJobFormsOfLocationArgs = {
  input: ManageAssignedJobFormsOfLocationInput;
};


export type MutationManageAssignedJobFormsOfLocationPlaceArgs = {
  input: ManageAssignedJobFormsOfLocationPlaceInput;
};


export type MutationNotifyEmployeesOfVisitArgs = {
  input: NotifyEmployeesOfVisitInput;
};


export type MutationOpenVisitArgs = {
  input: OpenVisitInput;
};


export type MutationReActivateCompanyEmployeeArgs = {
  input: ReActivateCompanyEmployeeInput;
};


export type MutationReactivateVisitArgs = {
  input: ReactivateVisitInput;
};


export type MutationRemoveAssignedClientContactsFromLocationArgs = {
  input: RemoveAssignClientContactsFromLocationInput;
};


export type MutationRemoveAssignedClientContactsFromLocationPlaceArgs = {
  input: RemoveClientContactsToLocationPlaceInput;
};


export type MutationRemoveSubstituteFromJobOfferSeatOfVisitArgs = {
  input: RemoveSubstituteFromJobOfferSeatOfVisitInput;
};


export type MutationRemoveUserDeviceArgs = {
  input: RemoveUserDeviceInput;
};


export type MutationRenameVisitJobFormArgs = {
  input: RenameVisitJobFormInput;
};


export type MutationSaveEmployeePermissionsArgs = {
  input: SavePermissionsForEmployeeInput;
};


export type MutationSavePermissionsForRoleArgs = {
  input: SavePermissionsForRoleInput;
};


export type MutationSaveUserPreferencesItemByKeyArgs = {
  input: SaveUserPreferencesItemByKeyInput;
};


export type MutationSaveUserPreferencesItemsForKeysArgs = {
  input: SaveUserPreferencesItemsForKeysInput;
};


export type MutationScheduleNonRepeatingVisitForJobArgs = {
  input: ScheduleNonRepeatingVisitForJobInput;
};


export type MutationScheduleVisitForJobArgs = {
  input: ScheduleVisitForJobInput;
};


export type MutationSendNotificationToUsersArgs = {
  input: SendNotificationToUsersInput;
};


export type MutationSetJobEmployeeStatusAsIgnoredOfVisitArgs = {
  input: SetJobEmployeeStatusAsIgnoredOfVisitInput;
};


export type MutationUpdateClientArgs = {
  input: UpdateClientInput;
};


export type MutationUpdateClientContactArgs = {
  input: UpdateClientContactInput;
};


export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};


export type MutationUpdateCompanyEmployeeArgs = {
  input: UpdateCompanyEmployeeInput;
};


export type MutationUpdateCompanyGeneralSettingsArgs = {
  input: UpdateCompanyGeneralSettingsInput;
};


export type MutationUpdateCompanyLogoArgs = {
  input: UpdateCompanyLogoInput;
};


export type MutationUpdateFileLabelArgs = {
  input: UpdateFileLabelInput;
};


export type MutationUpdateJobClientArgs = {
  input: UpdateJobClientInput;
};


export type MutationUpdateJobDetailsArgs = {
  input: UpdateJobDetailsInput;
};


export type MutationUpdateJobEmployeeStatusOfVisitArgs = {
  input: UpdateJobEmployeeStatusOfVisitInput;
};


export type MutationUpdateJobEmployeeStatusOfVisitByEmployeeArgs = {
  input: UpdateJobEmployeeStatusOfVisitByEmployeeInput;
};


export type MutationUpdateJobEmployeeTimesheetItemOfVisitArgs = {
  input: UpdateJobEmployeeTimesheetItemOfVisitInput;
};


export type MutationUpdateJobEmployeeTimesheetItemsApprovalOfVisitArgs = {
  input: UpdateJobEmployeeTimesheetItemsApprovalOfVisitInput;
};


export type MutationUpdateJobFormPositionArgs = {
  input: UpdateJobFormPositionInput;
};


export type MutationUpdateJobFormTemplateArgs = {
  input: UpdateJobFormTemplateInput;
};


export type MutationUpdateJobOfferSeatOfVisitArgs = {
  input: UpdateJobOfferSeatOfVisitInput;
};


export type MutationUpdateJobResponsiblePersonArgs = {
  input: UpdateJobResponsiblePersonInput;
};


export type MutationUpdateLocationArgs = {
  input: UpdateLocationInput;
};


export type MutationUpdateLocationPlaceArgs = {
  input: UpdateLocationPlaceInput;
};


export type MutationUpdateMaterialTemplateArgs = {
  input: UpdateMaterialTemplateInput;
};


export type MutationUpdateNoteItemArgs = {
  input: UpdateNoteItemInput;
};


export type MutationUpdateProductTemplateArgs = {
  input: UpdateProductTemplateInput;
};


export type MutationUpdateUserPhotoArgs = {
  input: UpdateUserPhotoInput;
};


export type MutationUpdateVisitAssignedEmployeesArgs = {
  input: UpdateVisitAssignedEmployeesInput;
};


export type MutationUpdateVisitAttachmentsArgs = {
  input: UpdateVisitAttachmentsInput;
};


export type MutationUpdateVisitDateTimeArgs = {
  input: UpdateVisitDateTimeInput;
};


export type MutationUpdateVisitDateTimeOnlyArgs = {
  input: UpdateVisitDateTimeOnlyInput;
};


export type MutationUpdateVisitDetailsArgs = {
  input: UpdateVisitDetailsInput;
};


export type MutationUpdateVisitInvoiceDeliveryNumbersArgs = {
  input: UpdateVisitInvoiceDeliveryNumbersInput;
};


export type MutationUpdateVisitJobFormElementDoneStateArgs = {
  input: UpdateVisitJobFormElementDoneStateInput;
};


export type MutationUpdateVisitLocationArgs = {
  input: UpdateVisitLocationInput;
};


export type MutationUpdateVisitLocationPlaceArgs = {
  input: UpdateVisitLocationPlaceInput;
};


export type MutationUpdateVisitMaterialArgs = {
  input: UpdateVisitMaterialInput;
};


export type MutationUpdateVisitProductArgs = {
  input: UpdateVisitProductInput;
};


export type MutationUpdateVisitStatusModifierArgs = {
  input: UpdateVisitStatusModifierInput;
};


export type MutationUserChangeEmailArgs = {
  input: UserChangeEmailInput;
};


export type MutationUserPersonalDetailsArgs = {
  input: UserPersonalDetailsInput;
};


export type MutationUserSignInArgs = {
  input: SignInInput;
};


export type MutationUserUpdateLanguageArgs = {
  input: UserUpdateLanguageInput;
};

export type NoteItemProfileResponse = {
  __typename?: 'NoteItemProfileResponse';
  employee?: Maybe<EmployeeJoinedUserResponse>;
  file?: Maybe<FileResponse>;
  noteItem: NoteItemResponse;
};

export type NoteItemResponse = {
  __typename?: 'NoteItemResponse';
  clientId?: Maybe<Scalars['Int']>;
  companyId: Scalars['Int'];
  createdAt: Scalars['Long'];
  createdEmployeeId: Scalars['Int'];
  createdUserId: Scalars['Int'];
  deleted: Scalars['Boolean'];
  employeeId?: Maybe<Scalars['Int']>;
  id: Scalars['Long'];
  note: Scalars['String'];
  updatedAt: Scalars['Long'];
};

export type NoteItemResponsePage = {
  __typename?: 'NoteItemResponsePage';
  content: Array<NoteItemResponse>;
  employees?: Maybe<Array<EmployeeJoinedUserResponse>>;
  files?: Maybe<Array<FileResponse>>;
};

export type NotifyEmployeesOfVisitInput = {
  repeatingDay?: InputMaybe<Scalars['Long']>;
  selectedEmployeeIds?: InputMaybe<Array<Scalars['Int']>>;
  type: VisitNotificationType;
  visitId: Scalars['Long'];
};

export type OpenVisitInput = {
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  visitId: Scalars['Long'];
};

export enum OsType {
  Android = 'Android',
  Ios = 'Ios',
  Linux = 'Linux',
  Mac = 'Mac',
  None = 'None',
  Unknown = 'Unknown',
  Web = 'Web',
  Windows = 'Windows'
}

export type PageableFragmentInput = {
  offset: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export enum PaymentType {
  DoNotTrack = 'DoNotTrack',
  Fixed = 'Fixed',
  Hourly = 'Hourly',
  None = 'None'
}

export type ProductResponse = {
  __typename?: 'ProductResponse';
  companyId: Scalars['Int'];
  createdAt: Scalars['Long'];
  deleted: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['Int']>;
  id: Scalars['Long'];
  isTemplate: Scalars['Boolean'];
  jobId?: Maybe<Scalars['Long']>;
  name: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  repeatingDay?: Maybe<Scalars['Long']>;
  sortOrder: Scalars['Int'];
  templateId?: Maybe<Scalars['Long']>;
  totalPrice?: Maybe<Scalars['Float']>;
  totalPriceWithVat?: Maybe<Scalars['Float']>;
  type: ProductType;
  unitCount?: Maybe<Scalars['Float']>;
  unitName?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['Float']>;
  visitId?: Maybe<Scalars['Long']>;
};

export type ProductResponsePage = {
  __typename?: 'ProductResponsePage';
  content: Array<ProductResponse>;
};

export enum ProductType {
  None = 'None',
  Product = 'Product',
  Service = 'Service'
}

export type Query = {
  __typename?: 'Query';
  adminGetCompanies: AdminCompanyResponsePage;
  adminGetSubscriptionForCompany: SubscriptionResponsePage;
  adminGetSubscriptionTemplates: AdminSubscriptionResponsePage;
  adminGetTimotySettings: TimotySettingsItemResponsePage;
  adminGetUsers: AdminUserResponsePage;
  getClientContactData: ClientContactResponse;
  getClientContactProfileData: ClientContactProfileResponse;
  getClientContactsForIds: ClientContactResponsePage;
  getClientData: ClientResponse;
  getClientProfileData: ClientProfileResponse;
  getClientPureData: ClientPureResponse;
  getClientsJoinedLocations: ClientJoinedLocationsResponsePage;
  getClientsPure: ClientPureResponsePage;
  getCompaniesData: CompanyResponsePage;
  getCompanyData: CompanyResponse;
  getCompanyEmployeeProcessingData: CompanyEmployeeProcessingDataResponse;
  getCompanyProfile: CompanyProfileResponse;
  getCompanySubscriptionStatus: CompanySubscriptionStatusResponse;
  getEmployeeData: EmployeeResponse;
  getEmployeeDataOfCompanies: EmployeeResponsePage;
  getEmployeeDataOrNull?: Maybe<EmployeeResponse>;
  getEmployeeDetail: EmployeeDetailResponse;
  getEmployeeJoinedUser: EmployeeJoinedUserResponse;
  getEmployeeJoinedUserOthers: EmployeeJoinedUserOthersResponse;
  getEmployeesJoinedUsers: EmployeeJoinedUserResponsePage;
  getEmployeesJoinedUsersIncludingInactive: EmployeeJoinedUserResponsePage;
  getFilesByIds: FileResponsePage;
  getIndustries: IndustryResponsePage;
  getJobData: JobResponse;
  getJobDetail: JobDetailResponse;
  getJobForm: JobFormResponse;
  getJobFormsByCompany: JobFormResponsePage;
  getJobFormsByIds: JobFormResponsePage;
  getJobFormsPureByCompany: JobFormPureResponsePage;
  getJobProtocol: JobProtocolResponse;
  getJobs: JobResponsePage;
  getJobsJoinedOthers: JobJoinedOthersResponseResponsePage;
  getLocationData: LocationResponse;
  getLocationPlaceData: LocationPlaceResponse;
  getLocationPlaceProfile: LocationPlaceProfileResponse;
  getLocationPlacesByIds: LocationPlaceResponsePage;
  getLocationProfile: LocationProfileResponse;
  getLocationPureData: LocationPureResponse;
  getLocationsForClient: LocationResponsePage;
  getLocationsForClientCount: Scalars['Long'];
  getLocationsForClientJoinedOthers: LocationJoinedOthersResponsePage;
  getLocationsPureByIds: LocationPureResponsePage;
  getLocationsPureForCompany: LocationPureResponsePage;
  getMaterial: MaterialResponse;
  getMaterialsByCompany: MaterialResponsePage;
  getNoteItem: NoteItemResponse;
  getNoteItemProfile: NoteItemProfileResponse;
  getNoteItems: NoteItemResponsePage;
  getPermissionsForAllRoles: EmployeePermissionsResponsePage;
  getPermissionsForRole: EmployeePermissionsResponse;
  getProduct: ProductResponse;
  getProductsByCompany: ProductResponsePage;
  getUserCompaniesProfile: UserCompaniesProfileResponse;
  getUserData: UserResponse;
  getUserDeleteRequestOrNull?: Maybe<DeleteAccountRequestResponse>;
  getUserJoinedCompanies: UserJoinedCompaniesResponse;
  getUserPreferences: UserPreferencesItemResponsePage;
  getUserProfile: UserProfileResponse;
  getVisitData: VisitResponse;
  getVisitDetail: VisitDetailResponse;
  getVisitJobEmployeeData: JobEmployeeDataResponsePage;
  getVisitJobEmployeeTimesheetItems: JobEmployeeTimesheetItemResponsePage;
  getVisitJobLogHistory: JobLogResponsePage;
  getVisitJobOfferSeats: JobOfferSeatResponsePage;
  getVisitJobProtocols: JobProtocolResponsePage;
  getVisitJobProtocolsJoinedOthers: JobProtocolJoinedOthersResponsePage;
  getVisits: VisitResponsePage;
  getVisitsJoinedOthers: VisitJoinedOthersResponsePage;
  getVisitsJoinedOthersConflicts: VisitJoinedOthersConflictsResponsePage;
  getVisitsJoinedOthersTimesheets: VisitJoinedOthersTimesheetsResponsePage;
  searchSpotlight: SpotlightSearchResponse;
  usersHaveAnyDeviceToken: UsersHaveAnyDeviceTokenResponsePage;
};


export type QueryAdminGetCompaniesArgs = {
  input: AdminGetCompaniesInput;
};


export type QueryAdminGetSubscriptionForCompanyArgs = {
  input: AdminGetSubscriptionsForCompanyInput;
};


export type QueryAdminGetSubscriptionTemplatesArgs = {
  input: AdminGetSubscriptionsInput;
};


export type QueryAdminGetUsersArgs = {
  input: AdminGetUsersInput;
};


export type QueryGetClientContactDataArgs = {
  input: GetClientContactInput;
};


export type QueryGetClientContactProfileDataArgs = {
  input: GetClientContactInput;
};


export type QueryGetClientContactsForIdsArgs = {
  input: GetClientContactsForIdsInput;
};


export type QueryGetClientDataArgs = {
  input: GetClientInput;
};


export type QueryGetClientProfileDataArgs = {
  input: GetClientProfileInput;
};


export type QueryGetClientPureDataArgs = {
  input: GetClientInput;
};


export type QueryGetClientsJoinedLocationsArgs = {
  input: GetClientsInput;
};


export type QueryGetClientsPureArgs = {
  input: GetClientsInput;
};


export type QueryGetCompaniesDataArgs = {
  input: GetCompaniesInput;
};


export type QueryGetCompanyDataArgs = {
  input: GetCompanyInput;
};


export type QueryGetCompanyEmployeeProcessingDataArgs = {
  input: GetCompanyInput;
};


export type QueryGetCompanyProfileArgs = {
  input: GetCompanyProfileInput;
};


export type QueryGetCompanySubscriptionStatusArgs = {
  input: GetCompanyInput;
};


export type QueryGetEmployeeDataArgs = {
  input: GetEmployeeInput;
};


export type QueryGetEmployeeDataOfCompaniesArgs = {
  input: GetEmployeeOfCompaniesInput;
};


export type QueryGetEmployeeDataOrNullArgs = {
  input: GetEmployeeInput;
};


export type QueryGetEmployeeDetailArgs = {
  input: GetEmployeeDetailInput;
};


export type QueryGetEmployeeJoinedUserArgs = {
  input: GetEmployeeJoinedUserInput;
};


export type QueryGetEmployeeJoinedUserOthersArgs = {
  input: GetEmployeeJoinedUserInput;
};


export type QueryGetEmployeesJoinedUsersArgs = {
  input: GetEmployeesJoinedUsersInput;
};


export type QueryGetEmployeesJoinedUsersIncludingInactiveArgs = {
  input: GetEmployeesJoinedUsersIncludingInactiveInput;
};


export type QueryGetFilesByIdsArgs = {
  input: GetFilesByIdsInput;
};


export type QueryGetIndustriesArgs = {
  input?: InputMaybe<GetIndustriesInput>;
};


export type QueryGetJobDataArgs = {
  input: GetJobInput;
};


export type QueryGetJobDetailArgs = {
  input: GetJobInput;
};


export type QueryGetJobFormArgs = {
  input: GetJobFormInput;
};


export type QueryGetJobFormsByCompanyArgs = {
  input: GetJobFormsInput;
};


export type QueryGetJobFormsByIdsArgs = {
  input: GetJobFormsByIdsInput;
};


export type QueryGetJobFormsPureByCompanyArgs = {
  input: GetJobFormsInput;
};


export type QueryGetJobProtocolArgs = {
  input: GetJobProtocolInput;
};


export type QueryGetJobsArgs = {
  input: GetJobsInput;
};


export type QueryGetJobsJoinedOthersArgs = {
  input: GetJobsInput;
};


export type QueryGetLocationDataArgs = {
  input: GetLocationInput;
};


export type QueryGetLocationPlaceDataArgs = {
  input: GetLocationPlaceDataInput;
};


export type QueryGetLocationPlaceProfileArgs = {
  input: GetLocationPlaceProfileInput;
};


export type QueryGetLocationPlacesByIdsArgs = {
  input: GetLocationPlacesByIdsInput;
};


export type QueryGetLocationProfileArgs = {
  input: GetLocationProfileInput;
};


export type QueryGetLocationPureDataArgs = {
  input: GetLocationInput;
};


export type QueryGetLocationsForClientArgs = {
  input: GetLocationsForClientInput;
};


export type QueryGetLocationsForClientCountArgs = {
  input: GetLocationsForClientInput;
};


export type QueryGetLocationsForClientJoinedOthersArgs = {
  input: GetLocationsForClientInput;
};


export type QueryGetLocationsPureByIdsArgs = {
  input: GetLocationsByIdsInput;
};


export type QueryGetLocationsPureForCompanyArgs = {
  input: GetLocationsForCompanyInput;
};


export type QueryGetMaterialArgs = {
  input: GetMaterialInput;
};


export type QueryGetMaterialsByCompanyArgs = {
  input: GetMaterialsInput;
};


export type QueryGetNoteItemArgs = {
  input: GetNoteItemInput;
};


export type QueryGetNoteItemProfileArgs = {
  input: GetNoteItemProfileInput;
};


export type QueryGetNoteItemsArgs = {
  input: GetNoteItemsInput;
};


export type QueryGetPermissionsForAllRolesArgs = {
  input: GetPermissionsForAllRolesInput;
};


export type QueryGetPermissionsForRoleArgs = {
  input: GetPermissionsForRoleInput;
};


export type QueryGetProductArgs = {
  input: GetProductInput;
};


export type QueryGetProductsByCompanyArgs = {
  input: GetProductsInput;
};


export type QueryGetUserCompaniesProfileArgs = {
  input?: InputMaybe<GetUserCompaniesProfileInput>;
};


export type QueryGetUserDataArgs = {
  input?: InputMaybe<GetUserInput>;
};


export type QueryGetUserJoinedCompaniesArgs = {
  input?: InputMaybe<GetUserJoinedCompaniesInput>;
};


export type QueryGetUserPreferencesArgs = {
  input: GetUserPreferencesInput;
};


export type QueryGetUserProfileArgs = {
  input?: InputMaybe<GetUserInput>;
};


export type QueryGetVisitDataArgs = {
  input: GetVisitInput;
};


export type QueryGetVisitDetailArgs = {
  input: GetVisitInput;
};


export type QueryGetVisitJobEmployeeDataArgs = {
  input: GetVisitJobEmployeeDataInput;
};


export type QueryGetVisitJobEmployeeTimesheetItemsArgs = {
  input: GetVisitJobEmployeeTimesheetItemsInput;
};


export type QueryGetVisitJobLogHistoryArgs = {
  input: GetVisitJobLogHistoryInput;
};


export type QueryGetVisitJobOfferSeatsArgs = {
  input: GetVisitJobOfferSeatsInput;
};


export type QueryGetVisitJobProtocolsArgs = {
  input: GetVisitJobProtocolsInput;
};


export type QueryGetVisitJobProtocolsJoinedOthersArgs = {
  input: GetVisitJobProtocolsInput;
};


export type QueryGetVisitsArgs = {
  input: GetVisitsInput;
};


export type QueryGetVisitsJoinedOthersArgs = {
  input: GetVisitsJoinedOthersInput;
};


export type QueryGetVisitsJoinedOthersConflictsArgs = {
  input: GetVisitJoinedOthersConflictsInput;
};


export type QueryGetVisitsJoinedOthersTimesheetsArgs = {
  input: GetVisitJoinedOthersTimesheetsInput;
};


export type QuerySearchSpotlightArgs = {
  input: SpotlightSearchInput;
};


export type QueryUsersHaveAnyDeviceTokenArgs = {
  input: UsersHaveAnyDeviceTokenInput;
};

export type ReActivateCompanyEmployeeInput = {
  employeeId: Scalars['Int'];
};

export type ReactivateVisitInput = {
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  sendNotifications: Scalars['Boolean'];
  visitId: Scalars['Long'];
};

export type RemoveAssignClientContactsFromLocationInput = {
  clientContacts: Array<Scalars['Int']>;
  locationId: Scalars['Int'];
};

export type RemoveClientContactsToLocationPlaceInput = {
  clientContacts: Array<Scalars['Int']>;
  locationPlaceId: Scalars['Long'];
};

export type RemoveSubstituteFromJobOfferSeatOfVisitInput = {
  employeeId: Scalars['Int'];
  jobOfferSeatId: Scalars['Long'];
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  visitId: Scalars['Long'];
};

export type RemoveUserDeviceInput = {
  deviceToken: Scalars['String'];
};

export type RenameVisitJobFormInput = {
  jobFormUUID: Scalars['String'];
  name: Scalars['String'];
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  visitId: Scalars['Long'];
};

export type SavePermissionsForEmployeeInput = {
  employeeId: Scalars['Int'];
  permissionsMapJSON: Scalars['String'];
};

export type SavePermissionsForRoleInput = {
  applyForAllOfRole: Scalars['Boolean'];
  companyId: Scalars['Int'];
  permissionsMapJSON: Scalars['String'];
  role: EmployeeRole;
  updatedPermissions: Array<Scalars['String']>;
};

export type SaveTimotySettingsItemsForKeysInput = {
  items: Array<SaveTimotySettingsItemsForKeysInputItemInput>;
};

export type SaveTimotySettingsItemsForKeysInputItemInput = {
  key: Scalars['String'];
  valueBool?: InputMaybe<Scalars['Boolean']>;
  valueDouble?: InputMaybe<Scalars['Float']>;
  valueInt?: InputMaybe<Scalars['Int']>;
  valueJsonJSON?: InputMaybe<Scalars['String']>;
  valueString?: InputMaybe<Scalars['String']>;
};

export type SaveUserPreferencesItemByKeyInput = {
  companyId: Scalars['Int'];
  key: Scalars['String'];
  valueBool?: InputMaybe<Scalars['Boolean']>;
  valueDouble?: InputMaybe<Scalars['Float']>;
  valueInt?: InputMaybe<Scalars['Int']>;
  valueString?: InputMaybe<Scalars['String']>;
};

export type SaveUserPreferencesItemsForKeysInput = {
  companyId: Scalars['Int'];
  items: Array<SaveUserPreferencesItemsForKeysInputItemInput>;
};

export type SaveUserPreferencesItemsForKeysInputItemInput = {
  key: Scalars['String'];
  valueBool?: InputMaybe<Scalars['Boolean']>;
  valueDouble?: InputMaybe<Scalars['Float']>;
  valueInt?: InputMaybe<Scalars['Int']>;
  valueString?: InputMaybe<Scalars['String']>;
};

export type ScheduleNonRepeatingVisitForJobInput = {
  endDate?: InputMaybe<Scalars['Long']>;
  endTime: Scalars['Long'];
  jobId: Scalars['Long'];
  sendNotifications: Scalars['Boolean'];
  startDate: Scalars['Long'];
  startTime: Scalars['Long'];
  visitId: Scalars['Long'];
};

export type ScheduleVisitForJobInput = {
  jobId: Scalars['Long'];
  sendNotifications: Scalars['Boolean'];
  visit: CreateVisitInput;
  visitId: Scalars['Long'];
};

export type ScheduleVisitForJobResponse = {
  __typename?: 'ScheduleVisitForJobResponse';
  job: JobResponse;
  visit: VisitResponse;
};

export type SendNotificationToUsersInput = {
  body: Scalars['String'];
  dataKeys?: InputMaybe<Array<Scalars['String']>>;
  dataValues?: InputMaybe<Array<Scalars['String']>>;
  title: Scalars['String'];
  userIds: Array<Scalars['Int']>;
};

export type SetJobEmployeeStatusAsIgnoredOfVisitInput = {
  employeeId: Scalars['Int'];
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  visitId: Scalars['Long'];
};

export type SignInInput = {
  email: Scalars['String'];
  fcmDeviceToken?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  loginProviders: Array<LoginProvider>;
  photoUrl?: InputMaybe<Scalars['String']>;
};

export type SpotlightSearchInput = {
  companyId: Scalars['Int'];
  query: Scalars['String'];
};

export type SpotlightSearchResponse = {
  __typename?: 'SpotlightSearchResponse';
  clients: Array<ClientJoinedLocationsResponse>;
  clientsForVisits: Array<ClientPureResponse>;
  employees: Array<EmployeeJoinedUserResponse>;
  jobs: Array<JobResponse>;
  query: Scalars['String'];
  visitRepeatDays: Array<VisitRepeatDayResponse>;
  visits: Array<VisitResponse>;
};

export type SubscriptionResponse = {
  __typename?: 'SubscriptionResponse';
  canBeDeleted: Scalars['Boolean'];
  companyId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Long'];
  deleted: Scalars['Boolean'];
  id: Scalars['Long'];
  isTemplate: Scalars['Boolean'];
  maxUsers: Scalars['Int'];
  name: Scalars['String'];
  templateId?: Maybe<Scalars['Long']>;
  translationKey: Scalars['String'];
  validForDays?: Maybe<Scalars['Int']>;
  validForMonths?: Maybe<Scalars['Int']>;
  validFrom?: Maybe<Scalars['Long']>;
  validTo?: Maybe<Scalars['Long']>;
};

export type SubscriptionResponsePage = {
  __typename?: 'SubscriptionResponsePage';
  content: Array<SubscriptionResponse>;
};

export type TimotySettingsItemResponse = {
  __typename?: 'TimotySettingsItemResponse';
  id: Scalars['Long'];
  key: Scalars['String'];
  updatedAt: Scalars['Long'];
  valueBool?: Maybe<Scalars['Boolean']>;
  valueDouble?: Maybe<Scalars['Float']>;
  valueInt?: Maybe<Scalars['Int']>;
  valueJsonJSON?: Maybe<Scalars['String']>;
  valueString?: Maybe<Scalars['String']>;
};

export type TimotySettingsItemResponsePage = {
  __typename?: 'TimotySettingsItemResponsePage';
  content: Array<TimotySettingsItemResponse>;
};

export type UpdateClientContactInput = {
  clientContactId: Scalars['Int'];
  name: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  surname: Scalars['String'];
};

export type UpdateClientInput = {
  clientId: Scalars['Int'];
  email?: InputMaybe<Scalars['String']>;
  isCompany: Scalars['Boolean'];
  name: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateCompanyEmployeeInput = {
  address?: InputMaybe<AddressFragmentInput>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contractType?: InputMaybe<ContractType>;
  distanceRate?: InputMaybe<Scalars['Float']>;
  hourRate?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  permissionsUpdateForRole?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  role: EmployeeRole;
  surname: Scalars['String'];
};

export type UpdateCompanyGeneralSettingsInput = {
  companyId: Scalars['Int'];
  gpsLocationTracking: Scalars['Boolean'];
};

export type UpdateCompanyInput = {
  address?: InputMaybe<AddressFragmentInput>;
  companyId: Scalars['Int'];
  companySize?: InputMaybe<Scalars['String']>;
  currency: Scalars['String'];
  hasVat: Scalars['Boolean'];
  industryIds: Array<Scalars['Int']>;
  name: Scalars['String'];
};

export type UpdateCompanyLogoInput = {
  companyId: Scalars['Int'];
  logoFileId?: InputMaybe<Scalars['Long']>;
};

export type UpdateFileLabelInput = {
  fileId: Scalars['Long'];
  label?: InputMaybe<Scalars['String']>;
};

export type UpdateJobClientInput = {
  clientId: Scalars['Int'];
  jobId: Scalars['Long'];
};

export type UpdateJobDetailsInput = {
  jobId: Scalars['Long'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateJobEmployeeStatusOfVisitByEmployeeInput = {
  createGpsLocationForJobInput?: InputMaybe<CreateGpsLocationForJobInput>;
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  status: JobEmployeeStatus;
  visitId: Scalars['Long'];
};

export type UpdateJobEmployeeStatusOfVisitInput = {
  employeeId: Scalars['Int'];
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  status: JobEmployeeStatus;
  visitId: Scalars['Long'];
};

export type UpdateJobEmployeeTimesheetItemOfVisitInput = {
  approved: Scalars['Boolean'];
  distance?: InputMaybe<Scalars['Float']>;
  distanceRate?: InputMaybe<Scalars['Float']>;
  employeeId: Scalars['Int'];
  endTime?: InputMaybe<Scalars['Long']>;
  fixedPrice?: InputMaybe<Scalars['Float']>;
  hourRate?: InputMaybe<Scalars['Float']>;
  hours?: InputMaybe<Scalars['Float']>;
  minutes?: InputMaybe<Scalars['Float']>;
  note?: InputMaybe<Scalars['String']>;
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  startTime?: InputMaybe<Scalars['Long']>;
  timesheetId: Scalars['Long'];
  timesheetUuid: Scalars['String'];
  visitId: Scalars['Long'];
};

export type UpdateJobEmployeeTimesheetItemsApprovalOfVisitInput = {
  items: Array<UpdateJobEmployeeTimesheetItemsApprovalOfVisitInputItemInput>;
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  visitId: Scalars['Long'];
};

export type UpdateJobEmployeeTimesheetItemsApprovalOfVisitInputItemInput = {
  approved: Scalars['Boolean'];
  timesheetId: Scalars['Long'];
  timesheetUuid: Scalars['String'];
};

export type UpdateJobFormElementDoneStateElementInput = {
  done: Scalars['Boolean'];
  doneValue?: InputMaybe<Scalars['String']>;
  jobFormElementUUID: Scalars['String'];
};

export type UpdateJobFormPositionInput = {
  jobFormIds: Array<Scalars['Long']>;
  positions: Array<Scalars['Int']>;
};

export type UpdateJobFormTemplateInput = {
  elements: Array<JobFormElementFragmentInput>;
  jobFormId: Scalars['Long'];
  name: Scalars['String'];
  sections: Array<Scalars['String']>;
};

export type UpdateJobOfferSeatOfVisitInput = {
  companyId: Scalars['Int'];
  employeeIds: Array<Scalars['Int']>;
  fixedPrice?: InputMaybe<Scalars['Float']>;
  hourRate?: InputMaybe<Scalars['Float']>;
  hours?: InputMaybe<Scalars['Float']>;
  jobOfferSeatId: Scalars['Long'];
  minutes?: InputMaybe<Scalars['Float']>;
  paymentType: PaymentType;
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  sendNotifications: Scalars['Boolean'];
  substituteCount: Scalars['Int'];
  totalPrice?: InputMaybe<Scalars['Float']>;
  visitId: Scalars['Long'];
};

export type UpdateJobResponsiblePersonInput = {
  jobId: Scalars['Long'];
  responsiblePersonId: Scalars['Int'];
};

export type UpdateLocationInput = {
  address: AddressFragmentInput;
  icon: Scalars['String'];
  locationId: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  specification?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type UpdateLocationPlaceInput = {
  icon: Scalars['String'];
  locationPlaceId: Scalars['Long'];
  name: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type UpdateMaterialTemplateInput = {
  id: Scalars['Long'];
  name: Scalars['String'];
  unitName?: InputMaybe<Scalars['String']>;
};

export type UpdateNoteItemInput = {
  note: Scalars['String'];
  noteId: Scalars['Long'];
};

export type UpdateProductTemplateInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Long'];
  name: Scalars['String'];
  price: Scalars['Float'];
  unitCount?: InputMaybe<Scalars['Float']>;
  unitName: Scalars['String'];
  vatRate?: InputMaybe<Scalars['Float']>;
};

export type UpdateUserPhotoInput = {
  customPhotoId?: InputMaybe<Scalars['Long']>;
};

export enum UpdateVisitAssignedEmployeesAction {
  DeleteSingle = 'DeleteSingle',
  None = 'None',
  UpdateAssigned = 'UpdateAssigned'
}

export type UpdateVisitAssignedEmployeesInput = {
  action: UpdateVisitAssignedEmployeesAction;
  employeeIds: Array<Scalars['Int']>;
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  visitId: Scalars['Long'];
};

export type UpdateVisitAttachmentsInput = {
  attachmentFileIds: Array<Scalars['Long']>;
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  visitId: Scalars['Long'];
};

export type UpdateVisitDateTimeInput = {
  endDate?: InputMaybe<Scalars['Long']>;
  endTime: Scalars['Long'];
  every?: InputMaybe<Scalars['Int']>;
  monthDays: Array<Scalars['Int']>;
  monthWeekDays: Array<Scalars['String']>;
  repeatEndDate?: InputMaybe<Scalars['Long']>;
  repeatModifier?: InputMaybe<VisitRepeatModifier>;
  repeatWeekDays: Array<Scalars['Int']>;
  repeating: VisitRepeating;
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  sendNotifications: Scalars['Boolean'];
  skipDays: Array<Scalars['Long']>;
  startDate: Scalars['Long'];
  startTime: Scalars['Long'];
  visitId: Scalars['Long'];
  wholeDay: Scalars['Boolean'];
};

export type UpdateVisitDateTimeOnlyInput = {
  endDate?: InputMaybe<Scalars['Long']>;
  endTime: Scalars['Long'];
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  sendNotifications: Scalars['Boolean'];
  startDate: Scalars['Long'];
  startTime: Scalars['Long'];
  visitId: Scalars['Long'];
};

export type UpdateVisitDetailsInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  sendNotifications: Scalars['Boolean'];
  updateOnlyDescription?: InputMaybe<Scalars['Boolean']>;
  updateOnlyName?: InputMaybe<Scalars['Boolean']>;
  visitId: Scalars['Long'];
};

export type UpdateVisitInvoiceDeliveryNumbersInput = {
  deliveryNumber?: InputMaybe<Scalars['String']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  visitId: Scalars['Long'];
};

export type UpdateVisitJobFormElementDoneStateInput = {
  elements: Array<UpdateJobFormElementDoneStateElementInput>;
  jobFormUUID: Scalars['String'];
  repeatingDay?: InputMaybe<Scalars['Long']>;
  visitId: Scalars['Long'];
};

export type UpdateVisitLocationInput = {
  locationId: Scalars['Int'];
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  sendNotifications: Scalars['Boolean'];
  visitId: Scalars['Long'];
};

export type UpdateVisitLocationPlaceInput = {
  locationPlaceId: Scalars['Long'];
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  visitId: Scalars['Long'];
};

export type UpdateVisitMaterialInput = {
  employeeId: Scalars['Int'];
  materialId: Scalars['Long'];
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  unitCount: Scalars['Float'];
  unitName?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
  visitId: Scalars['Long'];
};

export type UpdateVisitProductInput = {
  description?: InputMaybe<Scalars['String']>;
  employeeId: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Float'];
  productId: Scalars['Long'];
  productUuid: Scalars['String'];
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  unitCount?: InputMaybe<Scalars['Float']>;
  unitName: Scalars['String'];
  vatRate?: InputMaybe<Scalars['Float']>;
  visitId: Scalars['Long'];
};

export type UpdateVisitStatusModifierInput = {
  repeatingDay?: InputMaybe<Scalars['Long']>;
  repeats: JobUpdateRepeats;
  statusModifier: VisitStatusModifier;
  visitId: Scalars['Long'];
};

export type UserChangeEmailInput = {
  email: Scalars['String'];
};

export type UserCompaniesProfileResponse = {
  __typename?: 'UserCompaniesProfileResponse';
  companies: Array<CompanyResponse>;
  companySubscriptions?: Maybe<Array<SubscriptionResponse>>;
  employeeDataOfCompanies: Array<EmployeeResponse>;
  files: Array<FileResponse>;
};

export type UserDeleteResponse = {
  __typename?: 'UserDeleteResponse';
  createdRequest: Scalars['Boolean'];
};

export type UserJoinedCompaniesResponse = {
  __typename?: 'UserJoinedCompaniesResponse';
  companies: Array<CompanyResponse>;
  companySubscriptions?: Maybe<Array<SubscriptionResponse>>;
  employees: Array<EmployeeResponse>;
  files: Array<FileResponse>;
  user: UserResponse;
};

export type UserPersonalDetailsInput = {
  address?: InputMaybe<AddressFragmentInput>;
  contactEmail?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  newsletter?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  surname: Scalars['String'];
};

export type UserPreferencesItemResponse = {
  __typename?: 'UserPreferencesItemResponse';
  companyId: Scalars['Int'];
  id: Scalars['Long'];
  key: Scalars['String'];
  updatedAt: Scalars['Long'];
  userId: Scalars['Int'];
  valueBool?: Maybe<Scalars['Boolean']>;
  valueDouble?: Maybe<Scalars['Float']>;
  valueInt?: Maybe<Scalars['Int']>;
  valueString?: Maybe<Scalars['String']>;
};

export type UserPreferencesItemResponsePage = {
  __typename?: 'UserPreferencesItemResponsePage';
  content: Array<UserPreferencesItemResponse>;
};

export type UserProfileResponse = {
  __typename?: 'UserProfileResponse';
  customPhotoFile?: Maybe<FileResponse>;
  user: UserResponse;
  userDeleteRequest?: Maybe<DeleteAccountRequestResponse>;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  address?: Maybe<AddressFragment>;
  companyIds: Array<Scalars['Int']>;
  contactEmail?: Maybe<Scalars['String']>;
  createdAt: Scalars['Long'];
  customPhotoId?: Maybe<Scalars['Long']>;
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['Long']>;
  email: Scalars['String'];
  firebaseId?: Maybe<Scalars['String']>;
  historicCompanyIds: Array<Scalars['Int']>;
  id: Scalars['Int'];
  language?: Maybe<Scalars['String']>;
  loginProviders: Array<LoginProvider>;
  name?: Maybe<Scalars['String']>;
  newsletter: Scalars['Boolean'];
  phoneNumber?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  superAdmin: Scalars['Boolean'];
  surname?: Maybe<Scalars['String']>;
};

export type UserSignInResponse = {
  __typename?: 'UserSignInResponse';
  companyIds: Array<Scalars['Int']>;
  userId: Scalars['Int'];
};

export type UserUpdateLanguageInput = {
  language: Scalars['String'];
};

export type UsersHaveAnyDeviceTokenInput = {
  userIds: Array<Scalars['Int']>;
};

export type UsersHaveAnyDeviceTokenResponse = {
  __typename?: 'UsersHaveAnyDeviceTokenResponse';
  hasDeviceToken: Scalars['Boolean'];
  userId: Scalars['Int'];
};

export type UsersHaveAnyDeviceTokenResponsePage = {
  __typename?: 'UsersHaveAnyDeviceTokenResponsePage';
  content: Array<UsersHaveAnyDeviceTokenResponse>;
};

export type VisitDetailResponse = {
  __typename?: 'VisitDetailResponse';
  client: ClientPureResponse;
  createdByEmployee: EmployeeResponse;
  createdByUser: UserResponse;
  employeeJobData: Array<JobEmployeeDataResponse>;
  employeeTimesheet: Array<JobEmployeeTimesheetItemResponse>;
  employees: Array<EmployeeJoinedUserResponse>;
  files: Array<FileResponse>;
  gpsLocations: Array<GpsLocationResponse>;
  history?: Maybe<Array<JobLogResponse>>;
  job: JobResponse;
  jobFormTemplates?: Maybe<Array<JobFormPureResponse>>;
  jobForms: Array<JobFormResponse>;
  jobOfferSeat: Array<JobOfferSeatResponse>;
  location?: Maybe<LocationPureResponse>;
  materials: Array<MaterialResponse>;
  place?: Maybe<LocationPlaceResponse>;
  products: Array<ProductResponse>;
  responsiblePerson: EmployeeJoinedUserResponse;
  visit: VisitResponse;
};

export type VisitJoinedOthersConflictsResponsePage = {
  __typename?: 'VisitJoinedOthersConflictsResponsePage';
  jobOfferSeats: Array<JobOfferSeatResponse>;
  nonRepeating: Array<VisitResponse>;
  repeating: Array<VisitResponse>;
  repeatingDayData: Array<VisitRepeatDayResponse>;
};

export type VisitJoinedOthersResponsePage = {
  __typename?: 'VisitJoinedOthersResponsePage';
  clients: Array<ClientPureResponse>;
  employees: Array<EmployeeJoinedUserResponse>;
  files: Array<FileResponse>;
  jobEmployeeData?: Maybe<Array<JobEmployeeDataResponse>>;
  jobForms: Array<JobFormPureResponse>;
  jobOfferSeats: Array<JobOfferSeatResponse>;
  jobs: Array<JobPureResponse>;
  locations: Array<LocationPureResponse>;
  materials: Array<MaterialResponse>;
  nonRepeating: Array<VisitResponse>;
  notScheduledCount: Scalars['Long'];
  places: Array<LocationPlaceResponse>;
  products: Array<ProductResponse>;
  repeating: Array<VisitResponse>;
  repeatingDayData: Array<VisitRepeatDayResponse>;
  responsiblePersons: Array<EmployeeJoinedUserResponse>;
  scheduleLater?: Maybe<Array<VisitResponse>>;
  timesheets: Array<JobEmployeeTimesheetItemResponse>;
};

export type VisitJoinedOthersTimesheetsResponsePage = {
  __typename?: 'VisitJoinedOthersTimesheetsResponsePage';
  clients: Array<ClientPureResponse>;
  employees: Array<EmployeeJoinedUserResponse>;
  files: Array<FileResponse>;
  jobEmployeeData: Array<JobEmployeeDataResponse>;
  jobs: Array<JobPureResponse>;
  locations: Array<LocationPureResponse>;
  materials?: Maybe<Array<MaterialResponse>>;
  nonRepeating: Array<VisitResponse>;
  places: Array<LocationPlaceResponse>;
  products?: Maybe<Array<ProductResponse>>;
  repeating: Array<VisitResponse>;
  repeatingDayData: Array<VisitRepeatDayResponse>;
  timesheets: Array<JobEmployeeTimesheetItemResponse>;
};

export enum VisitNotificationType {
  NewVisit = 'NewVisit',
  None = 'None',
  Reminder = 'Reminder'
}

export type VisitRepeatDayResponse = {
  __typename?: 'VisitRepeatDayResponse';
  attachmentFileIds?: Maybe<Array<Scalars['Long']>>;
  clientContactIds: Array<Scalars['Int']>;
  companyId: Scalars['Int'];
  createdAt: Scalars['Long'];
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['Long']>;
  deliveryNumber?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  employeeIds: Array<Scalars['Int']>;
  employeeTotalPrice?: Maybe<Scalars['Float']>;
  endDate: Scalars['Long'];
  endTime: Scalars['Long'];
  ended: Scalars['Boolean'];
  endedAt?: Maybe<Scalars['Long']>;
  id: Scalars['Long'];
  invoiceNumber?: Maybe<Scalars['String']>;
  jobId: Scalars['Long'];
  locationId?: Maybe<Scalars['Int']>;
  locationPlaceId?: Maybe<Scalars['Long']>;
  name?: Maybe<Scalars['String']>;
  repeatInterval: Scalars['Int'];
  repeatWeekDays: Array<Scalars['Int']>;
  repeating: VisitRepeating;
  repeatingDay: Scalars['Long'];
  startDate: Scalars['Long'];
  startTime: Scalars['Long'];
  status: VisitStatus;
  statusModifier?: Maybe<VisitStatusModifier>;
  visitDefectsCount: Scalars['Long'];
  visitId: Scalars['Long'];
  visitProtocolsCount: Scalars['Long'];
  wholeDay: Scalars['Boolean'];
};

export enum VisitRepeatModifier {
  CustomDates = 'CustomDates',
  DayOfMonth = 'DayOfMonth',
  DayOfMonthOrLastDay = 'DayOfMonthOrLastDay',
  DayOfWeek = 'DayOfWeek',
  DayOfWeekOrLastDay = 'DayOfWeekOrLastDay',
  LastDayOfMonth = 'LastDayOfMonth',
  LastDayOfWeek = 'LastDayOfWeek',
  None = 'None',
  SkipWeekends = 'SkipWeekends'
}

export enum VisitRepeating {
  Daily = 'Daily',
  Monthly = 'Monthly',
  Never = 'Never',
  None = 'None',
  Weekly = 'Weekly',
  Yearly = 'Yearly'
}

export type VisitResponse = {
  __typename?: 'VisitResponse';
  attachmentFileIds?: Maybe<Array<Scalars['Long']>>;
  clientContactIds: Array<Scalars['Int']>;
  clientId: Scalars['Int'];
  companyId: Scalars['Int'];
  createVisitParams?: Maybe<Scalars['String']>;
  createdAt: Scalars['Long'];
  createdEmployeeId: Scalars['Int'];
  createdUserId: Scalars['Int'];
  customDates: Array<Scalars['Long']>;
  deleted: Scalars['Boolean'];
  deletedAt?: Maybe<Scalars['Long']>;
  deliveryNumber?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  employeeIds: Array<Scalars['Int']>;
  employeeTotalPrice?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['Long']>;
  endTime: Scalars['Long'];
  ended: Scalars['Boolean'];
  endedAt?: Maybe<Scalars['Long']>;
  every?: Maybe<Scalars['Int']>;
  id: Scalars['Long'];
  invoiceNumber?: Maybe<Scalars['String']>;
  jobId: Scalars['Long'];
  locationId?: Maybe<Scalars['Int']>;
  locationPlaceId?: Maybe<Scalars['Long']>;
  monthDays: Array<Scalars['Int']>;
  monthWeekDays: Array<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentVisitId?: Maybe<Scalars['Long']>;
  repeatEndDate?: Maybe<Scalars['Long']>;
  repeatInterval: Scalars['Int'];
  repeatModifier?: Maybe<VisitRepeatModifier>;
  repeatWeekDays: Array<Scalars['Int']>;
  repeating: VisitRepeating;
  repeatingDayData?: Maybe<VisitRepeatDayResponse>;
  requiredEmployeeIds: Array<Scalars['Int']>;
  sequenceId: Scalars['Long'];
  siblingIds: Array<Scalars['Long']>;
  skipDays: Array<Scalars['Long']>;
  startDate: Scalars['Long'];
  startTime: Scalars['Long'];
  status: VisitStatus;
  statusModifier?: Maybe<VisitStatusModifier>;
  templateId?: Maybe<Scalars['Int']>;
  uuid: Scalars['String'];
  visitDefectsCount: Scalars['Long'];
  visitProtocolsCount: Scalars['Long'];
  wholeDay: Scalars['Boolean'];
};

export type VisitResponsePage = {
  __typename?: 'VisitResponsePage';
  jobOfferSeats?: Maybe<Array<JobOfferSeatResponse>>;
  nonRepeating: Array<VisitResponse>;
  repeating: Array<VisitResponse>;
  repeatingDayData: Array<VisitRepeatDayResponse>;
  scheduleLater?: Maybe<Array<VisitResponse>>;
};

export enum VisitStatus {
  Canceled = 'Canceled',
  Closed = 'Closed',
  Done = 'Done',
  InProgress = 'InProgress',
  JobOffer = 'JobOffer',
  None = 'None',
  ScheduleLater = 'ScheduleLater',
  Scheduled = 'Scheduled',
  Travelling = 'Travelling',
  Unfinished = 'Unfinished'
}

export enum VisitStatusModifier {
  Invoiced = 'Invoiced',
  None = 'None',
  NotPaid = 'NotPaid',
  Paid = 'Paid'
}

export type AdminUserSignInMutationVariables = Exact<{
  input: AdminSignInInput;
}>;


export type AdminUserSignInMutation = { __typename?: 'Mutation', adminUserSignIn: { __typename: 'AdminUserSignInResponse', userId: number } };


export const AdminUserSignInDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AdminUserSignIn"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AdminSignInInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"adminUserSignIn"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"userId"}}]}}]}}]} as unknown as DocumentNode<AdminUserSignInMutation, AdminUserSignInMutationVariables>;