import IAppVersion, {AppType} from "../../../../model/firestore/AppVersion";
import AppModal from "../AppModal";
import {tt} from "../../../../core/Localization";
import {useForm} from "@mantine/form";
import {DateTime} from "luxon";
import React, {useEffect, useState} from "react";
import {Select, TextInput, useComponentDefaultProps} from "@mantine/core";
import AppButton from "../../buttons/AppButton";
import {appTypeToOptions} from "../../../../service/AppVersionService";
import {DateTimePicker} from "@mantine/dates";

export interface IAppVersionEditModalProps {
    opened: boolean;
    close: () => void;
    existing?: IAppVersion | NullOrUndefined;
    onSubmit: (appVersion: IAppVersion) => void;
    loading?: boolean;
}

const defaultProps: Partial<IAppVersionEditModalProps> = {
    loading: false,
};

export interface IAppVersionFormValues {
    app: string;
    version: string;
    createdAt: Date;
}

/**
 * Modal component for editing app version or create new.
 */
export default function AppVersionEditModal(props: IAppVersionEditModalProps) {
    const {opened, close, existing, onSubmit, loading} = useComponentDefaultProps('AppVersionEditModal', defaultProps, props);

    const title = existing ? tt('appVersionEditModal.title.edit') : tt('appVersionEditModal.title.create');

    const form = useForm<IAppVersionFormValues>({
        initialValues: {
            app: existing ? existing.app.toString() : AppType.BackOffice.toString(),
            version: existing ? existing.version : '',
            createdAt: existing ? new Date(existing.createdAt) : DateTime.now().toJSDate(),
        },
        validate: {
            app: (value) => value && parseInt(value) !== AppType.None ? null : tt('error.isRequired'),
            version: (value) => value ? null : tt('error.isRequired'),
            createdAt: (value) => value ? null : tt('error.isRequired'),
        },
    });

    useEffect(() => {
        if (opened) {
            form.setValues({
                app: existing ? existing.app.toString() : AppType.BackOffice.toString(),
                version: existing ? existing.version : '',
                createdAt: existing ? new Date(existing.createdAt) : DateTime.now().toJSDate(),
            });
        }
    }, [opened]);

    /**
     * OnSubmit process values and send to callback.
     */
    const onSubmitProcess = (values: IAppVersionFormValues) => {
        onSubmit({
            ...existing,
            app: parseInt(values.app),
            version: values.version,
            createdAt: DateTime.fromJSDate(values.createdAt).toMillis(),
        });
    };

    return (
        <AppModal
            opened={opened}
            close={close}
            title={title}
        >
            <form
                onSubmit={form.onSubmit((values) => onSubmitProcess(values))}
            >
                <Select
                    data={appTypeToOptions()}
                    label={tt('appVersionEditModal.app.label')}
                    mb="sm"
                    {...form.getInputProps('app')}
                />

                <TextInput
                    label={tt('appVersionEditModal.version.label')}
                    placeholder={tt('appVersionEditModal.version.placeholder')}
                    mb="sm"
                    {...form.getInputProps('version')}
                />

                <DateTimePicker
                    dropdownType="modal"
                    label={tt('appVersionEditModal.createdAt.label')}
                    placeholder={tt('appVersionEditModal.createdAt.placeholder')}
                    mb="sm"
                    {...form.getInputProps('createdAt')}
                />

                <AppButton
                    loading={loading}
                    type="submit"
                    fullWidth={true}
                    mb="sm"
                >
                    {tt('common.submit')}
                </AppButton>
            </form>
        </AppModal>
    );
}
