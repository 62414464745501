import { ActionIcon, ActionIconProps, Tooltip, TooltipProps } from "@mantine/core";
import { MouseEventHandler } from "react";

export interface IAppIconButtonProps extends ActionIconProps {
    tooltip?: string;
    onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

/**
 * Main App icon button component.
 */
export default function AppIconButton(props: IAppIconButtonProps) {
    const { tooltip } = props;

    let contentJSX = <ActionIcon {...props} />;

    if (tooltip) {
        contentJSX = (
            <Tooltip
                label={tooltip}
            >
                {contentJSX}
            </Tooltip>
        );
    }

    return (
        <>{contentJSX}</>
    );
}
