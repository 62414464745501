import {Box, createStyles, MantineProvider, Navbar, useComponentDefaultProps} from "@mantine/core";
import React, {ReactNode} from "react";
import {Building, GitCommit, InfoSquare, LayoutCollage, Logout, PigMoney, Settings, User} from "tabler-icons-react";
import {tt} from "../../../core/Localization";
import AppButton from "../buttons/AppButton";
import {useNavigate} from "react-router-dom";
import {kDashboardRoute} from "../../screens/dashboard/DashboardScreen";
import {kInfoListRoute} from "../../screens/infos/InfoListScreen";
import {kAppVersionsRoute} from "../../screens/appVersions/AppVersionListScreen";
import {MantineColor} from "@mantine/styles";
import {kSignOutRoute} from "../../screens/authorization/SignOutScreen";
import {kThemePrimaryShade} from "../../../styles/AppThemeProcessor";
import {kUserListRoute} from "../../screens/users/UserListScreen";
import {kCompanyListRoute} from "../../screens/companies/CompanyListScreen";
import {kSubscriptionListRoute} from "../../screens/companies/SubscriptionListScreen";
import {kTimotySettingsRoute} from "../../screens/timotySettings/TimotySettingsScreen";

export const kAppNavBarWidth = 240;

const useStyles = createStyles((theme) => ({
    navBar: {
        width: kAppNavBarWidth,
        padding: theme.spacing.md,
        borderRight: 0,
    },
    itemsContainer: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        borderRadius: 20,
    },
    navBarItem: {
        borderRadius: 0,
        borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2]}`,
        ':last-child': {
            borderBottom: 0,
        },
        '.mantine-Button-inner': {
            justifyContent: 'flex-start',
        },
    },
}));

export interface IAppNavBarProps {
    hidden?: boolean;
}

/**
 * Component for default app navigation bar.
 */
export default function AppNavBar(props: IAppNavBarProps) {
    const { hidden } = props;

    const { classes } = useStyles();

    return (
        <Navbar className={classes.navBar} hiddenBreakpoint="sm" hidden={hidden}>
            <Box
                className={classes.itemsContainer}
                mb={"md"}
            >
                <AppNavBarItem route={kTimotySettingsRoute} icon={<Settings />} label={tt('common.navigate.timotySettings')} />
            </Box>

            <Box
                className={classes.itemsContainer}
                mb={"md"}
            >
                <AppNavBarItem route={kDashboardRoute} icon={<LayoutCollage />} label={tt('common.navigate.dashboard')} />

                <AppNavBarItem route={kAppVersionsRoute} icon={<GitCommit />}
                    label={tt('common.navigate.appVersionList')} />

                <AppNavBarItem route={kCompanyListRoute} icon={<Building />} label={tt('common.navigate.companyList')} />

                <AppNavBarItem route={kSubscriptionListRoute} icon={<PigMoney />} label={tt('common.navigate.subscriptionList')} />

                <AppNavBarItem route={kInfoListRoute} icon={<InfoSquare />} label={tt('common.navigate.infoList')} />

                <AppNavBarItem route={kUserListRoute} icon={<User />} label={tt('common.navigate.userList')} />
            </Box>

            <Box
                className={classes.itemsContainer}
            >
                <AppNavBarItem
                    route={kSignOutRoute}
                    icon={<Logout />}
                    label={tt('common.navigate.signOut')}
                    color={'red'}
                    shade={7}
                />
            </Box>
        </Navbar>
    );
}

interface IAppNavBarItemProps {
    route: string;
    icon: ReactNode;
    label: string;
    color?: MantineColor;
    shade?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
}

const defaultAppNavBarItemProps: Partial<IAppNavBarItemProps> = {
    color: 'dark',
    shade: kThemePrimaryShade,
};

/**
 * Component for default app navigation bar item.
 */
export function AppNavBarItem(props: IAppNavBarItemProps) {
    const {
        route,
        icon,
        label,
        color,
        shade
    } = useComponentDefaultProps('AppNavBarItem', defaultAppNavBarItemProps, props);

    const navigate = useNavigate();

    const { classes, theme } = useStyles();

    const theTheme = { ...theme };

    theTheme.primaryShade = shade as any;

    return (
        <MantineProvider theme={theTheme}>
            <AppButton
                className={classes.navBarItem}
                color={color}
                onClick={() => navigate(route)}
                leftIcon={icon}
            >
                {label}
            </AppButton>
        </MantineProvider>
    );
}
