import {createStyles, Title} from "@mantine/core";
import {MantineStyleSystemProps} from "@mantine/styles/lib/theme/types/MantineStyleSystem";

const useStyles = createStyles((theme) => ({
    title: {
        borderBottom: `1px dashed ${theme.colors.gray[6]}`,
    },
}));

export interface IFormCategoryTitleProps {
    className?: string;
    title: string;
    styleProps?: MantineStyleSystemProps;
}

/**
 * Component for title of form category.
 */
export default function FormCategoryTitle(props: IFormCategoryTitleProps) {
    const {className, title, styleProps} = props;

    const {classes, cx} = useStyles();

    return (
        <Title
            className={cx(classes.title, className)}
            order={4}
            {...styleProps}
        >
            {title}
        </Title>
    );
}
