import AppModal from "./AppModal";
import {tt} from "../../../core/Localization";
import {HideConfirmActionModal} from "./AppModals";
import {AppDataContext} from "../../../AppData";
import {useContext} from "react";
import {Text} from "@mantine/core";
import AppButton from "../buttons/AppButton";
import AppModalActions from "./AppModalActions";

export interface IConfirmActionModalProps {
    opened: boolean;
    title?: string;
    text?: string;
    noText?: string;
    yesText?: string;
    onConfirm: VoidOrPromiseCallback;
}

/**
 * Modal component for confirm action.
 */
export default function ConfirmActionModal(props: IConfirmActionModalProps) {
    const {opened, title, text, noText, yesText, onConfirm} = props;

    const appDataContext = useContext(AppDataContext);

    const theTile = title ? title : tt('confirmActionModal.title');
    const theText = text ? text : tt('confirmActionModal.text');
    const theNoText = noText ? noText : tt('confirmActionModal.no');
    const theYesText = yesText ? yesText : tt('confirmActionModal.yes');

    return (
        <AppModal
            opened={opened}
            close={() => HideConfirmActionModal(appDataContext)}
            title={theTile}
        >
            <Text mb={'md'}>{theText}</Text>

            <AppModalActions>
                <AppButton
                    variant={'outline'}
                    onClick={() => HideConfirmActionModal(appDataContext)}
                >
                    {theNoText}
                </AppButton>

                <AppButton
                    color="red.8"
                    onClick={() => onConfirm()}
                >
                    {theYesText}
                </AppButton>
            </AppModalActions>
        </AppModal>
    );
}
