import { useContext, useEffect, useState } from "react";
import { AppDataContext } from "../../../AppData";
import ScreenContent from "../../components/screens/ScreenContent";
import { tt } from "../../../core/Localization";
import AppButton from "../../components/buttons/AppButton";
import { Plus } from "tabler-icons-react";
import AppPaper from "../../components/screens/AppPaper";
import { useDisclosure } from "@mantine/hooks";
import IInfo from "../../../model/firestore/Info";
import { kDefaultWaitForMillis, WaitFor } from "../../../utils/DateUtils";
import { deleteInfo, saveInfo } from "../../../service/InfoService";
import { ErrorToast, SuccessToast } from "../../../service/ToastService";
import { HideConfirmActionModal, SetConfirmActionModal } from "../../components/modals/AppModals";
import { createStyles, Group } from "@mantine/core";
import InfosTable from "../../components/infos/InfosTable";
import InfoEditModal from "../../components/modals/infos/InfoEditModal";
import { kLargeDesktop } from "../../../styles/AppThemeProcessor";
import {DateTime} from "luxon";

export const kInfoListRoute = '/infos';

const useStyles = createStyles((theme) => ({
    paper: {
        width: kLargeDesktop,
        maxWidth: "100%",
        '@media (max-width: 1500px)': {
            width: "100%",
        },
    },
}));

/**
 * Screen component for list of Info.
 */
export default function InfoListScreen() {
    const appDataContext = useContext(AppDataContext);
    const { setTitle, theme } = appDataContext;

    const { classes } = useStyles();

    const [opened, { open, close }] = useDisclosure(false);
    const [editInfo, setEditInfo] = useState<IInfo | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setTitle(tt('infoList.screen.title'));
    }, []);

    /**
     * OnCreate opens modal for creating new Info.
     */
    const onCreate = () => {
        setEditInfo(null);
        open();
    };

    /**
     * OnEdit opens modal for editing Info.
     */
    const onEdit = (info: IInfo) => {
        setEditInfo(info);
        open();
    };

    /**
     * Save Info data from form.
     */
    const onSubmit = async (info: IInfo) => {
        try {
            setLoading(true);

            await WaitFor(kDefaultWaitForMillis);

            await saveInfo(info);

            close();

            SuccessToast(tt('saveInfo.success'));
        } catch (e) {
            console.error(e);

            ErrorToast(tt('error.saveInfo.failed'));
        }

        setLoading(false);
    };

    /**
     * Delete Info.
     */
    const onDelete = async (info: IInfo) => {
        SetConfirmActionModal(appDataContext, {
            onConfirm: async () => {
                try {
                    await deleteInfo(info);

                    HideConfirmActionModal(appDataContext);

                    SuccessToast(tt('deleteInfo.success'));
                } catch (e) {
                    console.error(e);

                    ErrorToast(tt('error.deleteInfo.failed'));
                }
            },
        });
    };

    /**
     * Duplicate info, opens modal for editing.
     */
    const onDuplicate = (info: IInfo) => {
        const newInfo = { ...info };
        delete newInfo.id;

        newInfo.createdAt = DateTime.local().toMillis();

        setEditInfo(newInfo);
        open();
    };

    return (
        <>
            <ScreenContent>
                <AppPaper
                    className={classes.paper}
                >
                    <Group w="100%" position="right">
                        <AppButton
                            mb="sm"
                            onClick={onCreate}
                            leftIcon={<Plus />}
                        >
                            {tt('infoEditModal.create')}
                        </AppButton>
                    </Group>

                    <InfosTable
                        onEdit={onEdit}
                        onDelete={onDelete}
                        onDuplicate={onDuplicate}
                    />
                </AppPaper>
            </ScreenContent>

            <InfoEditModal
                opened={opened}
                close={close}
                existing={editInfo}
                onSubmit={onSubmit}
                loading={loading}
            />
        </>
    );
}
