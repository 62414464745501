import { Switch, TextInput, useComponentDefaultProps } from "@mantine/core";
import { AdminSaveSubscriptionTemplateInput, SubscriptionResponse } from "../../../../generated/graphql/graphql";
import { tt } from "../../../../core/Localization";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import AppModal from "../AppModal";
import AppButton from "../../buttons/AppButton";

export interface ISubscriptionTemplateEditModalProps {
    opened: boolean;
    close: () => void;
    existing?: SubscriptionResponse | NullOrUndefined;
    onSubmit: (input: AdminSaveSubscriptionTemplateInput) => void;
    loading?: boolean;
}

const defaultProps: Partial<ISubscriptionTemplateEditModalProps> = {
    loading: false,
};

export interface SubscriptionTemplateFormValues {
    name: string;
    translationKey: string;
    maxUsers: number;
    validForDays: number;
    validForMonths: number;
}

/**
 * Modal component for editing subscription template or create new.
 */
export default function SubscriptionTemplateEditModal(props: ISubscriptionTemplateEditModalProps) {
    const { opened, close, existing, onSubmit, loading } = useComponentDefaultProps('SubscriptionTemplateEditModal', defaultProps, props);

    const title = existing ? tt('subscriptionTemplateEditModal.title.edit') : tt('subscriptionTemplateEditModal.title.create');

    const [useValidForMonths, setUseValidForMonths] = useState<boolean>(true);

    const form = useForm<SubscriptionTemplateFormValues>({
        initialValues: {
            name: existing ? existing.name : '',
            translationKey: existing ? existing.translationKey : '',
            maxUsers: existing ? existing.maxUsers : 1,
            validForDays: existing?.validForDays || 14,
            validForMonths: existing?.validForMonths || 3,
        },
        validate: {
            name: (value) => value ? null : tt('error.isRequired'),
            translationKey: (value) => value ? null : tt('error.isRequired'),
            maxUsers: (value) => value && value > 0 ? null : tt('error.mustBePositive'),
            validForDays: (value) => (value && value > 0) || useValidForMonths ? null : tt('error.mustBePositive'),
            validForMonths: (value) => (value && value > 0) || !useValidForMonths ? null : tt('error.mustBePositive'),
        },
    });

    useEffect(() => {
        if (opened) {
            form.setValues({
                name: existing ? existing.name : '',
                translationKey: existing ? existing.translationKey : '',
                maxUsers: existing ? existing.maxUsers : 10,
                validForDays: existing?.validForDays || 14,
                validForMonths: existing?.validForMonths || 3,
            });

            setUseValidForMonths(
                existing ? !!existing.validForMonths : true,
            );
        }
    }, [opened]);

    /**
     * OnSubmit process values and send to callback.
     */
    const onSubmitProcess = (values: SubscriptionTemplateFormValues) => {
        onSubmit({
            id: existing?.id,
            name: values.name,
            translationKey: values.translationKey,
            maxUsers: values.maxUsers,
            validForDays: useValidForMonths ? null : values.validForDays,
            validForMonths: useValidForMonths ? values.validForMonths : null,
        });
    };

    return (
        <AppModal
            opened={opened}
            close={close}
            title={title}
        >
            <form
                onSubmit={form.onSubmit((values) => onSubmitProcess(values))}
            >
                <TextInput
                    label={tt('subscriptionTemplateEditModal.name.label')}
                    placeholder={tt('subscriptionTemplateEditModal.name.placeholder')}
                    mb="sm"
                    {...form.getInputProps('name')}
                />

                <TextInput
                    label={tt('subscriptionTemplateEditModal.translationKey.label')}
                    placeholder={tt('subscriptionTemplateEditModal.translationKey.placeholder')}
                    mb="sm"
                    {...form.getInputProps('translationKey')}
                />

                <TextInput
                    label={tt('subscriptionTemplateEditModal.maxUsers.label')}
                    placeholder={tt('subscriptionTemplateEditModal.maxUsers.placeholder')}
                    type="number"
                    mb="sm"
                    {...form.getInputProps('maxUsers')}
                />

                <Switch
                    onLabel={tt('subscriptionTemplateEditModal.validForMonths.label')}
                    offLabel={tt('subscriptionTemplateEditModal.validForDays.label')}
                    mb="sm"
                    size="lg"
                    checked={useValidForMonths}
                    onChange={(event) => setUseValidForMonths(event.currentTarget.checked)}
                />

                {useValidForMonths ? (
                    <TextInput
                        label={tt('subscriptionTemplateEditModal.validForMonths.label')}
                        placeholder={tt('subscriptionTemplateEditModal.validForMonths.placeholder')}
                        type="number"
                        mb="sm"
                        {...form.getInputProps('validForMonths')}
                    />
                ) : null}

                {!useValidForMonths ? (
                    <TextInput
                        label={tt('subscriptionTemplateEditModal.validForDays.label')}
                        placeholder={tt('subscriptionTemplateEditModal.validForDays.placeholder')}
                        type="number"
                        mb="sm"
                        {...form.getInputProps('validForDays')}
                    />
                ) : null}

                <AppButton
                    loading={loading}
                    type="submit"
                    fullWidth={true}
                    mb="sm"
                >
                    {tt('common.submit')}
                </AppButton>
            </form>
        </AppModal>
    );
}
