import React, {useContext, useEffect, useState} from "react";
import {AppDataContext} from "../../../AppData";
import {tt} from "../../../core/Localization";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/screens/AppPaper";
import {useDisclosure} from "@mantine/hooks";
import AppButton from "../../components/buttons/AppButton";
import TimotyNotificationsSettingsModal from "../../components/modals/timotySettings/TimotyNotificationsSettingsModal";
import {FetchPolicy, RestApiClientContext} from "../../../core/RestApiProvider";
import {
    SaveTimotySettingsItemsForKeysInput,
    SaveTimotySettingsItemsForKeysInputItemInput, TimotySettingsItemResponsePage
} from "../../../generated/graphql/graphql";
import {processMutationError} from "../../../service/ErrorService";
import {SuccessToast} from "../../../service/ToastService";
import FormCategoryTitle from "../../components/form/FormCategoryTitle";

export const kTimotySettingsRoute = '/timoty-settings';

/**
 * Screen component for various settings for Timoty system as a whole.
 */
export default function TimotySettingsScreen() {
    const appDataContext = useContext(AppDataContext);
    const {setTitle, auth} = appDataContext;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiPost, restUriBuilder} = restApiClientContext;

    const [notificationsOpened, { open: openNotifications, close: closeNotifications }] = useDisclosure(false);

    useEffect(() => {
        setTitle(tt('timotySettings.screen.title'));
    }, []);

    const [loading, setLoading] = useState(false);
    /**
     * Save settings to BE.
     */
    const onSubmit = (data: SaveTimotySettingsItemsForKeysInputItemInput[]) => {
        setLoading(true);

        restApiPost({
            uri: '/admin/timoty-settings/user-preferences-items-for-keys',
            params: {
                items: data,
            } as SaveTimotySettingsItemsForKeysInput,
            fetchPolicy: FetchPolicy.NetworkOnly,
            setLoading,
            onData: (data: TimotySettingsItemResponsePage) => {
                if (data) {
                    closeNotifications();

                    SuccessToast(tt('timotySettings.notificationsSettings.success'));
                }
            },
            onError: (error) => processMutationError(error),
        });
    };

    /**
     * Create and download backup.
     */
    const downloadBackup = () => {
        const input = {
            firebaseUid: auth.authUser?.uid,
        };
        const downloadUrl = restUriBuilder('/admin/backup/create-and-download-backup', input);

        window.open(downloadUrl, '_blank');
    };

    return (
        <>
            <ScreenContent>
                <AppPaper
                    mb="md"
                    centerContent={false}
                >
                    <FormCategoryTitle
                        title={tt('timotySettings.screen.notifications.title')}
                        styleProps={{mb: 'sm'}}
                    />

                    <AppButton
                        variant="default"
                        type="button"
                        fullWidth={true}
                        onClick={openNotifications}
                    >
                        {tt('timotySettings.screen.notifications')}
                    </AppButton>

                    <FormCategoryTitle
                        title={tt('timotySettings.screen.database.title')}
                        styleProps={{mb: 'sm'}}
                    />

                    <AppButton
                        variant="default"
                        type="button"
                        fullWidth={true}
                        onClick={downloadBackup}
                    >
                        {tt('timotySettings.screen.database.createBackup')}
                    </AppButton>
                </AppPaper>
            </ScreenContent>

            <TimotyNotificationsSettingsModal
                opened={notificationsOpened}
                close={closeNotifications}
                onSubmit={onSubmit}
                loading={loading}
            />
        </>
    );
}
