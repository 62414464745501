import {Button, ButtonProps, Tooltip, TooltipProps} from "@mantine/core";
import {MouseEventHandler} from "react";

export interface IAppButtonProps extends ButtonProps {
    tooltip?: string;
    onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}

/**
 * Main App button component.
 */
export default function AppButton(props: IAppButtonProps) {
    const {tooltip} = props;

    let contentJSX = <Button {...props}/>;

    if (tooltip) {
        contentJSX = (
            <Tooltip
                label={tooltip}
            >
                {contentJSX}
            </Tooltip>
        );
    }

    return (
        <>{contentJSX}</>
    );
}
