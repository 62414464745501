import {
    AdminAddSubscriptionToCompanyInput,
    AdminEditCompanySubscriptionInput,
    AdminGetSubscriptionsForCompanyInput,
    AdminGetSubscriptionsInput,
    AdminSubscriptionResponsePage,
    SubscriptionResponse,
    SubscriptionResponsePage
} from "../../../../generated/graphql/graphql";
import {Select, SelectItem, Text, TextInput, useComponentDefaultProps} from "@mantine/core";
import {tt} from "../../../../core/Localization";
import {useForm} from "@mantine/form";
import React, {useContext, useEffect, useState} from "react";
import AppModal from "../AppModal";
import AppButton from "../../buttons/AppButton";
import {RestApiClientContext} from "../../../../core/RestApiProvider";
import {processQueryError} from "../../../../service/ErrorService";
import {AppDataContext} from "../../../../AppData";
import {lastSubscriptionForCompany} from "../../../../service/CompanyService";
import {DateTime} from "luxon";
import {ErrorToast} from "../../../../service/ToastService";
import {DateTimePicker, DateValue} from "@mantine/dates";
import OptionalElement from "../../form/OptionalElement";

export interface ICompanySubscriptionEditModalOnSubmit {
    add?: AdminAddSubscriptionToCompanyInput;
    edit?: AdminEditCompanySubscriptionInput;
}

export interface ICompanySubscriptionEditModalProps {
    companyId: number;
    opened: boolean;
    close: () => void;
    existing: SubscriptionResponse | null;
    onSubmit: (input: ICompanySubscriptionEditModalOnSubmit) => void;
    loading?: boolean;
}

const defaultProps: Partial<ICompanySubscriptionEditModalProps> = {
    loading: false,
};

export interface CompanySubscriptionFormValues {
    template: number;
    maxUsers?: number;
    validFrom?: DateValue;
    validTo?: DateValue;
}

/**
 * Modal component for editing company subscription.
 * Is used to also add new one, when the old one ends.
 */
export function CompanySubscriptionEditModal(props: ICompanySubscriptionEditModalProps) {
    const {
        companyId,
        opened,
        close,
        existing,
        onSubmit,
        loading
    } = useComponentDefaultProps('CompanySubscriptionEditModal', defaultProps, props);

    const restApiClientContext = useContext(RestApiClientContext);
    const { restApiGet } = restApiClientContext;

    const appDataContext = useContext(AppDataContext);

    const title = existing ? tt('companySubscriptionEditModal.title.edit') : tt('companySubscriptionEditModal.title.addSubscriptionToCompany');

    const [templates, setTemplates] = useState<SubscriptionResponse[]>([]);
    const [templateItems, setTemplateItems] = useState<SelectItem[]>([]);
    const [checkElementValue, setCheckElementValue] = useState<number>(0);
    const [optionalMaxUsers, setOptionalMaxUsers] = useState<boolean>(false);
    const [optionalValidFrom, setOptionalValidFrom] = useState<boolean>(false);
    const [optionalValidTo, setOptionalValidTo] = useState<boolean>(false);

    const form = useForm<CompanySubscriptionFormValues>({
        initialValues: {
            template: existing ? existing.templateId : 0,
            maxUsers: existing ? existing.maxUsers : undefined,
            validFrom: existing ? DateTime.fromMillis(existing.validFrom).toJSDate() : undefined,
            validTo: existing ? DateTime.fromMillis(existing.validTo).toJSDate() : undefined,
        },
        validate: {
            template: (value) => value ? null : tt('error.isRequired'),
            maxUsers: (value) => (value && value > 0) || (!value && !existing) ? null : tt('error.mustBePositive'),
            validFrom: (value) => {
                if (!value && !existing) {
                    return null;
                } else if (!value && existing) {
                    return tt('error.isRequired');
                } else if (value && form.values.validTo && DateTime.fromJSDate(form.values.validTo).toMillis() < DateTime.fromJSDate(value).toMillis()) {
                    return tt('error.invalidDateRange');
                } else {
                    return null;
                }
            },
            validTo: (value) => {
                if (!value && !existing) {
                    return null;
                } else if (!value && existing) {
                    return tt('error.isRequired');
                } else if (value && form.values.validFrom && DateTime.fromJSDate(form.values.validFrom).toMillis() > DateTime.fromJSDate(value).toMillis()) {
                    return tt('error.invalidDateRange');
                } else {
                    return null;
                }
            },
        },
    });

    useEffect(() => {
        if (opened) {
            form.setValues({
                template: existing ? existing.templateId : 0,
                maxUsers: existing ? existing.maxUsers : undefined,
                validFrom: existing ? DateTime.fromMillis(existing.validFrom).toJSDate() : undefined,
                validTo: existing ? DateTime.fromMillis(existing.validTo).toJSDate() : undefined,
            });

            setCheckElementValue(checkElementValue + 1);
        }
    }, [opened]);

    const [loadingTemplates, setLoadingTemplates] = useState(false);
    useEffect(() => {
        if (opened) {
            restApiGet({
                uri: '/admin/subscription/search-template',
                params: {} as AdminGetSubscriptionsInput,
                setLoading: (loading: boolean) => setLoadingTemplates(loading),
                onData: (data: AdminSubscriptionResponsePage) => {
                    if (data) {
                        setTemplates(data.content);
                        setTemplateItems(data.content.map((item) => ({
                            value: item.id,
                            label: tt(item.translationKey),
                        })));
                    } else {
                        setTemplates([]);
                        setTemplateItems([]);
                    }
                },
                onError: (error: any) => processQueryError(appDataContext, error),
            });
        }
    }, [opened]);

    const [lastCompanySubscription, setLastCompanySubscription] = useState<SubscriptionResponse | NullOrUndefined>(null);
    const [loadingCompanySubscriptions, setLoadingCompanySubscriptions] = useState(false);
    useEffect(() => {
        if (opened && !existing) {
            restApiGet({
                uri: '/admin/subscription/company',
                params: {
                    company: companyId,
                } as AdminGetSubscriptionsForCompanyInput,
                setLoading: (loading: boolean) => setLoadingCompanySubscriptions(loading),
                onData: (data: SubscriptionResponsePage) => {
                    if (data) {
                        setLastCompanySubscription(
                            lastSubscriptionForCompany(companyId, data.content),
                        );
                    } else {
                        setLastCompanySubscription(null);
                    }
                },
                onError: (error: any) => processQueryError(appDataContext, error),
            });
        }
    }, [opened, existing]);

    useEffect(() => {
        if (!existing) {
            setOptionalMaxUsers(false);
            form.setFieldValue('maxUsers', undefined);
            setOptionalValidFrom(false);
            form.setFieldValue('validFrom', undefined);
            setOptionalValidTo(false);
            form.setFieldValue('validTo', undefined);
        }
    }, [form.values.template]);

    /**
     * Show field for maxUsers and set by template.
     */
    const showMaxUsers = (visible: boolean) => {
        if (visible) {
            if (form.values.template && templates && !existing) {
                const template = templates.find((item) => item.id === form.values.template);

                if (template) {
                    form.setFieldValue('maxUsers', template.maxUsers);
                } else {
                    form.setFieldValue('maxUsers', 1);
                }
            }
        }

        setOptionalMaxUsers(visible);
    };

    /**
     * Show field for validFrom and set by template.
     */
    const showValidFrom = (visible: boolean) => {
        if (visible) {
            if (!existing) {
                const validFrom = lastCompanySubscription?.validTo ? lastCompanySubscription.validTo + 1 : DateTime.now().toMillis();
                form.setFieldValue('validFrom', DateTime.fromMillis(validFrom).toJSDate());
            }
        }

        setOptionalValidFrom(visible);
    };

    /**
     * Show field for validTo and set by template.
     */
    const showValidTo = (visible: boolean) => {
        if (visible) {
            if (form.values.template && templates && !existing) {
                const template = templates.find((item) => item.id === form.values.template);

                if (template) {
                    const validFrom = lastCompanySubscription?.validTo ? lastCompanySubscription.validTo + 1 : DateTime.now().toMillis();

                    let validTo = DateTime.fromMillis(validFrom);

                    if (template.validForDays) {
                        validTo = validTo.plus({days: template.validForDays});
                    } else if (template.validForMonths) {
                        validTo = validTo.plus({months: template.validForMonths});
                    } else {
                        ErrorToast('Subscription has no validForDays or validForMonths');
                        return;
                    }

                    form.setFieldValue('validTo', validTo.toJSDate());
                } else {
                    form.setFieldValue('validTo', undefined);
                }
            }
        }

        setOptionalValidTo(visible);
    };

    /**
     * OnSubmit process values and send to callback.
     */
    const onSubmitProcess = (values: CompanySubscriptionFormValues) => {
        if (existing) {
            onSubmit({
                edit: {
                    subscriptionId: existing.id,
                    maxUsers: values.maxUsers!,
                    validFrom: DateTime.fromJSDate(values.validFrom!).toMillis(),
                    validTo: DateTime.fromJSDate(values.validTo!).toMillis(),
                },
            });
        } else {
            const validFrom = lastCompanySubscription?.validTo ? lastCompanySubscription.validTo + 1 : undefined;

            onSubmit({
                add: {
                    companyId,
                    templateId: values.template,
                    maxUsers: values.maxUsers,
                    validFrom: (values.validFrom ? DateTime.fromJSDate(values.validFrom).toMillis() : null) || validFrom,
                    validTo: values.validTo ? DateTime.fromJSDate(values.validTo).toMillis() : null,
                },
            });
        }
    };

    const selectedTemplate = existing?.templateId && templates ? templates.find((item) => item.id === existing.templateId) : undefined;

    return (
        <AppModal
            opened={opened}
            close={close}
            title={title}
        >
            <form
                onSubmit={form.onSubmit((values) => onSubmitProcess(values))}
            >
                {!existing ? (
                    <Select
                        label={tt('companySubscriptionEditModal.template.label')}
                        placeholder={tt('companySubscriptionEditModal.template.placeholder')}
                        mb="sm"
                        data={templateItems}
                        withinPortal={true}
                        {...form.getInputProps('template')}
                    />
                ) : (
                    <Text
                        color="orange"
                        mb="sm"
                    >{selectedTemplate ? tt(selectedTemplate.translationKey) : ''}</Text>
                )}

                {form.values.template ? (
                    <>
                        <OptionalElement
                            text={tt('companySubscriptionEditModal.maxUsers.optional')}
                            elementValue={form.values.maxUsers}
                            checkElementValue={checkElementValue}
                            controlledVisible={!existing ? optionalMaxUsers : undefined}
                            onControlledVisibleChange={!existing ? showMaxUsers : undefined}
                        >
                            <TextInput
                                label={tt('companySubscriptionEditModal.maxUsers.label')}
                                placeholder={tt('companySubscriptionEditModal.maxUsers.placeholder')}
                                type="number"
                                mb="sm"
                                {...form.getInputProps('maxUsers')}
                            />
                        </OptionalElement>

                        <OptionalElement
                            text={tt('companySubscriptionEditModal.validFrom.optional')}
                            elementValue={form.values.validFrom}
                            checkElementValue={checkElementValue}
                            controlledVisible={!existing ? optionalValidFrom : undefined}
                            onControlledVisibleChange={!existing ? showValidFrom : undefined}
                        >
                            <DateTimePicker
                                dropdownType="modal"
                                label={tt('companySubscriptionEditModal.validFrom.label')}
                                placeholder={tt('companySubscriptionEditModal.validFrom.placeholder')}
                                mb="sm"
                                maxDate={form.values.validTo ? form.values.validTo : undefined}
                                {...form.getInputProps('validFrom')}
                            />
                        </OptionalElement>

                        <OptionalElement
                            text={tt('companySubscriptionEditModal.validTo.optional')}
                            elementValue={form.values.validTo}
                            checkElementValue={checkElementValue}
                            controlledVisible={!existing ? optionalValidTo : undefined}
                            onControlledVisibleChange={!existing ? showValidTo : undefined}
                        >
                            <DateTimePicker
                                dropdownType="modal"
                                label={tt('companySubscriptionEditModal.validTo.label')}
                                placeholder={tt('companySubscriptionEditModal.validTo.placeholder')}
                                mb="sm"
                                minDate={form.values.validFrom ? form.values.validFrom : undefined}
                                {...form.getInputProps('validTo')}
                            />
                        </OptionalElement>
                    </>
                ) : null}

                <AppButton
                    loading={loading}
                    type="submit"
                    fullWidth={true}
                    mb="sm"
                >
                    {tt('common.submit')}
                </AppButton>
            </form>
        </AppModal>
    );
}
