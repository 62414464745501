import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useState } from "react";
import { useSetState } from "@mantine/hooks";
import IAuthUser from "./model/AuthUser";
import { FlavorByEnvironment } from "./flavor-config";
import { initializeApp } from "firebase/app";
import { InitTheme, kDefaultTheme } from "./service/ThemeService";
import { InitLanguage, kDefaultLanguage } from "./core/Localization";
import { IAppModalsProps } from "./ui/components/modals/AppModals";
import RestApiProvider, {k30Minutes} from "./core/RestApiProvider";
import EventSystemProcessor from "./core/EventSystemProcessor";
import {TimotySettingsItemResponse} from "./generated/graphql/graphql";
import TimotySettingsProcessor from "./core/TimotySettingsProcessor";

const config = FlavorByEnvironment();

initializeApp(config!.firebase);

const title = document.getElementById('document-title');
const appTitle = title && title.dataset.title ? title.dataset.title : '';

export interface IAppDataContext {
    title?: string;
    setTitle: Dispatch<SetStateAction<string | undefined>>;
    language: string;
    setLanguage: Dispatch<SetStateAction<string>>;
    auth: IAppDataAuth;
    setAuth: (statePartial: (Partial<IAppDataAuth> | ((currentState: IAppDataAuth) => Partial<IAppDataAuth>))) => void;
    theme: IAppDataTheme;
    setTheme: (statePartial: (Partial<IAppDataTheme> | ((currentState: IAppDataTheme) => Partial<IAppDataTheme>))) => void;
    appModals: IAppModalsProps;
    setAppModals: (statePartial: (Partial<IAppModalsProps> | ((currentState: IAppModalsProps) => Partial<IAppModalsProps>))) => void;
    timotySettings: TimotySettingsItemResponse[];
    setTimotySettings: Dispatch<SetStateAction<TimotySettingsItemResponse[]>>;
}

export interface IAppDataAuth {
    authUserVerified?: boolean;
    authUser?: IAuthUser | null;
}

export interface IAppDataTheme {
    theme: string;
    darkMode: boolean;
    isMobile: boolean;
}

export const AppDataContext = createContext<IAppDataContext>({} as IAppDataContext);

export interface IAppDataProps {
    children?: ReactNode;
}

/**
 * Component which is App data state container.
 */
export default function AppData(props: IAppDataProps) {
    const { children } = props;

    const [title, setTitle] = useState<string>();
    const [language, setLanguage] = useState<string>(kDefaultLanguage);
    const [auth, setAuth] = useSetState<IAppDataAuth>({});
    const [theme, setTheme] = useSetState<IAppDataTheme>({
        theme: kDefaultTheme,
        darkMode: false,
        isMobile: false,
    });
    const [appModals, setAppModals] = useSetState<IAppModalsProps>({});
    const [timotySettings, setTimotySettings] = useState<TimotySettingsItemResponse[]>([]);

    useEffect(() => {
        document.title = title ? `${title} - ${appTitle}` : appTitle;
    }, [title]);

    // console.log('TCH_d', auth.authUser?.email, auth.authUser?.idToken);
    // console.log('TCH_d', auth.authUser?.uid);

    const appDataContext: IAppDataContext = {
        title, setTitle,
        language, setLanguage,
        auth, setAuth,
        theme, setTheme,
        appModals, setAppModals,
        timotySettings, setTimotySettings,
    };

    useEffect(() => {
        InitLanguage(setLanguage);

        InitTheme(setTheme);
    }, []);

    useEffect(() => {
        if (theme.theme === 'light') {
            document.body.classList.remove('dark');
            document.body.classList.add('light');
        }
        if (theme.theme === 'dark') {
            document.body.classList.remove('light');
            document.body.classList.add('dark');
        }
    }, [theme.theme]);

    return (
        <RestApiProvider
            appDataContext={appDataContext}
            baseUri={config!!.restEndpoint}
            pollSubscriptionsInterval={k30Minutes}
        >
            <AppDataContext.Provider value={appDataContext}>
                <EventSystemProcessor>
                    <TimotySettingsProcessor>
                        {children}
                    </TimotySettingsProcessor>
                </EventSystemProcessor>
            </AppDataContext.Provider>
        </RestApiProvider>
    );
}
