import {MultiSelect, SelectItem, Switch, useComponentDefaultProps} from "@mantine/core";
import AppModal from "../AppModal";
import {tt} from "../../../../core/Localization";
import {useForm, UseFormReturnType} from "@mantine/form";
import React, {useContext, useEffect, useState} from "react";
import {AppDataContext} from "../../../../AppData";
import {
    kTimotySettingsCreateCompanyEmails,
    kTimotySettingsCreateCompanyEnabled,
    kTimotySettingsCreateCompanyUsers
} from "../../../../core/constants";
import AppButton from "../../buttons/AppButton";
import {
    AdminGetUsersInput,
    AdminUserResponsePage,
    SaveTimotySettingsItemsForKeysInputItemInput, UserResponse
} from "../../../../generated/graphql/graphql";
import {FetchPolicy, RestApiClientContext} from "../../../../core/RestApiProvider";
import {processQueryError} from "../../../../service/ErrorService";
import {userFullName} from "../../../../service/UserService";

export interface ITimotyNotificationsSettingsModalProps {
    opened: boolean;
    close: () => void;
    onSubmit: (data: SaveTimotySettingsItemsForKeysInputItemInput[]) => void;
    loading?: boolean;
}

const defaultProps: Partial<ITimotyNotificationsSettingsModalProps> = {
    loading: false,
};

export interface ITimotyNotificationsSettingsFormValues {
    createCompanyEnabled: boolean;
    createCompanyEmails: string[];
    createCompanyUsers: string[];
}

/**
 * Modal component to edit Timoty notifications settings.
 */
export default function TimotyNotificationsSettingsModal(props: ITimotyNotificationsSettingsModalProps) {
    const {
        opened,
        close,
        onSubmit,
        loading
    } = useComponentDefaultProps('TimotyNotificationsSettingsModal', defaultProps, props);

    const appDataContext = useContext(AppDataContext);
    const {timotySettings} = appDataContext;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiGet} = restApiClientContext;

    const [createCompanyEmailsData, setCreateCompanyEmailsData] = useState<SelectItem[]>([]);
    const [usersForSelectedCreateCompanyUsers, setUsersForSelectedCreateCompanyUsers] = useState<UserResponse[]>([]);
    const [usersForQueryCreateCompanyUsers, setUsersForQueryCreateCompanyUsers] = useState<UserResponse[]>([]);
    const [createCompanyUsersData, setCreateCompanyUsersData] = useState<SelectItem[]>([]);

    const form: UseFormReturnType<ITimotyNotificationsSettingsFormValues, (values: ITimotyNotificationsSettingsFormValues) => ITimotyNotificationsSettingsFormValues> = useForm<ITimotyNotificationsSettingsFormValues>({
        initialValues: {
            createCompanyEnabled: false,
            createCompanyEmails: [],
            createCompanyUsers: [],
        },
        validate: {
            createCompanyEnabled: (value) => null,
            createCompanyEmails: (value) => {
                return value.length > 0 || !form.values.createCompanyEnabled || form.values.createCompanyUsers.length > 0 ? null : tt('error.isRequired');
            },
            createCompanyUsers: (value) => {
                return value.length > 0 || !form.values.createCompanyEnabled || form.values.createCompanyEmails.length > 0 ? null : tt('error.isRequired');
            },
        },
    });

    useEffect(() => {
        if (opened) {
            const createCompanyEnabled = timotySettings && timotySettings.find(setting => setting.key === kTimotySettingsCreateCompanyEnabled);
            const createCompanyEmails = timotySettings && timotySettings.find(setting => setting.key === kTimotySettingsCreateCompanyEmails);
            const createCompanyUsers = timotySettings && timotySettings.find(setting => setting.key === kTimotySettingsCreateCompanyUsers);

            form.setValues({
                createCompanyEnabled: createCompanyEnabled ? (createCompanyEnabled.valueBool || false) : false,
                createCompanyEmails: createCompanyEmails && createCompanyEmails.valueJsonJSON ? JSON.parse(createCompanyEmails.valueJsonJSON).createCompanyEmails : [],
                createCompanyUsers: createCompanyUsers && createCompanyUsers.valueJsonJSON ? JSON.parse(createCompanyUsers.valueJsonJSON).createCompanyUsers : [],
            });

            if (createCompanyEmails && createCompanyEmails.valueJsonJSON) {
                setCreateCompanyEmailsData(JSON.parse(createCompanyEmails.valueJsonJSON).createCompanyEmails.map((email: string) => ({value: email, label: email})));
            } else {
                setCreateCompanyEmailsData([]);
            }
        }
    }, [opened]);

    useEffect(() => {
        if (opened) {
            setCreateCompanyUsersData([
                ...usersForSelectedCreateCompanyUsers.map((user: UserResponse) => ({value: user.id.toString(), label: `${userFullName(user.name, user.surname)} (${user.email || user.contactEmail || tt('common.error.noEmail')})`})),
                ...usersForQueryCreateCompanyUsers
                    .filter((user: UserResponse) => !usersForSelectedCreateCompanyUsers.includes(user))
                    .map((user: UserResponse) => ({value: user.id.toString(), label: `${userFullName(user.name, user.surname)} (${user.email || user.contactEmail || tt('common.error.noEmail')})`})),
            ]);
        }
    }, [opened, usersForSelectedCreateCompanyUsers, usersForQueryCreateCompanyUsers]);

    useEffect(() => {
        if (opened) {
            loadUsersForSelected(form.values.createCompanyUsers.map(userId => parseInt(userId)));
        }
    }, [opened, form.values.createCompanyUsers]);

    /**
     * Load Users by selected from BE.
     */
    const loadUsersForSelected = (userIds: number[]) => {
        if (userIds.length > 0) {
            restApiGet({
                uri: '/admin/user/search',
                params: {
                    userIds,
                } as AdminGetUsersInput,
                fetchPolicy: FetchPolicy.NetworkOnly,
                onData: (data: AdminUserResponsePage) => {
                    if (data) {
                        setUsersForSelectedCreateCompanyUsers(data.content);
                    } else {
                        setUsersForSelectedCreateCompanyUsers([]);
                    }
                },
                onError: (error) => processQueryError(appDataContext, error),
            });
        } else {
            setUsersForSelectedCreateCompanyUsers([]);
        }
    };

    /**
     * When search of Users changes, load Users by query from BE.
     */
    const loadUsersForQuery = (query: string) => {
        if (query.trim().length > 0) {
            restApiGet({
                uri: '/admin/user/search',
                params: {
                    search: query,
                    page: 0,
                    pageSize: 100,
                } as AdminGetUsersInput,
                fetchPolicy: FetchPolicy.NetworkOnly,
                onData: (data: AdminUserResponsePage) => {
                    if (data) {
                        setUsersForQueryCreateCompanyUsers(data.content);
                    } else {
                        setUsersForQueryCreateCompanyUsers([]);
                    }
                },
                onError: (error: any) => processQueryError(appDataContext, error),
            });
        } else {
            setUsersForQueryCreateCompanyUsers([]);
        }
    };

    /**
     * OnSubmit process values and send to callback.
     */
    const onSubmitProcess = (values: ITimotyNotificationsSettingsFormValues) => {
        onSubmit([
            {
                key: kTimotySettingsCreateCompanyEnabled,
                valueBool: values.createCompanyEnabled || false,
            },
            {
                key: kTimotySettingsCreateCompanyEmails,
                valueJsonJSON: JSON.stringify({
                    createCompanyEmails: values.createCompanyEmails || [],
                }),
            },
            {
                key: kTimotySettingsCreateCompanyUsers,
                valueJsonJSON: JSON.stringify({
                    createCompanyUsers: values.createCompanyUsers || [],
                }),
            },
        ]);
    };

    return (
        <AppModal
            size="lg"
            opened={opened}
            close={close}
            title={tt('timotyNotificationsSettings.modal.title')}
        >
            <form
                onSubmit={form.onSubmit((values) => onSubmitProcess(values))}
            >
                <Switch
                    label={tt('timotyNotificationsSettings.modal.createCompanyEnabled')}
                    onLabel={tt('common.yes')}
                    offLabel={tt('common.no')}
                    mb="sm"
                    size="lg"
                    checked={form.values.createCompanyEnabled}
                    {...form.getInputProps('createCompanyEnabled')}
                />

                {form.values.createCompanyEnabled ? (
                    <>
                        <MultiSelect
                            label={tt('timotyNotificationsSettings.modal.createCompanyUsers')}
                            placeholder={tt('timotyNotificationsSettings.modal.createCompanyUsers.placeholder')}
                            mb="sm"
                            data={createCompanyUsersData}
                            searchable={true}
                            creatable={false}
                            onSearchChange={loadUsersForQuery}
                            withinPortal={true}
                            {...form.getInputProps('createCompanyUsers')}
                        />

                        <MultiSelect
                            label={tt('timotyNotificationsSettings.modal.createCompanyEmails')}
                            placeholder={tt('timotyNotificationsSettings.modal.createCompanyEmails.placeholder')}
                            mb="sm"
                            data={createCompanyEmailsData}
                            searchable={true}
                            creatable={true}
                            getCreateLabel={(query) => tt('timotyNotificationsSettings.modal.createCompanyEmails.create.selectItem').replace('{query}', query)}
                            onCreate={(query) => {
                                const item = {value: query, label: query};
                                setCreateCompanyEmailsData((current) => [...current, item]);
                                return item;
                            }}
                            {...form.getInputProps('createCompanyEmails')}
                        />
                    </>
                ) : null}

                <AppButton
                    loading={loading}
                    type="submit"
                    fullWidth={true}
                    mb="sm"
                >
                    {tt('common.submit')}
                </AppButton>
            </form>
        </AppModal>
    );
}
