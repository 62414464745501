import {ActionIcon, Box, Flex, Group, Table} from "@mantine/core";
import React, {useContext, useEffect, useState} from "react";
import {
    appTypeToString,
    countAppVersions,
    kAppVersionsPageSize,
    subscribeAppVersions
} from "../../../service/AppVersionService";
import IAppVersion from "../../../model/firestore/AppVersion";
import {DateTime} from "luxon";
import {AppDataContext} from "../../../AppData";
import {tt} from "../../../core/Localization";
import {Edit, Minus, Plus, Trash} from "tabler-icons-react";
import AppButton from "../buttons/AppButton";
import {useGeneralStyles} from "../../../styles/GeneralStyles";
import AppIconButton from "../buttons/AppIconButton";

export interface IAppVersionsTableProps {
    onEdit?: (appVersion: IAppVersion) => void;
    onDelete?: (appVersion: IAppVersion) => void;
}

/**
 * Table component for displaying app versions.
 */
export default function AppVersionsTable(props: IAppVersionsTableProps) {
    const {onEdit, onDelete} = props;

    const appDataContext = useContext(AppDataContext);
    const {language} = appDataContext;

    const [appVersions, setAppVersions] = useState<IAppVersion[]>([]);
    const [loadPages, setLoadPages] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);

    const {classes: generalClasses} = useGeneralStyles();

    const totalRequestCount = kAppVersionsPageSize * loadPages;
    const canLoadLess = loadPages > 1;
    const canLoadMore = appVersions.length >= totalRequestCount;

    useEffect(() => {
        const unsubscribe = subscribeAppVersions((snapshot) => {
            const newAppVersions: IAppVersion[] = [];

            snapshot.forEach((doc) => {
                const data = doc.data();
                const appVersion: IAppVersion = data as any;

                appVersion.id = doc.id;

                newAppVersions.push(appVersion);
            });

            setAppVersions(newAppVersions);

            setTimeout(() => {
                countAppVersions()
                    .then((count) => {
                        setTotal(count);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }, 300);
        }, loadPages);

        return () => {
            unsubscribe();
        };
    }, [loadPages]);

    /**
     * Load less app versions.
     */
    const onLoadLess = () => {
        if (canLoadLess) {
            setLoadPages(loadPages - 1);
        }
    };

    /**
     * Load more app versions.
     */
    const onLoadMore = () => {
        if (canLoadMore) {
            setLoadPages(loadPages + 1);
        }
    };

    const rowsJSX = appVersions.map((appVersion) => {
        const date = DateTime.fromMillis(appVersion.createdAt);

        return (
            <tr key={appVersion.id}>
                <td>{appTypeToString(appVersion.app)}</td>
                <td>{appVersion.version}</td>
                <td>{date.toLocaleString(DateTime.DATETIME_MED, {locale: language})}</td>
                <td align="right">
                    <Flex
                        justify="flex-end"
                    >
                        <AppIconButton
                            onClick={() => onEdit && onEdit(appVersion)}
                            tooltip={tt('common.table.button.edit')}
                        >
                            <Edit/>
                        </AppIconButton>

                        <AppIconButton
                            onClick={() => onDelete && onDelete(appVersion)}
                            tooltip={tt('common.table.button.delete')}
                        >
                            <Trash color="red"/>
                        </AppIconButton>
                    </Flex>
                </td>
            </tr>
        );
    });

    return (
        <>
            <Table
                highlightOnHover={true}
                mb="md"
            >
                <thead>
                <tr>
                    <th>{tt('appVersionsTable.header.app')}</th>
                    <th>{tt('appVersionsTable.header.version')}</th>
                    <th>{tt('appVersionsTable.header.createdAt')}</th>
                    <Box component="th" className={generalClasses.textAlignRightImportant}>
                        {tt('common.table.items.total').replace('$total', total)}
                    </Box>
                </tr>
                </thead>

                <tbody>
                {rowsJSX}
                </tbody>

                <tfoot>
                <tr>
                    <th>{tt('appVersionsTable.header.app')}</th>
                    <th>{tt('appVersionsTable.header.version')}</th>
                    <th>{tt('appVersionsTable.header.createdAt')}</th>
                    <Box component="th" className={generalClasses.textAlignRightImportant}>
                        {tt('common.table.items.total').replace('$total', total)}
                    </Box>
                </tr>
                </tfoot>
            </Table>

            <Group w="100%" position="right">
                <AppButton
                    mb="sm"
                    mr="sm"
                    onClick={onLoadLess}
                    leftIcon={<Minus/>}
                    disabled={!canLoadLess}
                >
                    {tt('common.table.button.loadLess')}
                </AppButton>

                <AppButton
                    mb="sm"
                    onClick={onLoadMore}
                    leftIcon={<Plus/>}
                    disabled={!canLoadMore}
                >
                    {tt('common.table.button.loadMore')}
                </AppButton>
            </Group>
        </>
    );
}
