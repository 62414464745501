import {createStyles, TextInput} from "@mantine/core";
import AppPaper from "../../components/screens/AppPaper";
import ScreenContent from "../../components/screens/ScreenContent";
import UsersTable from "../../components/users/UsersTable";
import {kLargeDesktop} from "../../../styles/AppThemeProcessor";
import {AdminUserSetAdminInput, UserResponse} from "../../../generated/graphql/graphql";
import {useContext, useEffect, useState} from "react";
import {FetchPolicy, RestApiClientContext} from "../../../core/RestApiProvider";
import {tt} from "../../../core/Localization";
import {processMutationError} from "../../../service/ErrorService";
import {SuccessToast} from "../../../service/ToastService";
import {AppDataContext} from "../../../AppData";

export const kUserListRoute = '/users';

const useStyles = createStyles((theme) => ({
    paper: {
        width: kLargeDesktop,
        maxWidth: "100%",
        '@media (max-width: 1500px)': {
            width: "100%",
        },
    },
}));

/**
 * Screen component for list of User.
 */
export default function UserListScreen() {
    const appDataContext = useContext(AppDataContext);
    const {setTitle} = appDataContext;

    const restApiClientContext = useContext(RestApiClientContext);
    const {restApiPost} = restApiClientContext;

    const {classes} = useStyles();

    const [search, setSearch] = useState<string>('');

    useEffect(() => {
        setTitle(tt('userList.screen.title'));
    }, []);

    /**
     * Toggle super admin status.
     */
    const onToggleSuperAdmin = (user: UserResponse, superAdmin: boolean) => {
        restApiPost({
            uri: '/admin/user/set-admin',
            params: {
                userId: user.id,
                superAdmin,
            } as AdminUserSetAdminInput,
            fetchPolicy: FetchPolicy.NetworkOnly,
            onData: (data: UserResponse) => {
                if (data) {
                    SuccessToast(
                        superAdmin ? tt('userList.superAdminSet.success') : tt('userList.superAdminUnset.success'),
                    );
                }
            },
            onError: (error) => processMutationError(error),
        });
    };

    return (
        <>
            <ScreenContent>
                <AppPaper
                    className={classes.paper}
                    mb="md"
                    centerContent={false}
                >
                    <TextInput
                        label={tt('userList.search')}
                        placeholder={tt('userList.search.placeholder')}
                        mb="sm"
                        value={search}
                        onChange={(event) => setSearch(event.currentTarget.value)}
                    />
                </AppPaper>

                <AppPaper
                    className={classes.paper}
                >
                    <UsersTable
                        search={search}
                        onToggleSuperAdmin={onToggleSuperAdmin}
                    />
                </AppPaper>
            </ScreenContent>
        </>
    );
}
