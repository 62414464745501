import AppVersion, {AppType} from "../model/firestore/AppVersion";
import {
    collection,
    deleteDoc,
    doc,
    getCountFromServer,
    getDocs,
    getFirestore,
    limit,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    Unsubscribe,
    where
} from "firebase/firestore";
import {DateTime} from "luxon";
import packageJson from "../../package.json";
import {IAppDataContext} from "../AppData";
import {SetAppVersionModal} from "../ui/components/modals/AppModals";
import {tt} from "../core/Localization";
import {QuerySnapshot} from "@firebase/firestore";
import {SelectItem} from "@mantine/core";

export const kAppVersionsCollection = 'app_versions';

/**
 * Create new or update existing AppVersion on Firestore.
 */
export async function saveAppVersion(appVersion: AppVersion): Promise<void> {
    const firestore = getFirestore();
    const theCollection = collection(firestore, kAppVersionsCollection);

    if (appVersion.id) {
        await setDoc(doc(theCollection, appVersion.id), appVersion);
    } else {
        await setDoc(doc(theCollection), appVersion);
    }
}

export const kAppVersionsPageSize = 10;

/**
 * Create subscription to app versions collection.
 */
export function subscribeAppVersions(onNext: (snapshot: QuerySnapshot) => void, loadPages: number): Unsubscribe {
    const firestore = getFirestore();
    const theCollection = collection(firestore, kAppVersionsCollection);

    const theQuery = query(
        theCollection,
        orderBy('createdAt', 'desc'),
        limit(kAppVersionsPageSize * loadPages),
    );

    return onSnapshot(theQuery, onNext);
}

/**
 * Count number of app versions in Firestore.
 */
export async function countAppVersions(): Promise<number> {
    const firestore = getFirestore();
    const theCollection = collection(firestore, kAppVersionsCollection);

    const snapshot = await getCountFromServer(
        theCollection,
    );

    return snapshot.data().count;
}

/**
 * Query the latest version of the app from Firestore.
 */
export async function queryLatestAppVersion(): Promise<AppVersion | null> {
    const firestore = getFirestore();
    const theCollection = collection(firestore, kAppVersionsCollection);

    const now = DateTime.now().toMillis();

    const theQuery = query(
        theCollection,
        where('app', '==', AppType.BackOffice),
        where('createdAt', '<=', now),
        orderBy('createdAt', 'desc'),
        limit(1),
    )

    const snapshot = await getDocs(theQuery);

    if (snapshot.empty) {
        return null;
    }

    const doc = snapshot.docs[0];

    return {
        ...doc.data(),
        id: doc.id,
    } as AppVersion;
}

/**
 * Delete AppVersion from Firestore.
 */
export async function deleteAppVersion(appVersion: AppVersion): Promise<void> {
    const firestore = getFirestore();
    const theCollection = collection(firestore, kAppVersionsCollection);

    await deleteDoc(doc(theCollection, appVersion.id));
}

let lastCheckedAt: number = 0;
const kTenMinutes = 1000 * 60 * 10;

/**
 * Compare latest app version with latest version on Firestore.
 * If there is newer version, display update dialog.
 */
export async function checkAppVersion(appDataContext: IAppDataContext): Promise<void> {
    const now = DateTime.now().toMillis();

    if (now - lastCheckedAt < kTenMinutes) {
        return;
    }
    lastCheckedAt = now;

    try {
        const latestAppVersion = await queryLatestAppVersion();

        if (latestAppVersion) {
            const latestVersionNumber: number = parseInt(latestAppVersion.version.replace(/\./g, ''));
            const currentAppVersion: number = parseInt(packageJson.version.replace(/\./g, ''));

            if (latestVersionNumber > currentAppVersion) {
                SetAppVersionModal(appDataContext, {
                    opened: true,
                    version: latestAppVersion.version,
                });
            }
        }
    } catch (e) {
        console.error(e);
    }
}

/**
 * Display AppVersion type as string.
 */
export function appTypeToString(appType: AppType): string {
    switch (appType) {
        case AppType.BackOffice:
            return tt('common.backOffice');
        case AppType.Mobile:
            return tt('common.mobile');
        case AppType.Web:
            return tt('common.web');
        default:
            return tt('common.none');
    }
}

/**
 * Display AppVersion type as string.
 * Use on string[] to translate all AppVersions.
 */
export function appTypesToString(appTypes: AppType[]): string {
    return appTypes.map(appTypeToString).join(', ');
}

/**
 * Get AppTypes for select as options.
 */
export function appTypeToOptions(skipNone?: boolean): SelectItem[] {
    const options: SelectItem[] = [];

    if (!skipNone) {
        options.push({label: appTypeToString(AppType.None), value: AppType.None.toString()});
    }

    options.push({label: appTypeToString(AppType.Web), value: AppType.Web.toString()});
    options.push({label: appTypeToString(AppType.Mobile), value: AppType.Mobile.toString()});
    options.push({label: appTypeToString(AppType.BackOffice), value: AppType.BackOffice.toString()});

    return options;
}
