import React, {useContext, useEffect} from "react";
import {AppDataContext} from "../../../AppData";
import {tt} from "../../../core/Localization";
import ScreenContent from "../../components/screens/ScreenContent";
import AppPaper from "../../components/screens/AppPaper";
import {Text, Title} from "@mantine/core";

export const kDashboardRoute = '/';

/**
 * Screen component for Dashboard.
 */
export default function DashboardScreen() {
    const appDataContext = useContext(AppDataContext);
    const {setTitle, theme} = appDataContext;

    useEffect(() => {
        setTitle(tt('dashboard.screen.title'));
    }, []);

    return (
        <>
            <ScreenContent>
                <AppPaper>
                    <Title order={2} pb="md">Welcome to Timoty back Office.</Title>

                    <Text>I'm sure there will be something amazing here in the future.</Text>
                </AppPaper>
            </ScreenContent>
        </>
    );
}
