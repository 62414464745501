import {tt} from "../../../../core/Localization";
import AppModal from "../AppModal";
import {Text} from "@mantine/core";
import AppButton from "../../buttons/AppButton";
import AppModalActions from "../AppModalActions";
import {AppDataContext} from "../../../../AppData";
import {useContext} from "react";
import {HideAppVersionModal} from "../AppModals";

export interface IAppVersionModalProps {
    opened: boolean;
    version: string;
}

/**
 * Modal component for app version updated notification and reload app.
 */
export default function AppVersionModal(props: IAppVersionModalProps) {
    const {opened, version} = props;

    const appDataContext = useContext(AppDataContext);

    const text = tt('appVersionModal.subtitle').replace('$version', version);

    return (
        <AppModal
            opened={opened}
            close={() => HideAppVersionModal(appDataContext)}
            title={tt('appVersionModal.title')}
        >
            <Text mb={'md'}>{text}</Text>

            <AppModalActions>
                <AppButton
                    variant={'outline'}
                    onClick={() => HideAppVersionModal(appDataContext)}
                >
                    {tt('appVersionModal.later')}
                </AppButton>

                <AppButton onClick={() => window.location.reload()}>
                    {tt('appVersionModal.update')}
                </AppButton>
            </AppModalActions>
        </AppModal>
    );
}
